import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import MenuAppBar from "../components/Appbar";
import Home from "./HomeScreens/Home";
import KycPop from "../components/KycPop";
import { UserContext } from "../Contexts/UserContext";
import Profile from "./HomeScreens/Profile";
import LoanScreen from "./HomeScreens/Loan";
import { useNavigate, useParams } from "react-router-dom";
import ViewProfile from "./HomeScreens/ViewProfile";
import { incentiveData } from "../api/agent";
import RewardWallet from "../components/V2/Reward/RewardWallet";

export default function FixedBottomNavigation() {
  const { tab } = useParams();
  const [index, setIndex] = useState();
  const [value, setValue] = useState(tab ? tab : "");
  const { user } = useContext(UserContext);
  const [appText, setText] = useState("");
  const navigate = useNavigate();

  const [coins, setCoins] = useState({});

  const ref = useRef(null);

  useEffect(() => {
    switch (index) {
      case 0:
        setText(
          <div style={{ color: "#353535", marginTop: "10px" }}>
            <span style={{ fontWeight: "400" }}>{doGreeting()}</span>
            <p>{user.name.split(" ")[0]} 👋</p>
          </div>
        );
        break;
      case 1:
        setText(`Loan Details`);
        break;
      case 2:
        setText(`Profile`);
        break;
    }
  }, [index]);

  useEffect(() => {
    switch (tab) {
      case "loan":
        setIndex(1);
        break;
      case "profile":
        setIndex(2);
        break;
      case "profile-view":
        setIndex(2);
        break;
      default:
        setIndex(0);
        break;
    }
  }, [tab]);

  // const getCoinData = async () => {
  //   try {
  //     let { data } = await incentiveData(false, true, "");
  //     if (data?.status) {
  //       setCoins({
  //         application: data?.applicationIncentiveData?.total_coins,
  //         overall: data?.totalAgentIncentiveData?.total_coins,
  //       });
  //     }
  //   } catch (err) {}
  // };

  // useEffect(()=>{
  //   getCoinData();
  // },[])

  const doGreeting = () => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      return "Good morning,";
    } else if (curHr < 18) {
      return "Good afternoon,";
    } else {
      return "Good evening,";
    }
    return "Hello,";
  };

  return (
    <>
      <Box sx={{ pb: 7, pt: value === 0 ? 8 : 3 }} ref={ref}>
        {index === 0 && (
          <MenuAppBar home={true} text={appText} active={index} />
        )}
        <CssBaseline />
        <div>
          {user?.kycStage === "none" && <KycPop />}
          {!tab && <Home />}
          {tab === "loan" && <LoanScreen />}
          {tab === "profile" && <Profile />}
          {tab === "profile-view" && <ViewProfile />}
        </div>
        <Paper
          sx={{
            height: "auto",
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            pb: 1,
            pt: 1,
          }}
          elevation={3}
        >
          <BottomNavigation
            className="bottom-nav"
            showLabels
            value={index}
            onChange={(event, newValue) => {
              setIndex(newValue);
              switch (newValue) {
                case 0:
                  setText(`Hi, ${user.name.split(" ")[0]}`);
                  setValue("");
                  navigate("/home");
                  break;
                case 2:
                  setText(`Loan Details`);
                  setValue("loan");
                  navigate("/home/loan");
                  break;
                case 1:
                  setText(`Profile`);
                  setValue("profile");
                  navigate("/home/profile");
                  break;
              }
            }}
          >
            <BottomNavigationAction
              // label="Home"
              icon={<AiOutlineHome style={{ fontSize: "25" }} />}
            />
            {/* <BottomNavigationAction
            label="Loan"
            icon={<GiReceiveMoney style={{ fontSize: "25" }} />}
          /> */}
            <BottomNavigationAction
              // label="Profile"
              icon={<CgProfile style={{ fontSize: "25" }} />}
            />
          </BottomNavigation>
        </Paper>
      </Box>

      {/* {user?.incentives_enabled && <RewardWallet coin={coins?.overall}/>} */}
    </>
  );
}
