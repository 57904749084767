import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import CatureUpload from "../../../components/CaptureV2";
import MenuAppBar from "../../../components/Appbar";
import { useNavigate, useParams } from "react-router-dom";
import { phychometricQuestions } from "../../../api/agent";
import { CustomerContext } from "../../../Contexts/CustomerContext";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import LoanJourneryContext, {
  LoanContext,
} from "../../../Contexts/LoanJourneyContext";
import { createCustomer, getAnchors } from "../../../api/agent";
import CustomInputAutocomplete from "../../../components/Autocomplete/AutoComplete";

// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import ListItemText from "@mui/material/ListItemText";
// import Select from '@mui/material/Select';
import Checkbox from "@mui/material/Checkbox";
import RewardFloating from "../../../components/RewardFloating";
import ShowRewardCoinGif from "../../../components/ShowRewardCoinGif";
import { GlobalContext } from "../../../Contexts/GlobalContext";


const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const apps = [
  "Paytm",
  "PhonePe",
  "GooglePay",
  "Cred",
  "Bhim",
  "Amazon",
  "Flipkart",
  "Khatabook",
  "WhatsApp",
  "Others",
];

const PsychometricTest = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const { application_id } = useParams();
  const { showRewardState, getCoinData } = useContext(GlobalContext);

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [anchorList, setAnchorList] = useState([]);
  const [isReward, showReward] = showRewardState;

  const [errors, setErrors] = useState({
    pan_number: "",
    aadhaar_front: false,
    aadhaar_back: false,
    pan_image: "",
  });

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const [psychometricTestDetails, setPsychometricTestDetails] = useState({
    martialStatus: "",
    noOfKids: "",
    elderChildEducation: "",
    aadhaarAddressVisitTime: "",
    dayWorkHours: "",
    monthlyIncome: "",
    otherIncomeSource: "",
    monthlyHouseholdIncome: "",
    appsYouUse: [],
    other: "",
  });

  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setPsychometricTestDetails({ ...psychometricTestDetails, [name]: value });
    // console.log('==>', psychometricTestDetails.appsYouUse.includes('Others'))
    // if (!psychometricTestDetails.appsYouUse.includes('Others')) {
    //     console.log('log data')
    //     // setPsychometricTestDetails({ ...psychometricTestDetails, other: "" })
    // }
  };

  useEffect(() => {
    console.log(psychometricTestDetails);
  }, [psychometricTestDetails]);

  const isPanValid = (val) => {
    let pan_pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (pan_pattern.test(val)) return "";
    return "Please re-check you PAN";
  };

  const isValid = () => {
    let valid = true;
    let errors = {
      pan_number: journeyData?.isPanOcr
        ? ""
        : isPanValid(journeyData?.pan_number),
      aadhaar_front:
        journeyData?.aadhaar_front !== "" ? "" : "This field is required",
      aadhaar_back:
        journeyData?.aadhaar_back !== "" ? "" : "This field is required",
      pan_image: journeyData?.isPanOcr
        ? journeyData?.pan_image !== ""
          ? ""
          : "This field is required"
        : "",
    };

    console.log(errors);

    for (let i in errors) {
      if (errors[i].length > 0) {
        valid = false;
      }
    }

    return valid;
  };

  const psychoApi = async (payload) => {
    try {
      let { data } = await phychometricQuestions(payload, application_id);
      if (data?.status) {
        enqueueSnackbar("Data submitted!!", { variant: "success" });
        if(data?.is_coin_credited){
          showReward({
            show:true,
            coin:data?.coin
          })
          getCoinData()
        }
        setJourneyData({
          ...journeyData,
          is_psychometric_done: true,
        });
        props?.onClick();
        setApiSuccess(true);
        console.log(data?.status);
      }
    } catch (err) {
      let error = "";
      if (err?.response?.data?.message) {
        if (err?.response?.data?.message === "Bad request parameters") {
          error = "Please re-check all the inputs";
        } else {
          error = err?.response?.data?.message;
        }
      } else {
        error = "Something went wrong";
      }
      enqueueSnackbar(error, { variant: "error" });
    }
  }

  const handleSubmit = () => {
    let payload = {
      data: {
        "maritial_status": psychometricTestDetails.martialStatus,
        "no_of_kids": psychometricTestDetails.noOfKids,
        "elder_children_qualification": psychometricTestDetails.elderChildEducation,
        "no_of_time_visited_aadhaar_address": psychometricTestDetails.aadhaarAddressVisitTime,
        "no_of_hrs_work_in_a_day": psychometricTestDetails.dayWorkHours,
        "monthly_income": psychometricTestDetails.monthlyIncome,
        "other_source_of_income": psychometricTestDetails.otherIncomeSource,
        "monthly_household_income": psychometricTestDetails.monthlyHouseholdIncome,
        "apps_using": psychometricTestDetails.appsYouUse,
        "other": psychometricTestDetails.other
      }
    }

    if (psychometricTestDetails.martialStatus === 'single') {
      if (psychometricTestDetails.appsYouUse.includes('Others')) {
        if (psychometricTestDetails.aadhaarAddressVisitTime !== '' && psychometricTestDetails.dayWorkHours !== '' && psychometricTestDetails.monthlyIncome !== '' && psychometricTestDetails.otherIncomeSource !== '' && psychometricTestDetails.appsYouUse !== '' && psychometricTestDetails.other !== '') {
          psychoApi(payload);
        }
        else {
          enqueueSnackbar("All fields are mandatory", { variant: "error" });
        }
      }

      else {
        if (psychometricTestDetails.aadhaarAddressVisitTime !== '' && psychometricTestDetails.dayWorkHours !== '' && psychometricTestDetails.monthlyIncome !== '' && psychometricTestDetails.otherIncomeSource !== '' && psychometricTestDetails.appsYouUse !== '') {
          psychoApi(payload);
        }
        else {
          enqueueSnackbar("All fields are mandatory", { variant: "error" });
        }
      }
    }

    else if (psychometricTestDetails.martialStatus === 'married') {
      if (psychometricTestDetails.appsYouUse.includes('Others')) {
        if (psychometricTestDetails.aadhaarAddressVisitTime !== '' && psychometricTestDetails.dayWorkHours !== '' && psychometricTestDetails.monthlyIncome !== '' && psychometricTestDetails.otherIncomeSource !== '' && psychometricTestDetails.appsYouUse !== '' && psychometricTestDetails.other !== '') {
          psychoApi(payload);
        }
        else {
          enqueueSnackbar("All fields are mandatory", { variant: "error" });
        }
      }

      else {
        if (psychometricTestDetails.aadhaarAddressVisitTime !== '' && psychometricTestDetails.dayWorkHours !== '' && psychometricTestDetails.monthlyIncome !== '' && psychometricTestDetails.otherIncomeSource !== '' && psychometricTestDetails.appsYouUse !== '') {
          psychoApi(payload);
        }
        else {
          enqueueSnackbar("All fields are mandatory", { variant: "error" });
        }
      }
    }
  }

  const getAnchorsList = async () => {
    try {
      // let anchors = [{name:""},{}]
      let { data } = await getAnchors();
      if (data?.status) {
        let anchors = data?.data?.map((val) => {
          return `${val?.anchor_id} - ${val?.data?.name}`;
        });

        setAnchorList(anchors);
      }
    } catch (error) { }
  };

  const handleBack = async () => {
    props?.onClick();
  };

  useEffect(() => {
    getAnchorsList();
  }, []);

  // useEffect(() => {
  //     console.log(journeyData?.isPanOcr);
  // }, [journeyData])

  return (
    <>
      <Box
        className="w-100 kyc-wrapper"
        sx={{ pb: 7, pt: 7 }}
        ref={ref}
        style={{ position: "relative" }}
      >
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        {
          apiSuccess ?
            <ShowRewardCoinGif RewardValue={50} action={handleBack} close={props?.onClick()} psychoDone={() => setJourneyData({
              ...journeyData,
              is_psychometric_done: true,
            })} />
            :
            null
        }

        <div className="main-wrapper w-100">
          <>
            <Grid container columnSpacing={1}>
              <Grid item xs={12} sm={12}>
                <label className="form-label">Martial Status</label>

                <div>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="martialStatus"
                      // onChange={(event) => setMartialStatus(event.target.value)}
                      onChange={handleSelectChange}
                      value={psychometricTestDetails.martialStatus}
                    >
                      <FormControlLabel
                        value="married"
                        control={<Radio />}
                        label={
                          <span style={{ fontSize: "15px" }}>Married</span>
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                      <FormControlLabel
                        value="single"
                        control={<Radio />}
                        label={<span style={{ fontSize: "15px" }}>Single</span>}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                          marginLeft: "1em",
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>

            {psychometricTestDetails.martialStatus === "married" ? (
              <>
                {/* if married */}
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <label className="form-label">No. of Kids <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.noOfKids}
                      name="noOfKids"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"0"}>0</MenuItem>
                      <MenuItem value={"1"}>1</MenuItem>
                      <MenuItem value={"2"}>2</MenuItem>
                      <MenuItem value={"more than 2"}>More than 2</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={8}>
                    <label className="form-label">
                      What is your elder child's education qualification? <span className="mand-star">*</span>
                    </label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.elderChildEducation}
                      name="elderChildEducation"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"pre-school"}>Pre-school</MenuItem>
                      <MenuItem value={"school"}>School</MenuItem>
                      <MenuItem value={"college"}>College</MenuItem>
                      <MenuItem value={"earning"}>Earning</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid container columnSpacing={1} sx={{ marginTop: "0em" }}>
                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      No. of time you visit the Aadhar address <span className="mand-star">*</span>
                    </label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.aadhaarAddressVisitTime}
                      name="aadhaarAddressVisitTime"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem
                        value={"Work location & Aadhar address are same"}
                      >
                        Work location & Aadhar address are same
                      </MenuItem>
                      <MenuItem value={"less than 2 days a month"}>
                        Less than 2 days a Month
                      </MenuItem>
                      <MenuItem value={"2 to 6days a month"}>
                        2 days to 6 days a Month
                      </MenuItem>
                      <MenuItem value={"1 month - Once in 6 months"}>
                        1 Month - Once in 6 Months
                      </MenuItem>
                      <MenuItem value={"1 month - Once in 12 months "}>
                        1 Month - Once in 12 Months{" "}
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      No. of Hrs you work in a day <span className="mand-star">*</span>
                    </label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.dayWorkHours}
                      name="dayWorkHours"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"8 Hrs"}>8 Hrs</MenuItem>
                      <MenuItem value={"8 Hrs to 10 Hrs"}>
                        8 Hrs to 10 Hrs
                      </MenuItem>
                      <MenuItem value={"10 Hrs to 12 Hrs"}>
                        10 Hrs to 12 Hrs
                      </MenuItem>
                      <MenuItem value={"12 Hrs to 14 Hrs"}>
                        12 Hrs to 14 Hrs
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">Customer's Monthly Income <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.monthlyIncome}
                      name="monthlyIncome"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"Less than Rs 15k"}>
                        Less than Rs 15k
                      </MenuItem>
                      <MenuItem value={"Rs 15k to Rs 30k"}>
                        Rs 15k to Rs 30k
                      </MenuItem>
                      <MenuItem value={"Rs 30k to Rs 50k"}>
                        Rs 30k to Rs 50k
                      </MenuItem>
                      <MenuItem value={"More than Rs 50k"}>
                        More than Rs 50k
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">Other Source of Income <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.otherIncomeSource}
                      name="otherIncomeSource"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"Other Related Work- 2nd Shift"}>
                        Other Related Work- 2nd Shift
                      </MenuItem>
                      <MenuItem value={"Other Unrelated Work- 2nd Shift"}>
                        Other Unrelated Work- 2nd Shift
                      </MenuItem>
                      <MenuItem value={"Salaried"}>Salaried</MenuItem>
                      <MenuItem value={"None"}>None</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      Monthly Household Income <span className="mand-star">*</span>
                    </label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.monthlyHouseholdIncome}
                      name="monthlyHouseholdIncome"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"Only Earner"}>Only Earner</MenuItem>
                      <MenuItem value={"Less than Rs 30K"}>
                        Less than Rs 30K
                      </MenuItem>
                      <MenuItem value={"Rs 30k to Rs 60k"}>
                        Rs 30k to Rs 60k
                      </MenuItem>
                      <MenuItem value={"More than Rs 60k"}>
                        More than Rs 60k
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">Apps you use <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-multiple-checkbox-label"
                      // id="demo-multiple-checkbox"
                      name="appsYouUse"
                      multiple
                      value={psychometricTestDetails.appsYouUse}
                      onChange={handleSelectChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      {apps.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={
                              psychometricTestDetails.appsYouUse.indexOf(name) >
                              -1
                            }
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  {psychometricTestDetails.appsYouUse.includes("Others") ? (
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <label className="form-label">Others <span className="mand-star">*</span></label>
                      <br />
                      <TextField
                        onChange={(e) =>
                          setPsychometricTestDetails({
                            ...psychometricTestDetails,
                            other: e.target.value,
                          })
                        }
                        variant="outlined"
                        fullWidth
                        InputProps={{ sx: { height: 40 } }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : psychometricTestDetails.martialStatus === "single" ? (
              <>
                {/* if single */}
                <Grid container columnSpacing={1} sx={{ marginTop: "0em" }}>
                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      No. of time you visit the Aadhar address <span className="mand-star">*</span>
                    </label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.aadhaarAddressVisitTime}
                      name="aadhaarAddressVisitTime"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem
                        value={"Work location & Aadhar address are same"}
                      >
                        Work location & Aadhar address are same
                      </MenuItem>
                      <MenuItem value={"less than 2 days a month"}>
                        Less than 2 days a Month
                      </MenuItem>
                      <MenuItem value={"2 to 6days a month"}>
                        2 days to 6 days a month
                      </MenuItem>
                      <MenuItem value={"1 month - Once in 6 months"}>
                        1 Month - Once in 6 Months
                      </MenuItem>
                      <MenuItem value={"1 month - Once in 12 months "}>
                        1 Month - Once in 12 Months{" "}
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      No. of Hrs you work in a day <span className="mand-star">*</span>
                    </label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.dayWorkHours}
                      name="dayWorkHours"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"8 Hrs"}>8 Hrs</MenuItem>
                      <MenuItem value={"8 Hrs to 10 Hrs"}>
                        8 Hrs to 10 Hrs
                      </MenuItem>
                      <MenuItem value={"10 Hrs to 12 Hrs"}>
                        10 Hrs to 12 Hrs
                      </MenuItem>
                      <MenuItem value={"12 Hrs to 14 Hrs"}>
                        12 Hrs to 14 Hrs
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">Monthly Income <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.monthlyIncome}
                      name="monthlyIncome"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"Less than Rs 15k"}>
                        Less than Rs 15k
                      </MenuItem>
                      <MenuItem value={"Rs 15k to Rs 30k"}>
                        Rs 15k to Rs 30k
                      </MenuItem>
                      <MenuItem value={"Rs 30k to Rs 50k"}>
                        Rs 30k to Rs 50k
                      </MenuItem>
                      <MenuItem value={"More than Rs 50k"}>
                        More than Rs 50k
                      </MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">Other Source of Income <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-simple-select-helper-label"
                      // id="demo-simple-select-helper"
                      value={psychometricTestDetails.otherIncomeSource}
                      name="otherIncomeSource"
                      onChange={handleSelectChange}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      <MenuItem value={"Other Related Work- 2nd Shift"}>
                        Other Related Work- 2nd Shift
                      </MenuItem>
                      <MenuItem value={"Other Unrelated Work- 2nd Shift"}>
                        Other Unrelated Work- 2nd Shift
                      </MenuItem>
                      <MenuItem value={"Salaried"}>Salaried</MenuItem>
                      <MenuItem value={"None"}>None</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">Apps you use <span className="mand-star">*</span></label>
                    <br />
                    <Select
                      // labelId="demo-multiple-checkbox-label"
                      // id="demo-multiple-checkbox"
                      name="appsYouUse"
                      multiple
                      value={psychometricTestDetails.appsYouUse}
                      onChange={handleSelectChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      fullWidth
                      sx={{ height: 40 }}
                    >
                      {apps.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={
                              psychometricTestDetails.appsYouUse.indexOf(name) >
                              -1
                            }
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>

                  {psychometricTestDetails.appsYouUse.includes("Others") ? (
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                      <label className="form-label">Others <span className="mand-star">*</span></label>
                      <br />
                      <TextField
                        onChange={(e) =>
                          setPsychometricTestDetails({
                            ...psychometricTestDetails,
                            other: e.target.value,
                          })
                        }
                        variant="outlined"
                        fullWidth
                        InputProps={{ sx: { height: 40 } }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </>
            ) : null}
          </>
        </div>

        <Paper className="paper-bottom" elevation={3}>
          <BottomNavigation style={{ height: "auto", paddingTop: "3px" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Submit"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default PsychometricTest;
