import {
  BottomNavigation,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Slide,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../components/Appbar";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import agreeImg from "../../../assets/loan-agree.svg";
import { updateLoanData } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import ConfettiExplosion from "react-confetti-explosion";
import congratsIco from "../../../assets/reward-cup.svg";
import { display } from "@mui/system";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoanSummary = () => {
  const { bottomNav, otpState } = useContext(GlobalContext);
  const [otpData, setOtpData] = otpState;
  const [open, setOpen] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const [fieldErrors, setErrors] = useState({ dob: "" });

  const { application_id } = useParams();
  const mainWrapper = useRef(null);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  let confetti;

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    // setLoading(true);
    // let payload = {
    //   vehicle_model: "E-rick",
    //   vehicle_price_on_road: journeyData?.vehicle_price_on_road,
    //   vehicle_price_ex_showroom: journeyData?.vehicle_price_ex_showroom,
    //   loan_amount: journeyData?.loan_amount,
    //   down_payment: journeyData?.down_payment,
    //   interest_rate: journeyData?.interest_rate,
    //   tenure_type: "months",
    //   tenure_value: journeyData?.tenure,
    //   processing_charge_rate: journeyData?.processing_charge_rate,
    // };

    // try {
    //   let { data } = await updateLoanData(payload, application_id);
    //   if (data?.status) {
    //     navigate(`/create-loan/progress/${application_id}`);
    //   }
    // } catch (err) {
    //   enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
    // }
    // setLoading(false);
    navigate(`/onboard/payment-method/${application_id}`);
  };

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id, true);
    setTimeout(() => {
      setOpen(false);
    }, 3000);
  }, []);

  const fc = (val) => {
    return Number(val).toLocaleString("en-IN");
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        className="kyc-wrapper w-100"
        sx={{ pb: 7, pt: 7 }}
        ref={mainWrapper}
      >
        <MenuAppBar
          home={false}
          text={"Loan Summary"}
          action={() => {
            navigate("/home");
          }}
        />
        <CssBaseline />
        <div className="main-wrapper">
          <Grid container spacing={2} className="pb-5">
            <Grid item xs={12} sm={12}>
              <div className="loan-summary mt-2">
                <div class="d-flex justify-content-between mb-3 pt-3">
                  <div className="key">Loan Offer</div>
                  <div className="value">₹{fc(journeyData?.credit_limit)}</div>
                </div>

                <div class="d-flex justify-content-between mb-3">
                  <div className="key">Interest Rate</div>
                  <div className="value">
                    {fc(journeyData?.interest_rate)} %
                  </div>
                </div>

                <div class="d-flex justify-content-between mb-3">
                  <div className="key">Limit Tenure</div>
                  <div className="value">
                    {journeyData?.tenure_value} {journeyData?.tenure_type}
                  </div>
                </div>

                <div class="d-flex justify-content-between mb-3">
                  <div className="key">Tranch Tenure</div>
                  <div className="value">
                    {journeyData?.tranch_tenure_value}{" "}
                    {journeyData?.tranch_tenure_type}
                  </div>
                </div>

                <div class="d-flex justify-content-between mb-3">
                  <div className="key">Processing Fees Rate</div>
                  <div className="value">
                    {fc(journeyData?.processing_rate)} %
                  </div>
                </div>
                <div class="d-flex justify-content-between mb-3">
                  <div className="key">Processing Fees Charges</div>
                  <div className="value">
                    ₹{fc(journeyData?.processing_fee)}
                  </div>
                </div>

                {/* <div class="d-flex justify-content-between mb-3">
                <div className="key">Disbursal Amount</div>
                <div className="value">₹{journeyData?.disbursal_amount}</div>
              </div> */}
              </div>
            </Grid>
          </Grid>
        </div>

        <Paper className={`paper-bottom`} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Set up Payment Method"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
        <CongratsComp
          open={open}
          handleClose={handleClose}
          credit_limit_increased={journeyData?.credit_limit_increased}
        />
      </Box>
    </>
  );
};

const CongratsComp = ({ open, handleClose, credit_limit_increased }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          hideBackdrop={true}
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{
            style: { borderRadius: 20 },
          }}
        >
          <DialogContent>
            <div className="px-4">
              <p className="text-center">
                <img src={congratsIco} width="100px" />
              </p>
              <p
                className="text-center mb-0"
                style={{ fontWeight: 600, fontSize: 20 }}
              >
                Congratulations!
              </p>

              <p className="text-center" style={{ color: "gray" }}>
                Credit Limit
                {credit_limit_increased ? " Increased!" : " Approved"}
              </p>
            </div>
          </DialogContent>
        </Dialog>

        {open && <ConfettiExplosion />}
      </div>
    </>
  );
};

export default LoanSummary;
