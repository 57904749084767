import {
  AppBar,
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ageValidate, validateDob } from "../../../validations/dateValidations";
import { createCoCustomer, createCustomer } from "../../../api";
import MenuAppBar from "../../../components/Appbar";
import TimeInput from "../../../components/DateInput";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import {
  aadhaarEsign,
  updateAddress,
  verifyPincode,
  viewFile,
} from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import {
  isBlank,
  isMobValid,
  isStartSpace,
} from "../../../validations/fieldValidations";
import PsychometricTest from "./PsychometricTest";
import RewardFloating from "../../../components/RewardFloating";
import RewardWallet from "../../../components/V2/Reward/RewardWallet";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import mitcPdf from "../../../assets/MITC.pdf";
import axios from "axios";
import { environment } from "../../../config";
// import TimeInput from "../../Components/DateInput";
import CloseIcon from "@mui/icons-material/Close";

const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MitcPage = forwardRef((props, ref) => {
  const { bottomNav, otpState } = useContext(GlobalContext);
  const [otpData, setOtpData] = otpState;

  const refs = {
    dob: useRef(null),
    aadhaar_address: useRef(null),
    aadhaar_pincode: useRef(null),
    current_address: useRef(null),
    current_pincode: useRef(null),
    is_property_owned: useRef(null),
    is_aadhaar_property_owned: useRef(null),
    mobile_number: useRef(null),
    gender: useRef(null),
  };

  const [isLoading, setLoading] = useState(false);

  const { application_id } = useParams();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [showReward, setShowReward] = useState(false);
  const [showRewardInfo, setShowRewardInfo] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdfLink, setLink] = useState("");

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleInput = (e, setState) => {
    let result = validateDob(e.target.value);
    setState((st) => ({ ...st, date_of_birth: result }));
  };

  const handleSubmit = async () => {};

  const handleBack = async () => {
    navigate("/home");
  };

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   console.log(isPsycho);
  // }, [isPsycho]);

  const createPdf = async (view = false) => {
    // const existingPdfBytes = await fetch(mitcPdf).then((res) =>
    //   res.arrayBuffer()
    // );
    setLoading(true);

    try {
      const { data } = await viewFile(`assets/MITC.pdf`);
      const existingPdfBytes = await data?.arrayBuffer();

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const lenderName = form.getTextField("lenderName");
      const lenderAddress = form.getTextField("lenderAddress");
      const lenderWebsite = form.getTextField("lenderWebsite");
      const bankName = form.getTextField("bankName");
      const accountNumber = form.getTextField("accountNumber");
      const bankIfsc = form.getTextField("bankIfsc");
      const applicationNo = form.getTextField("applicationNo");
      const approvalDate = form.getTextField("approvalDate");
      const borrowerName = form.getTextField("borrowerName");
      const borrowerAddress = form.getTextField("borrowerAddress");
      const borrowerBankName = form.getTextField("borrowerBankName");
      const borrowerAccountNumber = form.getTextField("borrowerAccountNumber");
      const borrowerIfsc = form.getTextField("borrowerIfsc");
      const loanAmount = form.getTextField("loanAmount");
      const interestRate = form.getTextField("interestRate");
      const tranchTenure = form.getTextField("tranchTenure");
      const penalInterest = form.getTextField("penalInterest");
      const proccesingFee = form.getTextField("proccesingFee");
      const documentationFee = form.getTextField("documentationFee");
      const preInfo = form.getTextField("preInfo");
      const receivedAccount = form.getTextField("receivedAccount");

      lenderName.setText(journeyData?.nbfc_data?.name || "N/A");
      lenderAddress.setText(journeyData?.nbfc_data?.address || "N/A");
      lenderWebsite.setText(journeyData?.nbfc_data?.website || "N/A");
      bankName.setText(journeyData?.bank_name || "N/A");
      accountNumber.setText(journeyData?.accountNumber || "N/A");
      bankIfsc.setText(journeyData?.bank_name || "N/A");
      applicationNo.setText(journeyData?.application_id || "N/A");
      approvalDate.setText(
        moment(journeyData?.start_date).format("DD/MM/YYYY") || "N/A"
      );
      borrowerName.setText(journeyData?.full_name || "N/A");
      borrowerAddress.setText(journeyData?.current_address || "N/A");
      borrowerBankName.setText(journeyData?.bank_details?.bank_name || "N/A");
      borrowerAccountNumber.setText(
        journeyData?.bank_details?.account_number || "N/A"
      );
      borrowerIfsc.setText(journeyData?.bank_details?.ifsc_code || "N/A");
      loanAmount.setText(`${journeyData?.credit_limit || "N/A"}`);
      interestRate.setText(`${journeyData?.interest_rate || "N/A"}`);
      tranchTenure.setText(`${journeyData?.tranch_tenure_value || "N/A"}`);
      penalInterest.setText(`${journeyData?.penalInterest || "N/A"}`);
      proccesingFee.setText(`${journeyData?.processing_fee || "N/A"}`);
      documentationFee.setText(`${journeyData?.documentationFee || "N/A"}`);
      preInfo.setText(journeyData?.preInfo || "N/A");
      receivedAccount.setText(journeyData?.bank_account || "N/A");

      form?.flatten();
      const pdfBytes = await pdfDoc.save();

      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      let url = URL.createObjectURL(blob);

      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
        let base64data = reader.result;

        if (view) {
          setLoading(false);
          setLink(url);
          setOpen(true);
          return;
        }

        try {
          let payload = {
            redirect_url:
              environment === "development"
                ? `https://demo.cf.embifi.in/onboard/esign-status/${application_id}`
                : `https://cf.embifi.in/onboard/esign-status/${application_id}`,
            file: base64data,
          };

          let { data } = await aadhaarEsign(payload, application_id);
          window.open(data?.data?.url, "_self");
          // if (data?.status) {
          // }
        } catch (error) {
          console.log(error);
        }

        setLoading(false);
      };
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        className="kyc-wrapper w-100"
        sx={{ pb: 7, pt: 7, position: "relative" }}
        ref={ref}
      >
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />

        <div className="main-wrapper">
          <p
            className="mb-0"
            style={{ fontSize: "17px", color: "black", fontWeight: "500" }}
          >
            MITC
          </p>
          <p className="mb-0">Lender Details</p>
        </div>

        <div className="main-wrapper content-center p-4 pt-0 pb-0">
          <table style={{ width: "100%" }} className="mitc-table">
            <tr>
              <td>Lender’s Name</td>
              <td>{journeyData?.nbfc_data?.name}</td>
            </tr>
            <tr>
              <td>Lender’s Address</td>
              <td>{journeyData?.nbfc_data?.address || "NILL"}</td>
            </tr>
            <tr>
              <td>Lender’s Website</td>
              <td>{journeyData?.nbfc_data?.website || "NILL"}</td>
            </tr>
          </table>
        </div>

        <div className="main-wrapper pt-2 pb-3">
          <p className="mb-0">Loan Details</p>
        </div>

        <div className="main-wrapper content-center p-4 pt-0 pb-0 mb-0">
          <table style={{ width: "100%" }} className="mitc-table">
            <tr>
              <td>Loan Application Number</td>
              <td>{journeyData?.application_id || "NILL"}</td>
            </tr>
            <tr>
              <td>Loan Sanction Date</td>
              <td>
                {journeyData?.start_date
                  ? moment(journeyData?.start_date).format("DD/MM/YYYY")
                  : "NILL"}
              </td>
            </tr>
          </table>
        </div>

        <div className="main-wrapper pt-2 pb-3 mt-3">
          <p className="mb-0">Borrower Loan Details</p>
        </div>

        <div className="main-wrapper content-center p-4 pt-0 pb-0 mb-0">
          <table style={{ width: "100%" }} className="mitc-table">
            <tr>
              <td>Name of Borrower</td>
              <td>{journeyData?.full_name || "NILL"}</td>
            </tr>
            <tr>
              <td>
                Address of the Borrower / Registered Office address/ principal
                place of business
              </td>
              <td>{journeyData?.current_address || "NILL"}</td>
            </tr>
            <tr>
              <td>Bank Account of the Borrower</td>
              <td>
                <>
                  <div>{journeyData?.bank_details?.bank_name || "NILL"}</div>
                  <div>
                    {journeyData?.bank_details?.account_number || "NILL"}
                  </div>
                  <div>{journeyData?.bank_details?.ifsc_code || "NILL"}</div>
                </>
              </td>
            </tr>
            <tr>
              <td>Purpose of Loan</td>
              <td>To Purchase inventory</td>
            </tr>
            <tr>
              <td>Loan Amount</td>
              <td>{journeyData?.credit_limit || "NILL"}</td>
            </tr>
            <tr>
              <td>Rate of Interest</td>
              <td>{journeyData?.interest_rate || "NILL"} %</td>
            </tr>
            <tr>
              <td>Tenure of Loan</td>
              <td>
                {journeyData?.tenure_value || "NILL"}{" "}
                {journeyData?.tenure_type || ""}
              </td>
            </tr>
            <tr>
              <td>Tranch Tenure</td>
              <td>
                {journeyData?.tranch_tenure_value || "NILL"}{" "}
                {journeyData?.tranch_tenure_type || ""}
              </td>
            </tr>
            <tr>
              <td>Disbursement Amount (net of fees, charges expenses)</td>
              <td>As per Disbursal request</td>
            </tr>
            <tr>
              <td>
                Mode of Repayment (specify one or more as applicable of the
                following: NACH debit Instructions/ direct debit mandate/
                Standing instructions / delivering post-dated cheques or
                availing of any other or further means of periodic payments for
                the payment/repayment)
              </td>
              <td>
                <>
                  <ol className="p-3">
                    <li>
                      The Borrower shall pay the monies in respect of the
                      Unsecured Loan through payment received on NY0007003XXXXX
                    </li>
                    <li>PDC</li>
                  </ol>
                </>
              </td>
            </tr>
            <tr>
              <td>Additional Interest</td>
              <td>X.0% over and above the applicable Rate of interest</td>
            </tr>
            <tr>
              <td>Loan Processing Fee/Charges, if any</td>
              <td>0.0% of the Unsecured Loan amount</td>
            </tr>
            <tr>
              <td>Documentation/ fee/ charges</td>
              <td>0.0% of the Unsecured Loan amount</td>
            </tr>
            <tr>
              <td>Prepayment Information</td>
              <td>ZERO Pre Payment charges</td>
            </tr>
            <tr>
              <td>Mode of Disbursement</td>
              <td>RTGS / IMPS</td>
            </tr>
          </table>
        </div>
        <div className="main-wrapper content-center p-4 pb-0 mb-0">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <div className="d-flex">
                <FormControlLabel
                  className="mb-2"
                  control={<Checkbox color="success" />}
                  onChange={(e) => {
                    // setJourneyData({
                    //   ...journeyData,
                    //   credit_pull_allow: e.target.checked,
                    // });
                    // // if (!showReward) {
                    // //   setShowReward(true);
                    // //   setShowRewardInfo(true);
                    // // }
                    setChecked(!isChecked);
                  }}
                  checked={isChecked}
                  label={
                    <span className="checkbox-txt" style={{ fontSize: 12 }}>
                      I understand and agree to all the{" "}
                    </span>
                  }
                />
                <span
                  style={{
                    color: "blue",
                    fontSize: 12,
                    cursor: "pointer",
                    marginLeft: -10,
                    marginTop: 15,
                  }}
                  onClick={() => {
                    createPdf(true);
                  }}
                >
                  T&C’s
                </span>
              </div>
            </Grid>
          </Grid>
        </div>

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={`btn-submit ${!isChecked && "btn-submit-disabled"}`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={() => createPdf()}
                disabled={isLoading || !isChecked}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed to E sign"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>

      <Dialog
        fullScreen
        open={open}
        // onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", background: "white", color: "black" }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              MITC
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className="p-0">
          <iframe
            src={pdfLink}
            width="100%"
            height={"100%"}
            scrolling={"auto"}
          />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default MitcPage;
