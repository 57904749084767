import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchAllBanks,
  proceedApplication,
  proceedPayment,
} from "../../../../api/agent";
import MenuAppBar from "../../../../components/Appbar";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import AddIcon from "@mui/icons-material/Add";
import loadingGif from "../../../../assets/loading.gif";

const styles = {
  mainHead: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: 20,
  },
  card: {
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    padding: "15px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  card_disabled: {
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    padding: "30px",
    borderRadius: "10px",
    opacity: 0.4,
  },
  col: {
    display: "flex",
    alignItems: "Center",
    fontSize: "13px",
    fontWeight: "500",
  },

  colProgress: {
    display: "flex",
    justifyContent: "end",
    alignItems: "Center",
  },

  cardHeading: {
    fontWeight: 400,
    marginBottom: 0,
  },

  cardDesc: {
    fontWeight: 400,
    marginBottom: 0,
    color: "gray",
  },

  statusTxt: {
    marginBottom: 0,
    marginRight: 10,
  },
};

const statusColor = {
  pending: "#274384",
  unsuccessfull: "#FF0000",
  successfull: "#2AB514",
};

const BankAccounts = forwardRef((props, ref) => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { bottomNav, showRewardState } = useContext(GlobalContext);
  const { application_id } = useParams();
  const [isReward, showReward] = showRewardState;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [banksStatuses, setBankStates] = useState({});
  const [isDisabled, setDisabled] = useState(false);

  const [confirmationCount, setConfirmationCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [status, setStatus] = useState({
    enach: "pending",
    upi: "pending",
  });

  const handleSubmit = async () => {};

  const handleBack = async () => {
    navigate("/home");
  };

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    (async () => {
      await getApplicationDetails(application_id, true);
      getAllBanks();
    })();
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  const handleProceed = async () => {
    setLoading(true);
    if (!journeyData?.credit_limit_increased) {
      try {
        let payload = {
          from: "bank_accounts",
        };
        const { data } = await proceedApplication(application_id, payload);
        navigate(`/onboard/loan-summary/${application_id}`);
      } catch (err) {}
      setLoading(false);
      return;
    }

    setJourneyData({
      ...journeyData,
      temp_status: true,
    });
    // navigate(`/onboard/loan-status/${application_id}`)
    try {
      let payload = {
        from: "bank_accounts",
      };

      const { data } = await proceedApplication(application_id, payload);
      console.log(data);
      // setTimeout(() => {
      //   navigate(`/onboard/loan-status/${application_id}`);
      // }, [2000]);
      if (data?.status) {
        navigate(`/onboard/loan-status/${application_id}`);
        // if (data?.is_coin_credited) {
        //   showReward({
        //     show: true,
        //     coin: data?.coin,
        //   });
        // }
      }
    } catch (error) {
      enqueueSnackbar(
        error?.response?.data?.message || "Something went wrong",
        { variant: "error" }
      );
    }
    // setLoading(false);
  };

  const getAllBanks = async () => {
    setLoading(true);
    try {
      const { data } = await fetchAllBanks(application_id);
      setBanks(data?.banks);
    } catch (error) {}
    setLoading(false);
  };

  const handleCardClick = async (bank) => {
    try {
      if (!["SUCCESSFUL"].includes(getStatus(bank)?.status)) {
        // if (journeyData?.credit_limit_increased) {
        setJourneyData({
          ...journeyData,
          bank_name: bank?.bank_name,
          account_type: bank?.account_type,
          credit_over_limit: bank?.cash_credit,
          beneficiary_name: bank?.beneficiary_name,
          account_number: bank?.account_number,
          ifsc_code: bank?.ifsc_code,
          bank_code: bank?.bank_code,
          bank_id: bank?.bank_id,
        });
        navigate(`/onboard/confirm-bank-details/${application_id}`);
        // } else {
        //   setJourneyData({
        //     ...journeyData,
        //     bank_name: bank?.bank_name,
        //     account_type: bank?.account_type,
        //     credit_over_limit: bank?.cash_credit,
        //     beneficiary_name: bank?.beneficiary_name,
        //     account_number: bank?.account_number,
        //     ifsc_code: bank?.ifsc_code,
        //   });
        //   navigate(`/onboard/add-bank/${application_id}`);
        // }
      }
    } catch (error) {}
  };

  const getStatus = async (bank) => {
    if (bank?.penny_drop_status === null) {
      if (journeyData?.credit_limit_increased) {
        if (bank?.is_statement_parsed === null) {
          // setDisabled(true)
          return {
            status: "PROCESSING_STATEMENT",
            component: (
              <span style={{ color: "#645B9D" }}>
                <img src={loadingGif} width="20px" /> Processing statement
              </span>
            ),
          };
        } else if (bank?.is_statement_parsed) {
          // setDisabled(true)
          return {
            status: "CONFIRMATION_PENDING",
            component: (
              <span style={{ color: "#645B9D" }}>Confirmation Pending</span>
            ),
          };
        } else {
          // setDisabled(true)
          return {
            status: "PROCESSING_STATEMENT_FAILED",
            component: (
              <span style={{ color: "red" }}>Processing statement Failed</span>
            ),
          };
        }
      } else {
        // setDisabled(true)
        return {
          status: "PENDING",
          component: <span style={{ color: "#645B9D" }}>Pending</span>,
        };
      }
    } else if (bank?.penny_drop_status) {
      return {
        status: "SUCCESSFUL",
        component: <span style={{ color: "#2AB514" }}>Successful</span>,
      };
    } else {
      return {
        status: "UNSUCCESSFUL",
        component: <span style={{ color: "#B51414" }}>Unsuccessful</span>,
      };
    }
  };

  useEffect(() => {
    (async () => {
      let statuses = {};
      let i = 0;
      for await (const bank of banks) {
        statuses = { ...statuses, [i++]: await getStatus(bank) };
      }

      console.log(statuses);
      setBankStates(statuses);
    })();
  }, [banks]);

  let checkDisable = (obj) => {
    for (const key of Object.keys(obj)) {
      if (
        obj[key]?.status === "CONFIRMATION_PENDING" ||
        obj[key]?.status === "PROCESSING_STATEMENT" ||
        obj[key]?.status === "PENDING"
      ) {
        console.log(obj[key]?.status);
        setDisabled(true);
        return;
      } else {
        console.log(obj[key]?.status);
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    checkDisable(banksStatuses);
  }, [banksStatuses]);

  return (
    <>
      <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper">
          <p style={styles?.mainHead}>Bank Accounts</p>
          <Grid container spacing={2}>
            {banks?.map((bank, i) => {
              return (
                <Grid item xs={12} sm={12}>
                  <Container
                    style={styles?.card}
                    onClick={() => {
                      handleCardClick(bank);
                    }}
                  >
                    <Row>
                      <Col xs={8} md={8} style={styles?.col}>
                        <div>
                          <p style={styles?.cardHeading}>{bank?.bank_name}</p>
                          <p style={styles?.cardDesc}>{bank?.account_number}</p>
                        </div>
                      </Col>
                      <Col xs={4} md={4} style={styles?.colProgress}>
                        <p className="mb-0 text-end">
                          {banksStatuses?.[i]?.component}
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Grid>
              );
            })}

            <Grid item xs={12} sm={12}>
              <p
                style={{ float: "right", cursor: "pointer", color: "gray" }}
                onClick={() => {
                  if (journeyData?.credit_limit_increased) {
                    navigate(`/onboard/add-bank-statement/${application_id}`);
                  } else {
                    setJourneyData({
                      ...journeyData,
                      bank_id: "",
                      bank_name: "",
                      bank_code: "",
                      account_type: "",
                      credit_over_limit: "",
                      beneficiary_name: "",
                      account_number: "",
                      ifsc_code: "",
                    });
                    navigate(`/onboard/add-bank/${application_id}`);
                  }
                }}
              >
                <AddIcon /> Add more
              </p>
            </Grid>
          </Grid>
        </div>

        <Paper className={`paper-bottom`} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={`btn-submit ${
                  (isLoading ||
                    status.enach === "pending" ||
                    status.upi === "pending") &&
                  "btn-submit-disabled"
                }`}
                className={`btn-submit ${isDisabled && "btn-submit-disabled"}`}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleProceed}
                disabled={isDisabled || isLoading}
                // disabled={
                //   isLoading ||
                //   status.enach === "pending" ||
                //   status.upi === "pending"
                // }
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default BankAccounts;
