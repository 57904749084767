import axios from "axios";
import { baseURL, environment } from "../config";

const API = axios.create({
  baseURL: baseURL[environment],
  withCredentials: true,
});


API.interceptors.request.use(
  config => {
      const nbfc = localStorage.getItem('nbfc');
      config.headers['nbfc-id'] = nbfc;
      config.headers['application'] = "CUSTOMER-CFINANCE";
      return config;
  },
  error => {
      Promise.reject(error)
});



export const userLogin = (data) => API.post(`/cfinance/customer-login`, data);
export const verifyLogin = (data) => API.post(`/cfinance/verify-login`, data);
export const agentLogin = (data) => API.post(`/cfinance/agent-login`, data);
export const verifyAuth = () => API.get(`/cfinance/verify-auth`);

export const fetchApplicationData = (application_id) => API.get(`cfinance/loan-application/${application_id}`);

export const getAnchors = () => API.get(`cfinance/anchors`);
export const createCustomer = (payload) => API.post(`/cfinance/onboard-customer`,payload);
export const updateAddress = (payload, id) => API.put(`/cfinance/customer/${id}`,payload);
export const updateBusiness = (payload, id) => API.put(`/cfinance/update-business-details/${id}`,payload);
export const verifyOtp = (payload) => API.post(`/cfinance/verify-otp`,payload);

export const updateBankDetails = (payload,id,limit) => API.post(`/cfinance/add-bank-account/${id}?increaseCreditLimit=${limit}`,payload);


export const sentOtp = (payload) => API.post(`/cfinance/resent-otp`,payload);
export const createCoCustomer = (payload) => API.post(`/cfinance/create-co-customer`,payload);
export const updateCoCustomer = (payload, id) => API.put(`/cfinance/co-customer/${id}`,payload);
export const phychometricQuestions = (payload, id) => API.put(`cfinance/phychometric-questions/${id}`, payload);
export const fetchAllBanks = (id) => API.get(`/cfinance/all-banks/${id}`);
export const confirmBank = (id,payload) => API.post(`/cfinance/penny-drop/${id}`, payload);
export const changeDefaultBank = (id) => API.put(`/cfinance/change-default-bank/${id}`);

export const proceedApplication = (id,payload) => API.put(`/cfinance/proceed-application/${id}`,payload);
export const createMandate = (id,bank_id) => API.post(`/common/create-mandate/${id}?bank_id=${bank_id}`);
export const updateEnachStatus = (id, status) => API.put(`/common/mandate-data/${id}`, {status});
export const uploadPhysicalAgreement = (id,payload) => API.post(`/cfinance/upload-docs/${id}`, payload);

export const initiateUpiAuto = (payload,bank_id) => API.post(`/common/upi-autopay?bank_id=${bank_id}`, payload);
export const proceedPayment = (id) => API.post(`/cfinance/proceed-to-disbursement/${id}`);
export const aadhaarEsign = (payload,id) => API.post(`/common/aadhaar-esign/${id}`,payload);

export const updateLoanData = (payload, id) => API.put(`/erick/loan-details/${id}`,payload);
export const customerFieldInvestigation = (payload) => API.put(`/erick/customer-field-investigation`,payload);
export const uploadPostDisbursement = (payload) => API.put(`/erick/post-disbursement`,payload);
export const preDisbursementCustomer = (payload,id) => API.post(`/erick/pre-disbursement/${id}?type=customer`,payload);
export const preDisbursementCoCustomer = (payload,id) => API.post(`/erick/pre-disbursement/${id}?type=co-customer`,payload);
export const preDisbursementBankDocs = (payload,id) => API.post(`/erick/pre-disbursement/${id}?type=bank`,payload);
export const preDisbursementDocument = (payload,id,part) => API.post(`/erick/pre-disbursement/${id}?type=document&part=${part}`,payload);
export const addNewCoapplicant = (id) => API.put(`/cfinance/change-rejected-cocustomer/${id}`);
export const chequeOcr = (payload) => API.post(`/common/cheque-ocr`,payload);

// V2

export const coCustomerAdd = (id) => API.post(`/erick/co-customer-add/${id}`);
export const fiSendOtp = (mobile,id) => API.post(`/erick/send-otp/${id}?mobile_number=${mobile}`);
export const verifyPincode = (pin)=> API.get(`common/pincode/${pin}`)
export const updateFieldInvestigation = (payload) => API.put(`/erick/customer-field-investigation`,payload);


export const getDraftApplication = () => API.get(`/cfinance/draft`);
export const fetchAllApplications = (filter,page,limit) => API.get(`/common/all-applications?${ filter !== 'all'  ? "type=filter&filter="+filter+'&' : ''}limit=${limit}&page=${page}`);
export const searchApplication = (query,limit) => API.get(`/common/all-applications?type=search&param=${query}&limit=${limit}`);

export const getSpecificDoc = (applicationId,name,collection) => API.get(`/erick/get-specific-docs/${applicationId}?document_name=${name}&collection=${collection}`);
export const uploadDoc = (payload) => API.post(`/erick/single-page-upload`, payload);
export const deleteDoc = (payload) => API.delete(`/common/delete-doc`, { data: payload });
export const submitCombineDoc = (payload) => API.put(`/erick/doc-page-completed`, payload);

export const incentiveData = (allRequired,totalCoin,id) => API.get(`/incentives/incentive-detials?allAgentIncentivesRequired=${allRequired}&agentTotalCoinRequired=${totalCoin}&applicationId=${id}`);

export const logout = () => API.post(`/cfinance/logout`);

// View Api

export const viewFile = (key) => API.get(`common/view?key=${key}`,{responseType:'blob'});
export const validateIfsc = (ifsc) => API.get(`/common/validate-ifsc/${ifsc}`);
