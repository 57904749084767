import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import UserContextProvider, { UserContext } from "./Contexts/UserContext";
import GlobalContextProvider, { GlobalContext } from "./Contexts/GlobalContext";

import { useContext, useEffect } from "react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { withSnackbar, useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useState } from "react";

import { environment } from "./config";
import NoConnection from "./components/NoConnection";
import CustomerFlow from "./Routes/CustomerFlow";

function App() {
  const [newVersionAvailable, setNewVersion] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOnline, setOnline] = useState(true);

  const onServiceWorkerUpdate = (registration) => {
    setNewVersion(true);
    setWaitingWorker(registration?.waiting);
  };

  const updateServiceWorker = () => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setNewVersion(false);
    window.location.reload();
  };

  useEffect(() => {
    if (environment === "development") {
      localStorage.setItem("nbfc", "NY00002");
    } else {
      localStorage.setItem("nbfc", "NY00002");
      // if (window.location.host.includes(`prestloans`)) {
      //   localStorage.setItem("nbfc", "PL00003");
      // } else if (window.location.host.includes(`vani`)) {
      //   localStorage.setItem("nbfc", "VA00001");
      // } else if (window.location.host.includes(`nyleasing`)) {
      //   localStorage.setItem("nbfc", "NY00002");
      // }
    }
  }, []);

  const refreshAction = (key) => {
    return (
      <>
        <Button
          className="snackbar-button"
          size="small"
          onClick={updateServiceWorker}
        >
          {"refresh"}
        </Button>
      </>
    );
  };

  // useEffect(() => {
  //   serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
  // }, []);

  useEffect(() => {
    if (newVersionAvailable) {
      enqueueSnackbar("Updating to new version", {
        variant: "success",
      });
      updateServiceWorker();
    }
    // enqueueSnackbar("A new version is available", {
    //   persist: true,
    //   variant: "success",
    //   action: refreshAction(),
    // });
  }, [newVersionAvailable]);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />

        {localStorage.getItem("nbfc") === "VA00001" && (
          <>
            <title>Vani Commercials</title>
            <link
              rel="icon"
              type="image/png"
              href="vani-favicon.ico"
              sizes="16x16"
            />
          </>
        )}
        {localStorage.getItem("nbfc") === "PL00003" && (
          <>
            <title>Prestloans</title>
            <link
              rel="icon"
              type="image/png"
              href="prest-favicon.ico"
              sizes="16x16"
            />
          </>
        )}
      </Helmet> */}

      <div className="app-wrapper">
        <CustomerFlow />
      </div>

      {/* {
        !isOnline && <NoConnection/>
      } */}
    </>
  );
}

export default App;
