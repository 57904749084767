import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchAllBanks,
  proceedPayment,
  uploadPhysicalAgreement,
  viewFile,
} from "../../../api/agent";
import MenuAppBar from "../../../components/Appbar";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import useApplication from "../../../hooks/useApplication";
import AddIcon from "@mui/icons-material/Add";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import mitcPdf from "../../../assets/MITC.pdf";
import { getBase64 } from "../../../util/convertFileBase";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOver from "../../../components/LoadingOver";
import axios from "axios";

const styles = {
  mainHead: {
    fontSize: 15,
    fontWeight: 400,
    marginBottom: 20,
    paddingBottom: 10,
    color: "gray",
    borderBottom: "2px solid #d6d4d0",
  },
  card: {
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    padding: "15px",
    borderRadius: "10px",
    cursor: "pointer",
  },
  card_disabled: {
    boxShadow:
      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
    padding: "30px",
    borderRadius: "10px",
    opacity: 0.4,
  },
  col: {
    display: "flex",
    alignItems: "Center",
    fontSize: "13px",
    fontWeight: "500",
  },

  colProgress: {
    display: "flex",
    justifyContent: "end",
    alignItems: "Center",
  },

  cardHeading: {
    fontWeight: 400,
    marginBottom: 0,
  },

  cardDesc: {
    fontWeight: 400,
    marginBottom: 0,
    color: "gray",
  },

  statusTxt: {
    marginBottom: 0,
    marginRight: 10,
  },
};

const statusColor = {
  pending: "#274384",
  unsuccessfull: "#FF0000",
  successfull: "#2AB514",
};

const PhysicalAgreement = forwardRef((props, ref) => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { bottomNav, showRewardState } = useContext(GlobalContext);
  const { application_id } = useParams();
  const [isReward, showReward] = showRewardState;
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);
  const [isDownloading, setDowloading] = useState(false);
  const [loanAgreement, setLoanAgreement] = useState("");

  const handleBack = async () => {
    navigate(`/onboard/esign-status/${application_id}`);
  };

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  const handleProceed = async () => {
    setLoading(true);
    try {
      let payload = {
        files: [
          {
            name: "loan_agreement",
            data: loanAgreement,
          },
        ],
      };

      const { data } = await uploadPhysicalAgreement(application_id, payload);
      if (data?.status) {
        // if (data?.is_coin_credited) {
        //   showReward({
        //     show: true,
        //     coin: data?.coin,
        //   });
        // }
        enqueueSnackbar("Physical Enach Uploaded Successfully", {
          variant: "success",
        });
        navigate("/home");
      }
    } catch (error) {}
    setLoading(false);
  };

  const createPdf = async () => {
    setDowloading(true);

    try {
      const res = await viewFile(`assets/MITC.pdf`);
      const existingPdfBytes = await res?.data?.arrayBuffer();

      // const existingPdfBytes = await fetch(mitcPdf).then((res) =>
      //   res.arrayBuffer()
      // );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const lenderName = form.getTextField("lenderName");
      const lenderAddress = form.getTextField("lenderAddress");
      const lenderWebsite = form.getTextField("lenderWebsite");
      const bankName = form.getTextField("bankName");
      const accountNumber = form.getTextField("accountNumber");
      const bankIfsc = form.getTextField("bankIfsc");
      const applicationNo = form.getTextField("applicationNo");
      const approvalDate = form.getTextField("approvalDate");
      const borrowerName = form.getTextField("borrowerName");
      const borrowerAddress = form.getTextField("borrowerAddress");
      const borrowerBankName = form.getTextField("borrowerBankName");
      const borrowerAccountNumber = form.getTextField("borrowerAccountNumber");
      const borrowerIfsc = form.getTextField("borrowerIfsc");
      const loanAmount = form.getTextField("loanAmount");
      const interestRate = form.getTextField("interestRate");
      const tranchTenure = form.getTextField("tranchTenure");
      const penalInterest = form.getTextField("penalInterest");
      const proccesingFee = form.getTextField("proccesingFee");
      const documentationFee = form.getTextField("documentationFee");
      const preInfo = form.getTextField("preInfo");
      const receivedAccount = form.getTextField("receivedAccount");

      lenderName.setText(journeyData?.nbfc_data?.name || "N/A");
      lenderAddress.setText(journeyData?.nbfc_data?.address || "N/A");
      lenderWebsite.setText(journeyData?.nbfc_data?.website || "N/A");
      bankName.setText(journeyData?.bank_name || "N/A");
      accountNumber.setText(journeyData?.accountNumber || "N/A");
      bankIfsc.setText(journeyData?.bank_name || "N/A");
      applicationNo.setText(journeyData?.application_id || "N/A");
      approvalDate.setText(
        moment(journeyData?.start_date).format("DD/MM/YYYY") || "N/A"
      );
      borrowerName.setText(journeyData?.full_name || "N/A");
      borrowerAddress.setText(journeyData?.current_address || "N/A");
      borrowerBankName.setText(journeyData?.bank_details?.bank_name || "N/A");
      borrowerAccountNumber.setText(
        journeyData?.bank_details?.account_number || "N/A"
      );
      borrowerIfsc.setText(journeyData?.bank_details?.ifsc_code || "N/A");
      loanAmount.setText(journeyData?.loanAmount || "N/A");
      interestRate.setText(journeyData?.interestRate || "N/A");
      tranchTenure.setText(journeyData?.tranchTenure || "N/A");
      penalInterest.setText(journeyData?.penalInterest || "N/A");
      proccesingFee.setText(journeyData?.proccesingFee || "N/A");
      documentationFee.setText(journeyData?.documentationFee || "N/A");
      preInfo.setText(journeyData?.preInfo || "N/A");
      receivedAccount.setText(journeyData?.bank_account || "N/A");

      form?.flatten();
      
      const pdfBytes = await pdfDoc.save();

      var blob = new Blob([pdfBytes], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);
      // window.open(url);

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        var base64data = reader.result;

        const downloadLink = document.createElement("a");
        const fileName = "loan_agreement.pdf";

        downloadLink.href = base64data;
        downloadLink.download = fileName;
        downloadLink.click();
        setDowloading(false);
      };
    } catch (error) {
      console.log(error);
      setDowloading(false);
    }
  };

  const handleCardClick = async () => {
    createPdf();
  };

  return (
    <>
      <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper">
          <p style={styles?.mainHead}>Download Agreement</p>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Container
                style={styles?.card}
                onClick={() => {
                  handleCardClick();
                }}
              >
                <Row>
                  <Col style={styles?.col}>
                    <div style={{ color: "#1976D2" }}>
                      <DownloadForOfflineIcon />
                    </div>
                    <div className="ms-4">LOAN AGREEMENT.PDF</div>
                  </Col>
                </Row>
              </Container>
            </Grid>
          </Grid>

          <p style={styles?.mainHead} className="mt-4">
            Upload Agreement
          </p>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Container
                style={
                  loanAgreement === ""
                    ? { ...styles?.card }
                    : { ...styles?.card, cursor: "default" }
                }
                onClick={() => {
                  if (loanAgreement === "") {
                    hiddenFileInput.current.click();
                  }
                }}
              >
                <Row>
                  <Col style={styles?.col}>
                    <div
                      style={{
                        color: loanAgreement === "" ? "#1976D2" : "green",
                      }}
                    >
                      {loanAgreement === "" ? (
                        <UploadFileIcon />
                      ) : (
                        <CheckCircleIcon />
                      )}
                    </div>
                    <div className="ms-4">Upload Agreement</div>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                      accept="application/pdf"
                      onChange={async (e) => {
                        if (e.target.files[0].type.match("application/pdf")) {
                          setLoanAgreement(await getBase64(e.target.files[0]));
                        } else {
                          enqueueSnackbar("Unsupported File type", {
                            variant: "error",
                          });
                        }
                      }}
                    />

                    {loanAgreement !== "" && (
                      <CloseIcon
                        className="ms-4 pointer"
                        onClick={() => {
                          setLoanAgreement("");
                        }}
                      />
                    )}
                  </Col>
                </Row>
              </Container>
            </Grid>
          </Grid>
        </div>

        <Paper className={`paper-bottom`} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className={
                  loanAgreement !== "" ? `btn-submit` : `btn-submit-disabled`
                }
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                disabled={loanAgreement === ""}
                onClick={handleProceed}
                // disabled={
                //   isLoading ||
                //   status.enach === "pending" ||
                //   status.upi === "pending"
                // }
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
      {isDownloading && <LoadingOver text={"Downloading"} />}
    </>
  );
});

export default PhysicalAgreement;
