import {
  Autocomplete,
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  changeDefaultBank,
  fetchAllBanks,
  proceedApplication,
  proceedPayment,
} from "../api/agent";
import MenuAppBar from "../components/Appbar";
import CustomInputAutocomplete from "../components/Autocomplete/AutoCompleteBank";
// import CircularProgress from "../../../../components/CircularProgress";
import { GlobalContext } from "../Contexts/GlobalContext";
import { LoanContext } from "../Contexts/LoanJourneyContext";
import useApplication from "../hooks/useApplication";
import loginImg from "../assets/loginMan.svg";
import OtpLogin from "./OtpLogin";
import LoginSplashScreen from "./LoginSplashScreen";
import loginManIco from "../assets/loginMan.svg";
import useAuth from "../hooks/AuthScreens/Customer/useAuth";
import { isMobValid } from "../validations/fieldValidations";

const statusColor = {
  pending: "#274384",
  unsuccessfull: "#FF0000",
  successfull: "#2AB514",
};

const PaymentMethod = forwardRef((props, ref) => {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const { bottomNav, showRewardState } = useContext(GlobalContext);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSplash, setOpenSplash] = useState(true);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");

  const { loginUser, signUpUser } = useAuth();

  // const handleSubmit = async () => {};

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenSplash(false);
    }, 3000);
  }, []);

  const handleSignin = async () => {
    let error = isMobValid(mobile);
    if (error === "") {
      setLoading(true);
      let response = await loginUser(mobile);
      if (response) {
        setOpen(true);
      }
      setLoading(false);
    } else {
      setError(error);
    }
  };

  return (
    <>
      {!openSplash && (
        <div className="login-wrapper">
          <Box
            className="kyc-wrapper w-100 bottom-content"
            // sx={{ pt: 2 }}
            ref={ref}
          >
            <CssBaseline />
            <div className="main-wrapper">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  {/* <div className="strip">&nbsp;</div> */}

                  <p className="login-icon-holder mb-0">
                    <img
                      src={loginManIco}
                      className="login-icon-man"
                      alt=""
                      width={"200px"}
                    />
                  </p>

                  <p className="login-info-txt">
                    Enter your<br></br>mobile number
                  </p>
                  <span style={{ color: "gray" }}>
                    We'll verify your mobile
                  </span>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div className="mobile-text-wrapper mt-4">
                    <input
                      autoFocus
                      type="text"
                      maxLength={10}
                      inputMode="numeric"
                      placeholder="000 000 0000"
                      className="login-mobile w-100"
                      value={mobile}
                      onChange={(e) => {
                        if (
                          e.target.value !== "" &&
                          !/^\d+$/.test(e.target.value)
                        ) {
                          return false;
                        }
                        setError("");
                        if (e.target.value.length > 10) return false;
                        setMobile(e.target.value);
                      }}
                      onBlur={(e) => {
                        setError(isMobValid(e.target.value) || "");
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleSignin();
                        }
                      }}
                    />

                    <div className="country-code">+91</div>
                  </div>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Button className="login-btn" onClick={handleSignin}>
                    CONTINUE
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Box>
        </div>
      )}

      <LoginSplashScreen open={openSplash} />

      {open && <OtpLogin open={open} handleClose={handleClose} />}
    </>
  );
});

export default PaymentMethod;
