import {
  BottomNavigation,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  Paper,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { sendOTP, verifyOTP } from "../api";
import { GlobalContext } from "../Contexts/GlobalContext";
import { UserContext } from "../Contexts/UserContext";
import useAuth from "../hooks/AuthScreens/Customer/useAuth";
import LoadingOver from "../components/LoadingOver";
import { sentOtp, verifyLogin, verifyOtp } from "../api/agent";
import { LoanContext } from "../Contexts/LoanJourneyContext";

const OtpScreen = ({ setActive }) => {
  // const [kycData, setKycData] = kycState;
  // const [merchant_id, setMerchantId] = merchantId;
  // const [isAadharVerified, setAadharVerfiy] = aadharVerifState;

  const { otpState, resetState, showRewardState } = useContext(GlobalContext);
  const { setUser, signUpDataState } = useContext(UserContext);
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [isReward, showReward] = showRewardState;

  // // const { vId } = useParams();
  const [vId, setVid] = useState("");
  const [isOver, setOver] = useState(false);
  const navigate = useNavigate();
  const { signUpUser } = useAuth();

  const [otpData, setOtpData] = otpState;
  const [isResetVerified, setResetVerified] = resetState;
  const [signUpData, setSignup] = signUpDataState;

  let inputs = [];

  const [otp, setOtp] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isRunningCRE, setLoadingCRE] = useState(false);
  const [timer, setTimer] = useState("05:00");
  const { enqueueSnackbar } = useSnackbar();

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    startTimer(5 * 60);
  }, []);

  useEffect(() => {
    setVid(otpData.vId);
  }, [otpData]);

  const handleInput = (target) => {
    if (/^\d+$/.test(target.value)) {
      getOTP();
      let nextInput = target.nextElementSibling;

      if (nextInput && target.value) {
        nextInput.focus();
        nextInput.value && nextInput.select();
      }
    } else {
      target.value = "";
    }
  };

  const handleBackspace = (target) => {
    getOTP();
    return target.value
      ? (target.value = "")
      : target.previousElementSibling?.focus();
  };

  const handleArrowLeft = (target) => {
    const previousInput = target.previousElementSibling;
    return !previousInput ? undefined : previousInput.focus();
  };
  const handleArrowRight = (target) => {
    const nextInput = target.nextElementSibling;
    return !nextInput ? undefined : nextInput.focus();
  };
  const handlePaste = (event, inputs) => {
    let pasteText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );
    inputs.forEach((input, index) => {
      input.value = pasteText[index] || ``;
    });
    getOTP();
    event.preventDefault();
  };

  function shortcut(element, key, handle, params) {
    element.addEventListener(`keydown`, (e) => {
      if (e.target.value !== "") e.preventDefault();
      return e.key.toString().toLowerCase() == key && handle(element);
    });
  }

  useEffect(() => {
    const verification = document.querySelector(`.verification`);
    const inputs = [
      input1.current,
      input2.current,
      input3.current,
      input4.current,
    ];

    verification.addEventListener("input", ({ target }) => handleInput(target));
    verification.addEventListener("paste", (e) => handlePaste(e, inputs));

    shortcut(inputs[0], `backspace`, handleBackspace);
    shortcut(inputs[1], `backspace`, handleBackspace);
    shortcut(inputs[2], `backspace`, handleBackspace);
    shortcut(inputs[3], `backspace`, handleBackspace);

    shortcut(inputs[0], `arrowleft`, handleArrowLeft);
    shortcut(inputs[1], `arrowleft`, handleArrowLeft);
    shortcut(inputs[2], `arrowleft`, handleArrowLeft);
    shortcut(inputs[3], `arrowleft`, handleArrowLeft);

    shortcut(inputs[0], `arrowright`, handleArrowRight);
    shortcut(inputs[1], `arrowright`, handleArrowRight);
    shortcut(inputs[2], `arrowright`, handleArrowRight);
    shortcut(inputs[3], `arrowright`, handleArrowRight);

    autoFetch();
  }, []);

  const autoFetch = async () => {
    const signal = new AbortController();
    setTimeout(() => {
      signal.abort();
    }, 1 * 60 * 1000);

    if ("OTPCredential" in window) {
      try {
        if (navigator.credentials) {
          try {
            await navigator.credentials
              .get({ abort: signal, otp: { transport: ["sms"] } })
              .then((content) => {
                if (content && content.code) {
                  input1.current.value = content.code[0];
                  input2.current.value = content.code[1];
                  input3.current.value = content.code[2];
                  input4.current.value = content.code[3];

                  setOtp(content.code);
                }
              })
              .catch((e) => console.log(e));
          } catch (e) {
            return;
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // if ("OTPCredential" in window) {
    //   const ac = new AbortController();

    //   navigator.credentials
    //     .get({
    //       otp: { transport: ["sms"] },
    //       signal: ac.signal,
    //     })
    //     .then((otp) => {
    //       alert(otp);

    //       if (otp.length === 4) {
    //         input1.current.value = otp[0];
    //         input2.current.value = otp[1];
    //         input3.current.value = otp[2];
    //         input4.current.value = otp[3];

    //         setOtp(otp);
    //       }
    //       ac.abort();
    //     })
    //     .catch((err) => {
    //       // alert("Some error")
    //       ac.abort();
    //     });
    // }
  };

  const getOTP = () => {
    const inputs = [
      input1.current.value,
      input2.current.value,
      input3.current.value,
      input4.current.value,
    ];
    let temp = inputs[0] + inputs[1] + inputs[2] + inputs[3];
    setOtp(temp);
  };

  useEffect(() => {
    if (validateOtp()) {
      submitOTP();
    }
  }, [otp]);

  const validateOtp = () => {
    if (otp.length === 4 && /^\d+$/.test(otp)) {
      return true;
    }
    return false;
  };

  const requestOTP = async () => {
    if (otpData.method === "signup") {
      try {
        let payload = {
          data: {
            mobile_number: otpData.mobile,
          },
        };

        let { data } = await sendOTP(payload, "signin");

        if (data?.status) {
          setOtpData((st) => ({
            ...st,
            mobile: otpData.mobile,
            path: "home",
            method: "signup",
            vId: data?.data?.verificationId,
          }));
        }
      } catch (error) {
        enqueueSnackbar(error.response.data.errors[0].message, {
          variant: "error",
        });
      }
    } else if (
      otpData.method === "create-customer" ||
      otpData.method === "create-Cocustomer"
    ) {
      try {
        let payload = {
          applicationId: otpData?.application_id,
          method:
            otpData.method === "create-customer"
              ? "CREDIT_PULL_CONSENT_OTP"
              : "CO_CUSTOMER_CREDIT_PULL_CONSENT_OTP",
        };

        let { data } = await sentOtp(payload, "signin");

        if (data?.status) {
          setOtpData((st) => ({
            ...st,
            mobile: otpData.mobile,
            path:
              otpData.method === "create-customer"
                ? "create-loan/status"
                : "create-loan/status-cocustomer",
            method: otpData.method,
            vId: data?.data?.verificationId,
          }));
        }
      } catch (error) {
        enqueueSnackbar(
          error.response.data.errors[0].message || "Server Failed to respond",
          {
            variant: "error",
          }
        );
      }
    }
  };

  const submitOTP = async () => {
    setLoading(true);
    let payload = {
      otp,
      verificationId: vId,
    };

    if (
      otpData.method === "create-customer" ||
      otpData.method === "create-customer"
    ) {
      setTimeout(() => {
        setLoading(false);
        setLoadingCRE(true);
      }, 2000);
    }

    try {
      let data;
      if (otpData.method === "customer-login") {
        let { data: response } = await verifyLogin(payload);
        setUser({
          ...response?.data,
          name: response?.data?.pan_details?.name || "User",
        });
        navigate(`/${otpData.path}`);
      } else {
        let { data: response } = await verifyOtp(payload);
        data = response;
      }
      setLoading(false);
      setLoadingCRE(false);

      if (data?.status) {
        if (otpData.method === "signup") {
          setUser(data.data);
          navigate(`/${otpData.path}`);
        }
        if (otpData.method === "create-customer") {
          if (data?.is_coin_credited) {
            showReward({
              show: true,
              coin: data?.coin,
            });
          }
          navigate(`/${otpData.path}/${otpData?.application_id}`);
        }
        if (otpData.method === "create-Cocustomer") {
          if (data?.is_coin_credited) {
            showReward({
              show: true,
              coin: data?.coin,
            });
          }
          navigate(`/${otpData.path}/${otpData?.application_id}`);
        }
        if (otpData.method === "field-investigation") {
          setOtpData({
            ...otpData,
            verified: true,
          });
          setJourneyData({
            ...journeyData,
            loaded: true,
          });
          navigate(`${otpData.path}`);
        }
      }
    } catch (error) {
      setLoading(false);
      setLoadingCRE(false);
      navigate(-1);
      enqueueSnackbar(error.response?.data?.message, {
        variant: "error",
      });
    }
  };

  const handleResend = () => {
    startTimer(5 * 60);
    setOver(false);
    setOtp("");
    input1.current.value = "";
    input2.current.value = "";
    input3.current.value = "";
    input4.current.value = "";
    requestOTP();
    enqueueSnackbar("OTP resent successfully", { variant: "success" });
  };

  function startTimer(duration, display) {
    var timer = duration,
      minutes,
      seconds;
    var interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setTimer(minutes + ":" + seconds);

      if (--timer === 0) {
        clearInterval(interval);
        setTimer("00:00");
        setOver(true);
      }
    }, 1000);
  }

  return (
    <>
      <div className="content-center">
        <Box sx={{ pb: 7 }} className="response-wrapper">
          <CssBaseline />

          <div className={`appbar-wrapper`}>
            <div className="appbar">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ArrowBackIcon
                    className="ms-3 pointer"
                    onClick={() => {
                      if (otpData.method === "create-customer") {
                        navigate(
                          "/onboard/pan-details/" + otpData?.application_id
                        );
                      } else if (otpData.method === "create-Cocustomer") {
                        navigate(
                          "/onboard/co-customer-details/" +
                            otpData?.application_id
                        );
                      } else if (otpData.method === "field-investigation") {
                        navigate(`${otpData.path}`);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>

          <div className="verification-wrapper">
            <Grid container spacing={2} className="pb-5">
              <Grid item xs={12} sm={12}>
                <Box className="form-box">
                  <div className="verification_head">
                    <p className="main-text">Verify Mobile</p>
                    <p className="desc" style={{ fontWeight: "500" }}>
                      Check your inbox we have sent you the verification code at{" "}
                      <span>{otpData.mobile}</span>
                      <br></br>
                    </p>
                  </div>

                  <form class="verification w-100">
                    <input
                      ref={input1}
                      type="number"
                      autoComplete="off"
                      class="verification__input  verification__input--1"
                      id="verification-input-1"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                    />
                    <input
                      ref={input2}
                      type="number"
                      autoComplete="off"
                      class="verification__input  verification__input--2"
                      id="verification-input-2"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                    />
                    <input
                      ref={input3}
                      type="number"
                      autoComplete="off"
                      class="verification__input  verification__input--3"
                      id="verification-input-3"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                    />
                    <input
                      ref={input4}
                      type="number"
                      class="verification__input  verification__input--4"
                      id="verification-input-4"
                      autoComplete="off"
                      placeholder="-"
                      maxLength="1"
                      inputMode="numeric"
                    />
                  </form>
                  <div className="verification_footer">
                    <p className="duration">({timer})</p>
                    <p className="info">
                      This session will end in 5 minute.
                      <br />
                      {isOver && (
                        <>
                          Didn’t receive a code?{" "}
                          <span
                            className="pointer"
                            style={{ fontWeight: "500", color: "blue" }}
                            onClick={() => {
                              handleResend();
                            }}
                          >
                            Resend Code
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </div>

          <Paper
            sx={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              boxShadow: "none",
            }}
            elevation={3}
          >
            {/* <BottomNavigation style={{ height: "auto" }}>
          <div className="button-wrapper w-100">
            <Button
              className={`btn-submit ${!isLoading && "btn-submit-disabled"}`}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 2 }}
              onClick={() => {}}
            >
              {!isLoading ? (
                "Verify"
              ) : (
                <>
                  <CircularProgress
                    className="me-2 mb-1"
                    size={20}
                    style={{ color: "white" }}
                  />
                  verifying
                </>
              )}
            </Button>
          </div>
        </BottomNavigation> */}
          </Paper>
        </Box>
      </div>
      {isLoading && <LoadingOver text={"Verifying"} />}
      {isRunningCRE && <LoadingOver text={"Running Credit Engine"} />}
    </>
  );
};

export default OtpScreen;
