import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomInputAutocomplete2({
  options,
  onChange: setVal,
  text,
}) {
  return (
    <Autocomplete
      sx={{
        "& input": {
          width: "100% !important",
        },
      }}
      id="custom-input-demo"
      options={options}
      disableClearable
      freeSolo
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input
            placeholder={text ? text : "Select Bank"}
            type="text"
            {...params.inputProps}
            className="form-input"
          />
        </div>
      )}
      onInputChange={(e) => {
        setVal(e.target.value);
      }}
      onChange={(e) => {
        setVal(e.target.innerText);
      }}
    />
  );
}
