import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomInputAutocomplete({ text, options, onChange:setVal }) {
  return (
    <Autocomplete
      sx={{
        "& input": {
          width: "100% !important"
        },
      }}
      id="custom-input-demo"
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input placeholder={text ? text : "Select an option"} type="text" {...params.inputProps} className="form-input"/>
        </div>
      )}
      onChange={(e,value) => {
        setVal(value)
      }}
    />
  );
}
