import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import { userLogin, signIn, verifyAuth } from "../../../api/agent";
import { useSnackbar } from "notistack";
import { GlobalContext } from "../../../Contexts/GlobalContext";

export default function useAuth() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const { otpState } = useContext(GlobalContext);

  const [error, setError] = useState(null);
  const [otpData, setOtpData] = otpState;

  const loginUser = async (mobile) => {
    let payload = {
      mobile_number: mobile,
    };

    try {
      let { data } = await userLogin(payload);
      if (data?.status) {

        console.log(data);
        
        setOtpData((st) => ({
          ...st,
          mobile: mobile,
          path: "home",
          method: "customer-login",
          vId: data?.verificationId,
        }));

        return true

        // navigate(`/otp/${data?.verificationId}`);
      }
    } catch (error) {
      enqueueSnackbar(error?.response?.data?.message || "Server Down", {
        variant: "error",
      });
      return false;
    }
  };

  // const signUpUser = async (payload,mobile) => {
  //   try {
  //     let { data } = await sendOTP(payload,'signup');
  //     if (data?.status) {
  //       setOtpData((st) => ({
  //         ...st,
  //         mobile: mobile,
  //         path: "home",
  //         method:"signup",
  //         vId:data?.data?.verificationId
  //       }));
  //       navigate(`/otp/${data?.data?.verificationId}`);
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(error.response.data.errors[0].message, {variant:"error"})
  //   }
  // }

  // const completeSignUp = async (name, pincode, setLoading) => {

  //   let payload = {
  //     name,
  //     business_pincode: pincode,
  //   };

  //   setLoading(true)

  //   try {
  //     let { data } = await addName(payload);
  //     if (data?.status) {
  //       setUser((st)=>({...st, name}));
  //       navigate("/home");
  //     }
  //   } catch (error) {
  //     enqueueSnackbar(error.response.data.errors[0].message, {variant:"error"})
  //   }

  //   setLoading(false)

  // };

  return {
    loginUser,
    // signUpUser,
    error,
  };
}
