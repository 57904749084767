import { Button, CircularProgress } from "@mui/material";
import React, { useContext } from "react";

const LoadingOver = ({text}) => {

  return (
    <div className="kyc-dialog">
      <div className="dialog" style={{width:"auto"}}>
        <p className="welcome text-center"><CircularProgress /></p>
        <p className="welcome mt-2">{text? text : "Loading"}</p>
      </div>
    </div>
  );
};

export default LoadingOver;
