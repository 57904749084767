import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const LoanScreen = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="content-center">
        <Grid container spacing={2} className="p-3 response-wrapper">
          <Grid item xs={12} sm={12} className="mt-4">
            
            {/* <Button
              className="w-100 home-btn"
              variant="outlined"
              onClick={() => {
                navigate("/recent-applications");
              }}
            >
              Recently Submitted
            </Button> */}

            <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/home");
              }}
            >
              Draft Applications
            </Button>

            {/* <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/all-applications");
              }}
            >
              All Applications
            </Button> */}

            {/* <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/all-applications");
              }}
            >
              All Applications
            </Button>

            <Button className="w-100 home-btn mt-3" variant="outlined" onClick={() => navigate("/under-construction")}>
              Amount Recieved
            </Button>

            <Button
              className="w-100 home-btn mt-3"
              variant="outlined"
              onClick={() => {
                navigate("/collections");
              }}
            >
              Collections
            </Button> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default LoanScreen;
