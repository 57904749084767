import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { verifyAuth } from "../../../api";
import { useLocation } from "react-router-dom";
import { GlobalContext } from "../../../Contexts/GlobalContext";

export default function usePermission() {
  const { permissionState } = useContext(GlobalContext);
  const [permissions, setPermissions] = permissionState;

  useEffect(() => {
    getCameraState();
    getLocationState();
  }, []);

  let options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const getUserLocation = async (setState) => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            setPermissions((st) => ({ ...st, location: "GRANTED" }));
            await getLocation(setState);
          } else if (result.state === "prompt") {
            setPermissions((st) => ({ ...st, location: "PROMPT" }));
            await getLocation(setState);
          } else if (result.state === "denied") {
            setPermissions((st) => ({ ...st, location: "DENIED" }));
          }
          result.onchange = function () {
            getLocationState();
          };
        });
    } else {
      setPermissions((st) => ({ ...st, location: "NOTAVAIL" }));
    }
  };

  const getLocation = async (setState) => {
    navigator.geolocation.getCurrentPosition(
      async ({ coords }) => {
        navigator.geolocation.getCurrentPosition(async ({ coords }) => {
          console.log(coords);
          setState((prev) => ({
            ...prev,
            status: "success",
            lat: "" + coords.latitude,
            long: "" + coords.longitude,
          }));
        });
      },
      (err) => {
        console.log(err);
        setState((prev) => ({
          ...prev,
          status: "failed",
          lat: "",
          long: "",
        }));
      },
      options
    );
  };

  const getLocationV2 = async (options) => {
    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    );
  };

  const getLocationState = () => {
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            setPermissions((st) => ({ ...st, location: "GRANTED" }));
          } else if (result.state === "prompt") {
            setPermissions((st) => ({ ...st, location: "PROMPT" }));
          } else if (result.state === "denied") {
            setPermissions((st) => ({ ...st, location: "DENIED" }));
          }
          result.onchange = function () {
            getLocationState();
          };
        });
    } else {
      setPermissions((st) => ({ ...st, location: "NOTAVAIL" }));
    }
  };

  // const getCameraState = () => {
  //   navigator.permissions
  //     .query({ name: "camera" })
  //     .then((permissionObj) => {
  //       switch (permissionObj.state) {
  //         case "denied":
  //           setPermissions((st) => ({ ...st, camera: "DENIED" }));
  //           break;
  //         case "granted":
  //           setPermissions((st) => ({ ...st, camera: "GRANTED" }));
  //           break;
  //         case "prompt":
  //           setPermissions((st) => ({ ...st, camera: "PROMPT" }));
  //           break;
  //       }
  //     })
  //     .catch((error) => {
  //       setPermissions((st) => ({ ...st, camera: "NOTAVAIL" }));
  //     });
  // };

  const getCameraState = (setState) => {
    navigator.permissions
      .query({ name: "camera" })
      .then(async function (result) {
        if (result.state === "granted") {
          setPermissions((st) => ({ ...st, camera: "GRANTED" }));
        } else if (result.state === "prompt") {
          setPermissions((st) => ({ ...st, camera: "PROMPT" }));
        } else if (result.state === "denied") {
          setPermissions((st) => ({ ...st, camera: "DENIED" }));
        }
        result.onchange = function () {
          getCameraState(setState);
        };
      });
  };

  return {
    permissions,
    getUserLocation,
    getLocationV2,
  };
}
