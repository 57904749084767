import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import Congrats from "./Congrats";
import Rejected from "./Rejected";
import UnderReview from "./UnderReview";

const CoCustomerStatus = () => {
  const { application_id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  useEffect(() => {

    console.log("hereeeeeeeee", journeyData?.stage);

    switch (journeyData?.stage) {
      case "CO_CUSTOMER_CREDIT_ENGINE_FAILED":
        setStatus("review");
        break;
      case "CO_CUSTOMER_UNDER_REVIEW":
        setStatus("review");
        break;
      case "CO_CUSTOMER_EMBIFI_REJECTED":
        setStatus("rejected");
        break;
      case "CO_CUSTOMER_CREATION":
        setStatus("success");
        break;
      case "CREATE_BANK":
        setStatus("success");
        break;
    }
  }, [journeyData?.stage]);

  const { getApplicationDetails } = useApplication();

  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  return (
    <>
      <div className="response-wrapper w-100">
        {status === "success" && (
          <Congrats
            application_id={application_id}
            action={() => {
              navigate("/onboard/loan-creation/" + application_id);
            }}
          />
        )}
        {status === "review" && (
          <UnderReview
            origin={"co-customer"}
            action={() => {
              window.location.reload()
            }}
          />
        )}
        {status === "rejected" && (
          <Rejected
            origin={"co-customer"}
            action={() => {
              navigate("/onboard/co-customer/" + application_id);
            }}
          />
        )}
      </div>
      {status === "loading" && <LoadingOver />}
    </>
  );
};

export default CoCustomerStatus;
