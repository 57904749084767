import { Button, Grid } from "@mui/material";
import React from "react";
import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import UserAvatar from "../../components/Avatar";
import useLogout from "../../hooks/AuthScreens/Customer/useLogout";
import { UserContext } from "../../Contexts/UserContext";
import faqIcon from "../../assets/faq.svg";
import supportIcon from "../../assets/support.svg";
import { useNavigate } from "react-router-dom";
import { FILE_URL } from "../../util/constants";
import PageUnder from "../PageUnder";

const Profile = () => {
  const { user } = useContext(UserContext);
  const { logoutUser } = useLogout();
  const navigate = useNavigate();
  return (
    <>
      <div className="content-center">
        <Grid
          container
          spacing={2}
          className="p-3 mt-3 profile-wrapper response-wrapper"
        >
          <Grid item xs={12} sm={12}>
            <PageUnder/>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <div className="profile-card">
              <Container>
                <Row>
                  <Col xs={4} className={"d-flex justify-content-end pt-4"}>
                    <UserAvatar />
                    <img className="profile-pic" style={{width:"80px", height:"80px"}} src={FILE_URL+`user=dealer&id=${user?.id}&type=selfie`} alt={user?.name}/>   
                  </Col>
                  <Col xs={8}>
                    <p className="card-text company mt-3">{user?.business_details?.name}</p>
                    <p className="card-text user">{user?.name}</p>
                    <p className="card-text mob">+91 {user?.mobile_number}</p>
                  </Col>
                </Row>
                <Row>
                  <Col className={"d-flex justify-content-end"}>
                    <Button className="btn-view" onClick={()=>navigate('/home/profile-view')}>View Profile</Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="profile-card card-button content-center pt-5 pointer" onClick={() => navigate("/under-construction")}>
              <div>
                <p className="text-center mb-3">
                  <img src={faqIcon} width="40px" />
                </p>
                <p className="text-center card-text user">FAQs</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="profile-card card-button content-center pt-5 pointer" onClick={() => navigate("/under-construction")}>
              <div>
                <p className="text-center mb-3">
                  <img src={supportIcon} width="60px" />
                </p>
                <p className="text-center card-text user">Support</p>
              </div>
            </div>
          </Grid>

          <p className=" text-center logout-btn px-3">
            <Button
              className="btn-logout"
              variant="outlined"
              onClick={() => {
                logoutUser();
              }}
            >
              Logout
            </Button>
          </p> */}
        </Grid>
      </div>
    </>
  );
};

export default Profile;
