import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import MenuAppBar from "../../../components/Appbar";
import KycPop from "../../../components/KycPop";

import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { CustomerContext } from "../../../Contexts/CustomerContext";
import SearchIcon from "@mui/icons-material/Search";
import emptyIco from "../../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectCom from "../../../components/Select";
import { fetchAllApplications, searchApplication } from "../../../api/agent";

export default function AllApplications() {
  const ref = useRef(null);
  const selectRef = useRef(null);

  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [status, setStatus] = useState("All");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState(false);
  const [time, setTime] = useState("Any time");
  const [filter, setFilter] = useState("All Applications");

  const navigate = useNavigate();

  const { showPermissionState, applicationState, pathState } =
    useContext(GlobalContext);
  const [_, setSelectedApplication] = applicationState;
  const [applicationPath, setAppPath] = pathState;

  const { detailState, vehicleState } = useContext(CustomerContext);
  const [vehicleDetails, setVehicleDetails] = vehicleState;

  const fetchData = async () => {
    setLoading(true);
    try {
      let timeQuery = {};
      // if (time === "Any time") {
      //   timeQuery = { type: "All", value: 1 };
      // } else {
      //   timeQuery = {
      //     type: time.split(" ")[1].toLowerCase(),
      //     value: time.split(" ")[0],
      //   };
      // }
      let { data } = await fetchAllApplications(
        filter.split(" ")[0]?.toLowerCase(),
        1,
        500
      );

      setApplications(data?.data);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [filter]);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home/loan");
    };
  });

  let timeOut;

  useEffect(() => {
    if (searchTxt !== "") {
      clearTimeout(timeOut);
      setLetSearch(false);
      timeOut = setTimeout(() => {
        setLetSearch(true);
      }, 1000);
    }
  }, [searchTxt]);

  useEffect(() => {
    searchTxt !== "" && setSearching(true);

    if (letSearch) {
      if (searchTxt !== "") {
        (async () => {
          let timeQuery = {};
          // if (time === "Any time") {
          //   timeQuery = { type: "All", value: 1 };
          // } else {
          //   timeQuery = {
          //     type: time.split(" ")[1].toLowerCase(),
          //     value: time.split(" ")[0],
          //   };
          // }

          let { data } = await searchApplication(searchTxt, 500);
          if (data?.status) {
            setApplications(data?.data);
          }
        })();
      } else {
        fetchData(status);
      }
      setSearching(false);
    }
  }, [letSearch]);

  const getStatus = (val) => {
    if (val?.embifi_approval_details?.status) {
      if (val?.is_disbursed) {
        if (val?.is_closed) {
          return <span style={{ color: "#000000" }}>Closed</span>;
        } else {
          return <span style={{ color: "#00A945" }}>Disbursed</span>;
        }
      } else {
        return <span style={{ color: "#00A945" }}>Approved</span>;
      }
    } else if (val?.embifi_approval_details?.status === null) {
      return <span style={{ color: "#9B319F" }}>Pending</span>;
    } else {
      return <span style={{ color: "#F13131" }}>Rejected</span>;
    }
  };

  return (
    <div className="content-center">
      <Box sx={{ pb: 7 }} ref={ref} className="response-wrapper">
        <Grid container spacing={2} className="p-3 pt-0">
          <Grid item xs={12} sm={12}>
            <div className="page-head">
              <ArrowBackIcon
                sx={{ color: "#274384" }}
                className="pointer"
                onClick={() => {
                  navigate("/home/loan");
                }}
              />

              <Select
                id="demo-simple-select"
                value={filter}
                label="Age"
                disableUnderline
                sx={{
                  "& fieldset": { border: "none" },
                  color: "rgba(39, 67, 132, 1)",
                  fontWeight: 500,
                  fontSize: 22,
                }}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              >
                <MenuItem value={"All Applications"}>All Applications</MenuItem>
                ;
                <MenuItem value={"Approved Applications"}>
                  Approved Applications
                </MenuItem>
                ;
                <MenuItem value={"Rejected Applications"}>
                  Rejected Applications
                </MenuItem>
                ;
                <MenuItem value={"Draft Applications"}>
                  Pending Applications
                </MenuItem>
                ;
              </Select>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <div style={{ position: "relative" }}>
              <input
                type={"text"}
                placeholder="Search Name/PAN/Number"
                class="form-input input-blue input-blue-search w-100"
                value={searchTxt}
                onChange={(e) => {
                  setSearchTxt(e.target.value);
                }}
              />

              <SearchIcon
                className="search-ico"
                style={{ color: "gray" }}
                onClick={() => {}}
              />
            </div>
          </Grid>

          {/* <Grid item xs={4} sm={4} className="ps-2">
          <SelectCom
            reference={selectRef}
            search={true}
            state={time}
            default={true}
            options={[
              "Any time",
              "1 week",
              "1 month",
              "3 month",
              "6 month",
              "1 year",
            ]}
            styleText={true}
            isBlue={true}
            getData={(val) => {
              setTime(val);
            }}
          />
        </Grid> */}
        </Grid>
        <Grid container spacing={2} className="p-3 pt-0">
          <Grid item xs={12} sm={12}>
            {/* Chip sub filter was here */}

            {isSearching && (
              <p className="w-100 text-center mt-2 mb-2">
                <CircularProgress size={25} />
              </p>
            )}

            {!isLoading ? (
              <>
                {applications.length !== 0 &&
                  applications.map((val) => {
                    return (
                      <>
                        <Container
                          className="load-collapse-row collapse-shadow pointer"
                          onClick={() => {
                            // setSelectedApplication(val?.id);
                            // fetchStage(val?.id);
                            // setAppPath("ALL");
                            // navigate("/view/profile/" + val?.id);
                          }}
                        >
                          <Row className="mt-2">
                            <Col className="font-blue">
                              <div className="w-100">
                                <div>
                                  {val?.customerData?.pan_details?.name}
                                </div>
                                <div style={{ fontSize: "10px" }}>
                                  {val?.customer_id}
                                </div>
                              </div>
                            </Col>
                            <Col className="font-blue">{val?.nbfc_id}</Col>

                            <Col className="font-blue align-right">{getStatus(val)}</Col>
                          </Row>
                          {filter === "Rejected Applications" && (
                            <Row className="">
                              <Col style={{ fontSize: 10, color: "red" }}>
                                {val?.embifi_approval_details?.remark}
                              </Col>
                            </Row>
                          )}
                        </Container>
                      </>
                    );
                  })}
                {applications.length === 0 && (
                  <p className="text-center mt-5">
                    <img src={emptyIco} />
                    <p style={{ color: "gray" }}>No Applications here</p>
                  </p>
                )}{" "}
              </>
            ) : (
              <>
                {["", "", ""].map((val) => {
                  return (
                    <>
                      <Container className="load-collapse-row collapse-blue pointer">
                        <Row className="mt-2">
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                          <Col className="font-blue">
                            <Skeleton width={"100%"} />
                          </Col>
                        </Row>
                      </Container>
                    </>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
