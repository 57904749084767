import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import CatureUpload from "../../../components/CaptureV2";
import MenuAppBar from "../../../components/Appbar";
import { useNavigate, useParams } from "react-router-dom";
import { panUpload } from "../../../api";
import { CustomerContext } from "../../../Contexts/CustomerContext";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import LoanJourneryContext, {
  LoanContext,
} from "../../../Contexts/LoanJourneyContext";
import { createCustomer, getAnchors } from "../../../api/agent";
import CustomInputAutocomplete from "../../../components/Autocomplete/AutoComplete";

const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const PanUpload = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const isKeyboardOpen = useDetectKeyboardOpen();

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [anchorList, setAnchorList] = useState([]);

  const [errors, setErrors] = useState({
    pan_number: "",
    aadhaar_front: false,
    aadhaar_back: false,
    pan_image: "",
  });

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);

  const isPanValid = (val) => {
    let pan_pattern = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (pan_pattern.test(val)) return "";
    return "Please re-check you PAN";
  };

  const isValid = () => {
    let valid = true;
    let errors = {
      pan_number: journeyData?.isPanOcr
        ? ""
        : isPanValid(journeyData?.pan_number),
      aadhaar_front:
        journeyData?.aadhaar_front !== "" ? "" : "This field is required",
      aadhaar_back:
        journeyData?.aadhaar_back !== "" ? "" : "This field is required",
      pan_image: journeyData?.isPanOcr
        ? journeyData?.pan_image !== ""
          ? ""
          : "This field is required"
        : "",
    };

    console.log(errors);

    for (let i in errors) {
      if (errors[i].length > 0) {
        valid = false;
      }
    }

    return valid;
  };

  const handleSubmit = async () => {
    if (isValid()) {
      setLoading(true);

      let payload = {
        // anchor_id: journeyData?.anchor_id,
        pan: journeyData?.isPanOcr
          ? journeyData?.pan_image
          : journeyData?.pan_number,
        aadhaar_front: journeyData?.aadhaar_front,
        aadhaar_back: journeyData?.aadhaar_back,
        // isPanOcr: journeyData?.isPanOcr,
      };

      try {
        let { data } = await createCustomer(payload);
        if (data?.status) {
          let { pan_details, aadhaar_details, application_id } = data?.data;

          let address = aadhaar_details?.address.split(",");

          setJourneyData({
            ...journeyData,
            full_name: pan_details?.name,
            pan_number: pan_details?.number,
            aadhaar_address: aadhaar_details?.address,
            aadhaar_pincode: address[address?.length - 1]?.trim(),
            is_address_same: false,
            current_address: "",
            current_pincode: "",
            is_property_owned: true,
            mobile_number: "",
            email_id: "",
          });
          navigate("/onboard/business-details/" + application_id);
        }
      } catch (err) {
        enqueueSnackbar(err?.response?.data?.message, { variant: "error" });
      }
      setLoading(false);
    } else {
      enqueueSnackbar("Please re-check your inputs", { variant: "error" });
    }
  };

  const getAnchorsList = async () => {
    try {
      // let anchors = [{name:""},{}]
      let { data } = await getAnchors();
      if (data?.status) {
        let anchors = data?.data?.map((val) => {
          return `${val?.anchor_id} - ${val?.data?.name}`;
        });

        setAnchorList(anchors);
      }
    } catch (error) {}
  };

  const handleBack = async () => {
    navigate("/home");
  };

  // useEffect(() => {
  //   getAnchorsList();
  // }, []);

  // useEffect(()=>{
  //   console.log(journeyData?.isPanOcr);
  // },[journeyData])

  return (
    <>
      <Box className="w-100 kyc-wrapper" sx={{ pb: 7, pt: 7 }} ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper w-100">
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <label className="form-label">
                  Personal PAN <span className="mand-star">*</span>
                </label>
              </Grid>
            </Grid>

            {/* ////////////////////////////////////////////////////////////////// */}

            {/* <RadioGroup
              className="mb-3"
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={journeyData?.isPanOcr ? "true" : "false"}
              name="radio-buttons-group"
              onChange={(e) => {
                setJourneyData({
                  ...journeyData,
                  isPanOcr: e.target.value === "true" ? true : false,
                });
                setErrors({ errors, pan_number: "" });
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControlLabel
                    value="false"
                    control={<Radio sx={radioStyle} />}
                    label={<span style={{ fontSize: "10px" }}>PAN Number</span>}
                    // sx={labelStyle}
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <FormControlLabel
                    value="true"
                    control={<Radio sx={radioStyle} />}
                    label={
                      <span style={{ fontSize: "10px" }}>
                        Upload / Capture PAN
                      </span>
                    }
                    sx={labelStyle}
                  />
                </Grid>
              </Grid>
            </RadioGroup> */}

            {!journeyData?.isPanOcr && (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} className="input-pan">
                  <input
                    className="form-input w-100"
                    autoComplete="given-name"
                    name="pannumber"
                    maxlength="10"
                    required
                    fullWidth
                    placeholder="Enter your Personal PAN Number"
                    value={journeyData.pan_number}
                    onChange={(e) => {
                      setErrors({ errors, pan_number: "" });
                      setJourneyData({
                        ...journeyData,
                        pan_number: e.target.value.toUpperCase(),
                      });
                    }}
                    onBlur={(e) => {}}
                  />
                </Grid>
              </Grid>
            )}

            {journeyData?.isPanOcr && (
              <CatureUpload
                error={errors?.pan_image}
                // errorTxt={pErrorTxt}
                imageOnly={true}
                text={"Upload/Capture [ Image files only ]"}
                setFile={(file) => {
                  //   setPError(false);
                  //   setPErrorTxt("");
                  setJourneyData({
                    ...journeyData,
                    pan_image: file,
                  });
                }}
                tempState={journeyData?.pan_image}
              />
            )}

            {/* ////////////////////////////////////////////////////////////////// */}

            <Grid container spacing={2} className="mt-2">
              <Grid item xs={12} sm={12}>
                <label className="form-label">
                  Aadhaar Front <span className="mand-star">*</span>
                </label>
              </Grid>
            </Grid>

            <CatureUpload
              error={errors?.aadhaar_front}
              // errorTxt={pErrorTxt}
              text={"Upload/Capture [ Image files only ]"}
              imageOnly={true}
              setFile={(file) => {
                //   setPError(false);
                //   setPErrorTxt("");
                setJourneyData({
                  ...journeyData,
                  aadhaar_front: file,
                });
              }}
              tempState={journeyData?.aadhaar_front}
            />

            <Grid container spacing={2} className={"mt-2"}>
              <Grid item xs={12} sm={12}>
                <label className="form-label">
                  Aadhaar Back <span className="mand-star">*</span>
                </label>
              </Grid>
            </Grid>

            <CatureUpload
              error={errors?.aadhaar_back}
              // errorTxt={pErrorTxt}
              text={"Upload/Capture [ Image files only ]"}
              imageOnly={true}
              setFile={(file) => {
                //   setPError(false);
                //   setPErrorTxt("");
                setJourneyData({
                  ...journeyData,
                  aadhaar_back: file,
                });
              }}
              tempState={journeyData?.aadhaar_back}
            />
          </>
        </div>

        <Paper className="paper-bottom" elevation={3}>
          <BottomNavigation style={{ height: "auto", paddingTop: "3px" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default PanUpload;
