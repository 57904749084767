import { Button, Card, CardActions, CardContent, Grid } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getDraftApplication } from "../../api/agent";
import handMoney from "../../assets/hand.svg";
import sittingMan from "../../assets/sittingMan.svg";
import LoadingOver from "../../components/LoadingOver";
import useApplication from "../../hooks/useApplication";

const Home = () => {
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const fetchDraftApplications = async (type, time) => {
    setLoading(true);
    try {
      let { data } = await getDraftApplication();
      setApplications(data?.loans);
    } catch (error) {}
    setLoading(false);
  };

  const mapStage = (stage) => {
    switch (stage) {
      case "CUSTOMER_BASIC_DETAILS":
        return "Customer Details";
        break;
      case "CUSTOMER_BUSINESS_DETAILS":
        return "Business Details";
        break;
      case "CUSTOMER_EMBIFI_REJECTED":
        return "Rejected";
        break;
      case "CUSTOMER_CREDIT_ENGINE_FAILED":
        return "Under Review";
        break;
      case "CUSTOMER_UNDER_REVIEW":
        return "Under Review";
        break;
      case "CO_CUSTOMER_CREATION":
        return "Co-Applicant Onboarding";
        break;
      case "CO_CUSTOMER_RECREATION":
        return "Co-Applicant Re-creation";
        break;
      case "CO_CUSTOMER_BASIC_DETAILS":
        return "Co-Applicant Basic Details";
        break;
      case "CO_CUSTOMER_EMBIFI_REJECTED":
        return "Co-Applicant Rejected";
        break;
      case "CO_CUSTOMER_CREDIT_ENGINE_FAILED":
        return "Co-Applicant Under Review";
        break;
      case "CO_CUSTOMER_UNDER_REVIEW":
        return "Co-Applicant Under Review";
        break;
      case "APPLICATION_UNDER_REVIEW":
        return "Under Review";
        break;
      case "CREATE_BANK":
        return "Add bank";
        break;
      case "LOAN_OFFER":
        return "Loan Summary";
        break;
      case "BANK_ACCOUNTS":
        return "Bank Accounts";
        break;
      case "PAYMENT_METHOD":
        return "Payment Selection";
        break;
      case "AADHAAR_E_SIGN":
        return "MITC";
        break;
      case "AADHAAR_E_SIGN_STATUS":
        return "Aadhaar e-sign Status";
        break;
      case "PENNY_DROP_FAILED":
        return "Bank Details Under Review";
        break;
      case "WAITING_FOR_DISBURSAL":
        return "Waiting Disbursal";
        break;
      case "DISBURSED":
        return "Disbursed";
        break;
      case "COMPLETED":
        return "Completed";
        break;
    }
  };

  const { getStage } = useApplication();

  useEffect(() => {
    fetchDraftApplications();
  }, []);

  return (
    <>
      <div className="content-center">
        {isLoading && <LoadingOver />}
        {!isLoading && (
          <Grid container spacing={2} className="p-3 response-wrapper">
            {applications?.length === 0 ? (
              <Grid item xs={12} sm={12} style={{ marginTop: "70px" }}>
                <div className="home-card-new">
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={6}>
                      <img
                        className="sitting-img"
                        draggable={false}
                        src={sittingMan}
                        width={230}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} style={{ position: "relative" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <div>
                          <p className="apply-credit-text mb-2">
                            Apply Credit Line
                          </p>
                          <Button
                            className="apply-now-btn"
                            variant="outlined"
                            onClick={() => {
                              navigate(`/onboard/pan-upload`);
                            }}
                          >
                            Apply now
                          </Button>
                        </div>
                      </div>
                      {/* <p className="apply-credit-text">Apply Credit Line</p>   */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} style={{ marginTop: "70px" }}>
                <Container
                  className="application-details-card p-0"
                  onClick={() => {
                    // setSelectedApplication(val?.id);
                    // fetchStage(
                    //   val?.id,
                    //   val?.customerId,
                    //   val?.current_stage
                    // );
                  }}
                >
                  <Row className="mt-2 p-4">
                    <Col className="font-blue">
                      <p className="app-p">
                        <p className="mb-0" style={{ color: "gray" }}>
                          Active Application
                        </p>
                        <p
                          className="mb-2"
                          style={{ fontSize: 16, color: "#4b99ff" }}
                        >
                          {applications[0]?.application_id}
                        </p>
                      </p>
                    </Col>
                    <Col className="font-blue">
                      <p className="app-p text-end">
                        {/* {mapStage(applications[0]?.stage)} */}
                        <p
                          className="mb-0"
                          style={{ color: "gray", fontSize: 10 }}
                        >
                          Created on:-
                        </p>
                        {applications[0]?.createdAt
                          ? moment(applications[0]?.createdAt).format(
                              "DD/MM/YYYY"
                            )
                          : "---"}
                      </p>
                    </Col>
                  </Row>
                  <div className="bottom-blue">
                    <span style={{ color: "white" }}>
                      State: {mapStage(applications[0]?.stage)}
                    </span>
                    <span style={{ float: "right" }}>
                      <Button
                        className="apply-now-btn"
                        style={{ height: 30, cursor: "pointer !important" }}
                        variant="outlined"
                        onClick={() => {
                          getStage(
                            applications[0]?.stage,
                            applications[0]?.application_id,
                            applications[0]?.coCustomerData ? true : false
                          );
                        }}
                      >
                        Continue
                      </Button>
                    </span>
                  </div>
                </Container>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </>
  );
};

export default Home;
