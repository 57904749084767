import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { getCustomerData, getLoanData } from "../api";
import { useContext } from "react";
import { CustomerContext } from "../Contexts/CustomerContext";
import { LoanContext } from "../Contexts/LoanJourneyContext";
import { fetchApplicationData } from "../api/agent";
import { useEffect } from "react";

export default function useApplication() {
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const navigate = useNavigate();

  // const authloan = () => {
  //   if(vehicleDetails?.id !== "") return true;
  //   else return false;
  // }

  const getValue = (value, defaultValue) => {
    if (!value || value === null || value === undefined || value === "") {
      return defaultValue;
    } else {
      return value;
    }
  };

  const getApplicationDetails = async (application_id, load = false) => {
    if (journeyData?.loaded && !load) {
      console.log("Already loaded");
      return;
    }
    console.log("loading");
    try {
      let { data: response } = await fetchApplicationData(application_id);

      // console.log(response);
      const { data, current_stage, application_stage_progress } = response;
      const {
        customerData,
        coCustomerData,
        anchorData,
        businessData,
        nbfcData,
      } = data;

      setJourneyData((st) => ({
        ...st,
        application_id,
        loaded: true,
        isPanOcr: true,
        pan_number: customerData?.pan_details?.number,
        pan_image: "",
        aadhaar_front: "",
        aadhaar_back: "",

        business_name: businessData?.name || "",
        gst_number: businessData?.gst_details?.gstin || "",
        business_start_date: businessData?.start_date || "",
        registered_address: businessData?.registered_address?.address || "",
        registered_pincode: businessData?.registered_address?.pincode || "",

        is_registered_owned: businessData?.registered_address?.is_property_owned
          ? businessData?.registered_address?.is_property_owned
          : null,

        is_operational_address_same: businessData?.is_address_same || null,

        business_address:
          businessData === null || businessData?.addresses?.length === 0
            ? [
                {
                  operational_address: "",
                  operational_pincode: "",
                  is_operational_property_owned: null,
                },
              ]
            : businessData?.addresses,

        full_name: customerData?.pan_details?.name,
        date_of_birth: customerData?.dob ? customerData?.dob : "",
        aadhaar_address: customerData?.aadhaar_details?.address,
        aadhaar_pincode: customerData?.aadhaar_details?.address_information
          ?.pincode
          ? customerData?.aadhaar_details?.address_information?.pincode
          : "",
        is_address_same: customerData?.is_address_same,
        current_address: customerData?.residential_address?.address
          ? customerData?.residential_address?.address
          : "",
        current_pincode: customerData?.residential_address?.pincode
          ? customerData?.residential_address?.pincode
          : "",
        is_property_owned: customerData?.residential_address?.property_owned
          ? customerData?.residential_address?.property_owned
          : null,

        is_aadhaar_property_owned: customerData?.aadhaar_details
          ?.is_aadhaar_address_owned
          ? customerData?.aadhaar_details?.is_aadhaar_address_owned
          : null,

        mobile_number: customerData?.mobile_number
          ? customerData?.mobile_number
          : "",
        email_id: customerData?.email_id ? customerData?.email_id : "",
        credit_pull_allow: data?.credit_pull?.consent,

        customer_pan_uploaded: customerData?.is_pan_uploaded,

        gender: customerData?.gender ? customerData?.gender : "",

        is_psychometric_done: data?.other_details?.psychometric_data
          ? true
          : false,

        co_customer_exist: coCustomerData ? true : false,
        co_customer_type: coCustomerData?.customer_type
          ? coCustomerData?.customer_type
          : "CO-APPLICANT",
        co_customer_id: coCustomerData?.id ? coCustomerData?.id : "",
        co_customer_full_name: coCustomerData?.pan_details?.name
          ? coCustomerData?.pan_details?.name
          : "",
        co_customer_pan_number: coCustomerData?.pan_details?.number
          ? coCustomerData?.pan_details?.number
          : "",
        co_customer_date_of_birth: coCustomerData?.dob
          ? coCustomerData?.dob
          : "",
        co_customer_aadhaar_address: coCustomerData?.aadhaar_details?.address
          ? coCustomerData?.aadhaar_details?.address
          : "",
        co_customer_aadhaar_pincode: coCustomerData?.aadhaar_details
          ?.address_information?.pincode
          ? coCustomerData?.aadhaar_details?.address_information?.pincode
          : "",
        co_customer_is_address_same: customerData?.is_address_same,
        co_customer_current_address: coCustomerData?.residential_address
          ?.address
          ? coCustomerData?.residential_address?.address
          : "",
        co_customer_current_pincode: coCustomerData?.residential_address
          ?.pincode
          ? coCustomerData?.residential_address?.pincode
          : "",
        co_customer_is_property_owned: coCustomerData?.residential_address
          ?.property_owned
          ? coCustomerData?.residential_address?.property_owned
          : null,
        co_customer_is_aadhaar_property_owned: coCustomerData?.aadhaar_address
          ?.is_aadhaar_address_owned
          ? coCustomerData?.aadhaar_address?.is_aadhaar_address_owned
          : null,
        co_customer_mobile_number: coCustomerData?.mobile_number
          ? coCustomerData?.mobile_number
          : "",
        co_customer_email_id: coCustomerData?.email
          ? coCustomerData?.email
          : "",

        co_customer_gender: coCustomerData?.gender
          ? coCustomerData?.gender
          : "",

        credit_limit: data?.credit_limit,
        credit_limit_increased:
          data?.other_details?.proceeded_with_increase_credit_limit,

        bank_details_id: data?.bank_details_id || null,
        bank_details: data?.bank_details,

        bank_id: "",
        bank_name: "",
        bank_code: "",
        account_type: "",
        credit_over_limit: "",
        is_password: null,
        pdf_password: "",

        beneficiary_name: "",
        account_number: "",
        ifsc_code: "",

        loan_amount: data?.loan_amount,
        interest_rate: data?.annual_interest_rate,
        tenure_value: data?.tenure_value,
        tenure_type: data?.tenure_type,
        tranch_tenure_type: data?.tranch_tenure_type,
        tranch_tenure_value: data?.tranch_tenure_value,

        disbursal_amount: data?.disbursal_amount,
        mode_of_repayment: data?.mode_of_repayment,
        interest_amount: data?.mode_of_repayment,
        processing_fee: data?.processing_charge,
        processing_rate: data?.processing_charge_rate,

        // MITC

        nbfc_data: nbfcData,
        start_date: data?.application_start_date,

        //

        penny_drop_status: data?.bank_details?.penny_drop_status || null,

        // Enach
        aadhaar_esign_status: data?.aadhaar_esign_status,
        esign_count: data?.other_details?.aadhaar_esign_run_count,

        enach_status: data?.enach_status,
        upi_status: data?.upi_autopay_status,
        upi_link_sent: data?.upi_autopay_data?.link_sent,

        is_disbursed: data?.is_disbursed,
        stage: current_stage,
        percentages: {
          ...application_stage_progress,
        },

        // Field investigation

        anchor_name: anchorData?.name,

        total_members: getValue(
          customerData?.other_details?.family?.family_members,
          ""
        ),
        earning_members: getValue(
          customerData?.other_details?.family?.earning_members,
          ""
        ),
        family_income: getValue(
          customerData?.other_details?.family?.amount_earned,
          ""
        ),
        engine_number: getValue(data?.other_details?.vehicle_engine_no, ""),
        chasis_no: getValue(data?.other_details?.vehicle_chasis_no, ""),
        customer_photo_with_vehicle: "",
        customer_house_inside: "",
        customer_house_outside: "",
        customer_photo_with_electricity_meter: "",
        premise_accessibility: getValue(
          data?.other_details?.accessbility_till_premise,
          ""
        ),
        fi_remark: getValue(data?.other_details?.remarks, ""),
        investigator_name: getValue(data?.field_investigation_agent?.name, ""),
        investigator_mobile: getValue(
          data?.field_investigation_agent?.mobile_number,
          ""
        ),

        application_remark: data?.embifi_approval_details?.remark,
      }));

      // console.log(window.location.pathname);
      // console.log(current_stage);
      getStage(current_stage, application_id, coCustomerData ? true : false);
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const getStage = (stage, application_id, isCocustomer = false) => {
    switch (stage) {
      case "CUSTOMER_BASIC_DETAILS":
        navigate(`/onboard/pan-details/${application_id}`);
        break;
      case "CUSTOMER_BUSINESS_DETAILS":
        navigate(`/onboard/business-details/${application_id}`);
        break;
      case "CUSTOMER_EMBIFI_REJECTED":
        navigate(`/onboard/status/${application_id}`);
        break;
      case "CUSTOMER_CREDIT_ENGINE_FAILED":
        navigate(`/onboard/status/${application_id}`);
        break;
      case "CUSTOMER_UNDER_REVIEW":
        navigate(`/onboard/status/${application_id}`);
        break;
      case "CO_CUSTOMER_CREATION":
        navigate(`/onboard/status/${application_id}`);
        break;
      case "CO_CUSTOMER_RECREATION":
        navigate(`/onboard/co-customer/${application_id}`);
        break;
      case "CO_CUSTOMER_BASIC_DETAILS":
        navigate(`/onboard/co-customer-details/${application_id}`);
        break;
      case "CO_CUSTOMER_EMBIFI_REJECTED":
        navigate(`/onboard/status-cocustomer/${application_id}`);
        break;
      case "CO_CUSTOMER_CREDIT_ENGINE_FAILED":
        navigate(`/onboard/status-cocustomer/${application_id}`);
        break;
      case "CO_CUSTOMER_UNDER_REVIEW":
        navigate(`/onboard/status-cocustomer/${application_id}`);
        break;
      case "CREATE_BANK":
        navigate(`/onboard/status/${application_id}`);
        break;
      case "BANK_ACCOUNTS":
        navigate(`/onboard/bank-accounts/${application_id}`);
        break;
      case "APPLICATION_UNDER_REVIEW":
        navigate(`/onboard/loan-status/${application_id}`);
        break;

      case "LOAN_OFFER":
        navigate(`/onboard/loan-summary/${application_id}`);
        break;
      case "AADHAAR_E_SIGN":
        navigate(`/onboard/mitc/${application_id}`);
        break;
      case "AADHAAR_E_SIGN_STATUS":
        navigate(`/onboard/esign-status/${application_id}`);
        break;

      // case "LOAN_UPDATE":
      //   if (isCocustomer) {
      //     navigate(`/onboard/status-cocustomer/${application_id}`);
      //   } else {
      //     navigate(`/onboard/status/${application_id}`);
      //   }
      //   break;
      case "APPLICATION_STAGE_PROGRESSION": {
        if (window.location.pathname.includes("/onboard/progress/")) {
          return;
        }
        navigate(`/onboard/progress/${application_id}`);
        break;
      }
      case "PENNY_DROP_FAILED": {
        navigate(`/onboard/loan-status/${application_id}`);
        break;
      }
      case "PAYMENT_METHOD": {
        if (window.location.pathname.includes("/onboard/payment-method/")) {
          return;
        }
        navigate(`/onboard/payment-method/${application_id}`);
        break;
      }
      case "WAITING_FOR_DISBURSAL":
        navigate(`/onboard/loan-status/${application_id}`);
        break;
      case "DISBURSED":
        navigate(`/onboard/loan-status/${application_id}`);
        break;
    }
  };

  const getRealStatus = (enach_status, key) => {
    if (enach_status === null || enach_status === undefined) {
      if (key === "upi" && journeyData?.upi_link_sent) {
        return "Link Sent";
      }
      return "pending";
    } else {
      if (enach_status) {
        return "successfull";
      } else {
        return "unsuccessfull";
      }
    }
  };

  return {
    getApplicationDetails,
    getStage,
    getRealStatus,
  };
}
