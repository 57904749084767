import "../App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import UserContextProvider, { UserContext } from "../Contexts/UserContext";
import GlobalContextProvider, {
  GlobalContext,
} from "../Contexts/GlobalContext";
import PrivateRoute from "../pages/PrivateRoute";
import Register from "../pages/Register";
import Landing from "../pages/Landing";
import Home from "../pages/HomeLayout";
import NotFound from "../pages/NotFound";
import KycLayout from "../pages/kycSteps/KycLayout";
import KycContextProvider from "../Contexts/KycContext";
import OtpScreen from "../pages/OtpScreen";
import ResetPassword from "../pages/ResetPassword";
import RegisterName from "../pages/RegisterName";
import PublicRoute from "../pages/PublicRoute";
import { Fragment, useContext, useEffect } from "react";
import PermissionModal from "../components/PermissionModal";
import usePermission from "../hooks/usePermission";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { withSnackbar, useSnackbar } from "notistack";
import { Button } from "@mui/material";
import { useState } from "react";
import CustomerContextProvider from "../Contexts/CustomerContext";
import RecentApplication from "../pages/Applications/RecentApplications";
import ViewApplications from "../pages/ViewApplication/ViewLayout";
import Profile from "../pages/HomeScreens/Profile";
import CustomerProfile from "../pages/ViewApplication/Profile";
import LoanDetails from "../pages/ViewApplication/LoanDetails";
// import AllApplications from "../pages/Applications/AllApplications";
import Collections from "../pages/ViewApplication/Collections";
import AllCollections from "../pages/Collections/Collections";
import Test from "../pages/Test";
import PageUnder from "../pages/PageUnder";
import LoanJourney from "./v1/LoanRoutes";
import LoanContextProvider from "../Contexts/LoanJourneyContext";
import DraftApplication from "../pages/Applications/v1/DraftApplications";
import AllApplications from "../pages/Applications/v1/AllApplications";
import RewardSummary from "../pages/Rewards/RewardSummary";
import Login from "../pages/Login";

export default function CustomerFlow() {
  return (
    <Router>
      <UserContextProvider>
        <KycContextProvider>
          <CustomerContextProvider>
            <LoanContextProvider>
              <Routes>
                <Route element={<PublicRoute />}>
                  <Route exact path="" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="otp/:vId" element={<OtpScreen />} />
                  {/* <Route path="reset-password" element={<ResetPassword />} /> */}
                </Route>

                <Route element={<PrivateRoute />}>
                  <Route path="complete-signup" element={<RegisterName />} />
                  <Route path="home" element={<Home />} />
                  <Route path="home/:tab" element={<Home />} />
                  <Route path="kyc" element={<KycLayout />} />
                  <Route path="kyc/:stage" element={<KycLayout />} />
                  <Route path="sent-otp/:vId" element={<OtpScreen />} />
                  <Route path="onboard/*" element={<LoanJourney />} />

                  <Route
                    path="draft-applications"
                    element={<DraftApplication />}
                  />

                  <Route
                    path="all-applications"
                    element={<AllApplications />}
                  />
                </Route>

                <Route path="rewards" element={<RewardSummary />} />
                <Route path="rewards/:application_id" element={<RewardSummary />} />

                <Route path="test" element={<Login />} />
                <Route path="/under-construction" element={<PageUnder />} />
                {/* <Route element={<NotFound />} /> */}
              </Routes>
            </LoanContextProvider>
          </CustomerContextProvider>
        </KycContextProvider>
      </UserContextProvider>
    </Router>
  );
}
