import {
  BottomNavigation,
  Button,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ageValidate, validateDob } from "../../../validations/dateValidations";
import { createCoCustomer, createCustomer } from "../../../api";
import MenuAppBar from "../../../components/Appbar";
import TimeInput from "../../../components/DateInput";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import { updateAddress, verifyPincode } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import {
  isBlank,
  isEmailValid,
  isMobValid,
  isStartSpace,
} from "../../../validations/fieldValidations";
import PsychometricTest from "./PsychometricTest";
import RewardFloating from "../../../components/RewardFloating";
import RewardWallet from "../../../components/V2/Reward/RewardWallet";
// import TimeInput from "../../Components/DateInput";

const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const PanDetails = forwardRef((props, ref) => {
  const { bottomNav, otpState } = useContext(GlobalContext);
  const [otpData, setOtpData] = otpState;

  const refs = {
    dob: useRef(null),
    aadhaar_address: useRef(null),
    aadhaar_pincode: useRef(null),
    current_address: useRef(null),
    current_pincode: useRef(null),
    is_property_owned: useRef(null),
    is_aadhaar_property_owned: useRef(null),
    mobile_number: useRef(null),
    gender: useRef(null),
    email: useRef(null),
  };

  const [isPsycho, setPsycho] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fieldErrors, setErrors] = useState({ dob: "" });

  const { application_id } = useParams();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [showReward, setShowReward] = useState(false);
  const [showRewardInfo, setShowRewardInfo] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleInput = (e, setState) => {
    let result = validateDob(e.target.value);
    setState((st) => ({ ...st, date_of_birth: result }));
  };

  const validDate = (date, key) => {
    if (date.length === 0 || date?.includes("_")) {
      return "Provide a valid date";
    } else {
      // if (key === "dob") {
      //   if (date.length > 0 && !date?.includes("_")) {
      //     return ageValidate(journeyData.date_of_birth);
      //   }
      // }

      return "";
    }
  };

  const isValid = async () => {
    let valid = true;
    let errors = {
      dob: validDate(journeyData.date_of_birth, "dob"),
      aadhaar_address: isBlank(journeyData?.aadhaar_address),
      aadhaar_pincode: await isPinValid(journeyData?.aadhaar_pincode),
      current_address:
        ![null, undefined, ""].includes(journeyData?.is_address_same) &&
        journeyData?.is_address_same
          ? ""
          : isBlank(journeyData?.current_address),
      current_pincode:
        ![null, undefined, ""].includes(journeyData?.is_address_same) &&
        journeyData?.is_address_same
          ? ""
          : await isPinValid(journeyData?.current_pincode),
      is_property_owned: [null, undefined, ""].includes(
        journeyData?.is_property_owned
      )
        ? "Select any option"
        : "",

      is_property_owned:
        ![null, undefined, ""].includes(journeyData?.is_address_same) &&
        journeyData?.is_address_same
          ? ""
          : [null, undefined, ""].includes(journeyData?.is_property_owned)
          ? "Select any option"
          : "",
      is_aadhaar_property_owned: [null, undefined, ""].includes(
        journeyData?.is_aadhaar_property_owned
      )
        ? "Select any option"
        : "",
      mobile_number: isMobValid(journeyData?.mobile_number),
      gender: [null, undefined, ""].includes(journeyData?.gender)
        ? "Select gender"
        : "",
      email: isEmailValid(journeyData?.email_id),
    };

    console.log(errors);

    setErrors((prev) => ({ ...prev, ...errors }));

    // if (state.full_name === "" || state.pan_number === "") valid = false;
    let first = false;
    for (let i in errors) {
      if (errors[i].length > 0) {
        if (!first) {
          first = true;
          refs[i]?.current?.focus();
          window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
        }
        valid = false;
      }
    }

    return valid;
  };

  const isPinValid = async (pincode, key) => {
    try {
      let { data } = await verifyPincode(pincode);
      if (Object.keys(data).length !== 0) {
        return "";
      } else {
        return "Invalid pincode";
      }
    } catch (error) {
      return "Pincode validation failed";
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (await isValid()) {
      if (journeyData?.credit_pull_allow) {
        let payload = {
          dob: journeyData?.date_of_birth,
          aadhaar_pincode: journeyData?.aadhaar_pincode + "",
          aadhaar_address: journeyData?.aadhaar_address,
          same_address_as_aadhaar: journeyData?.is_address_same,
          is_property_owned: journeyData?.is_address_same
            ? journeyData?.is_aadhaar_property_owned
            : journeyData?.is_property_owned,
          is_aadhaar_address_owned: journeyData?.is_aadhaar_property_owned,
          mobile_number: journeyData?.mobile_number + "",
          email: journeyData?.email_id,
          credit_consent: journeyData?.credit_pull_allow,
          residential_address: journeyData?.current_address + "",
          residential_pincode: journeyData?.current_pincode + "",
          gender: journeyData?.gender,
        };


        
        try {
          let { data } = await updateAddress(payload, application_id);
          if (data?.status) {
            setOtpData((st) => ({
              ...st,
              mobile: journeyData?.mobile_number,
              path: "onboard/status",
              method: "create-customer",
              vId: data?.verificationId,
              application_id,
            }));
            navigate(`/sent-otp/${data?.verificationId}`);
          }
        } catch (err) {
          let error = "";
          if (err?.response?.data?.message) {
            if (err?.response?.data?.message === "Bad request parameters") {
              error = "Please re-check all the inputs";
            } else {
              error = err?.response?.data?.message;
            }
          } else {
            error = "Something went wrong";
          }
          enqueueSnackbar(error, { variant: "error" });
        }
      } else {
        enqueueSnackbar("Please accept the credit pull consent");
      }
    }
    setLoading(false);
  };

  const handleBack = async () => {
    navigate("/home");
  };

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  // useEffect(() => {
  //   console.log(isPsycho);
  // }, [isPsycho]);

  return !isPsycho ? (
    // return true ? (
    <>
      <Box
        className="kyc-wrapper"
        sx={{ pb: 7, pt: 7, position: "relative" }}
        ref={ref}
      >
        <RewardFloating
          isOpen={showReward}
          showInfo={showRewardInfo}
          action={() => setShowRewardInfo(false)}
          onClick={() => {
            setPsycho(true);
            setShowReward(false);
          }}
        />

        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-label">Full Name ( As per PAN)</label>
              <input
                className="form-input w-100"
                autoComplete="given-name"
                name="firstName"
                fullWidth
                disabled
                placeholder="Enter your first name"
                autoFocus
                value={journeyData.full_name}
              />
              <span className="error"></span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">PAN Number</label>
              <input
                className="form-input w-100"
                required
                fullWidth
                disabled
                placeholder="Enter your PAN number"
                autoComplete="family-name"
                value={journeyData.pan_number}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                Date of Birth <span className="mand-star">*</span>
              </label>

              <TimeInput
                ref={refs.dob}
                value={journeyData.date_of_birth}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, dob: "" });
                  handleInput(e, setJourneyData);
                }}
                onBlur={() => {
                  if (journeyData.date_of_birth === "__/__/____")
                    setJourneyData((st) => ({ ...st, date_of_birth: "" }));
                  setErrors((prev) => ({
                    ...prev,
                    date_of_birth: validDate(journeyData.date_of_birth, "dob"),
                  }));
                }}
              ></TimeInput>

              {/* <input
                    className="form-input w-100"
                    required
                    id="dob"
                    disabled
                    placeholder=""
                    name="dob"
                    autoComplete="family-name"
                    // value={pPanDetails.dob}
                  /> */}

              <span className="error">{fieldErrors.dob}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                Gender <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.gender}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={journeyData.gender}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, gender: "" });
                  setJourneyData({
                    ...journeyData,
                    gender: e.target.value,
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"Male"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>Male</span>}
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"Female"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>Female</span>}
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.gender}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                Aadhar Address <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.aadhaar_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder="Aadhaar Address"
                autoComplete="family-name"
                value={journeyData.aadhaar_address}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, aadhaar_address: "" });
                  setJourneyData({
                    ...journeyData,
                    aadhaar_address: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.aadhaar_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                Aadhaar Pincode <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.aadhaar_pincode}
                className="form-input w-100"
                required
                fullWidth
                placeholder="Aadhaar Pincode"
                autoComplete="family-name"
                value={journeyData.aadhaar_pincode}
                onChange={(e) => {
                  // if (
                  //   e.target.value !== "" &&
                  //   !/^\d+$/.test(e.target.value)
                  // ) {
                  //   return false;
                  // }
                  // if (e.target.value.length > 6) return false;
                  setErrors({ ...fieldErrors, aadhaar_pincode: "" });
                  setJourneyData({
                    ...journeyData,
                    aadhaar_pincode: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.aadhaar_pincode}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                Is this aadhaar property owned by customer?{" "}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_aadhaar_property_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={
                  journeyData.is_aadhaar_property_owned !== null
                    ? journeyData.is_aadhaar_property_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_aadhaar_property_owned:
                      e.target?.value === "yes" ? true : false,
                  });
                  setErrors({
                    ...fieldErrors,
                    is_aadhaar_property_owned: "",
                  });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>Yes</span>}
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>No</span>}
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">
                {fieldErrors.is_aadhaar_property_owned}
              </span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox defaultChecked={journeyData?.is_address_same} />
                }
                label="Same as Aadhaar"
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_address_same: e.target.checked,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                Current Residential Address <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.current_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder="Current Residential Address"
                autoComplete="family-name"
                disabled={journeyData?.is_address_same}
                value={
                  journeyData?.is_address_same
                    ? journeyData?.aadhaar_address
                    : journeyData?.current_address
                }
                onChange={(e) => {
                  setErrors({ ...fieldErrors, current_address: "" });
                  setJourneyData({
                    ...journeyData,
                    current_address: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.current_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                Current Residential Pincode <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.current_pincode}
                className="form-input w-100"
                required
                fullWidth
                disabled={journeyData?.is_address_same}
                placeholder="Current Residential Pincode"
                autoComplete="family-name"
                value={
                  journeyData?.is_address_same
                    ? journeyData?.aadhaar_pincode
                    : journeyData?.current_pincode
                }
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 6) return false;
                  setErrors({ ...fieldErrors, current_pincode: "" });
                  setJourneyData({
                    ...journeyData,
                    current_pincode: e.target.value,
                  });
                }}
              />
              <span className="error">{fieldErrors.current_pincode}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                Is this property owned by customer?{" "}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_property_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                disabled={journeyData?.is_address_same}
                value={
                  journeyData?.is_address_same
                    ? journeyData.is_aadhaar_property_owned !== null
                      ? journeyData.is_aadhaar_property_owned
                        ? "yes"
                        : "no"
                      : null
                    : journeyData.is_property_owned !== null
                    ? journeyData.is_property_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_property_owned: e.target?.value === "yes" ? true : false,
                  });
                  setErrors({ ...fieldErrors, is_property_owned: "" });
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>Yes</span>}
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>No</span>}
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.is_property_owned}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                Mobile Number <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.mobile_number}
                type="text"
                className="form-input w-100"
                required
                fullWidth
                placeholder="Your mobile number"
                name="mobile-number"
                autoComplete="family-name"
                maxLength={10}
                disabled
                value={journeyData.mobile_number}
                onChange={(e) => {
                  if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                    return false;
                  }
                  if (e.target.value.length > 10) return false;
                  setErrors({ ...fieldErrors, mobile_number: "" });
                  setJourneyData((st) => ({
                    ...st,
                    mobile_number: e.target.value,
                  }));
                }}
                onKeyUp={
                  (e) => {}
                  // setFieldErrors((st) => ({
                  //   ...st,
                  //   mobile: isMobValid(e.target.value),
                  // }))
                }
              />
              <span className="error">{fieldErrors.mobile_number}</span>
            </Grid>

            <Grid item xs={6} sm={6}>
              <label className="form-label">
                Email ID <span className="mand-star">*</span>
              </label>
              <input
                ref={refs?.email}
                type="text"
                className="form-input w-100 px-3"
                required
                placeholder="Your Email Address"
                style={{ fontSize: "11.5px" }}
                name="email"
                autoComplete="family-name"
                value={journeyData.email_id}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, email: "" });
                  setJourneyData((st) => ({
                    ...st,
                    email_id: e.target.value,
                  }));
                }}
                onKeyUp={
                  (e) => {}
                  // setFieldErrors((st) => ({
                  //   ...st,
                  //   email: isEmailValid(e.target.value),
                  // }))
                }
              />
              <span className="error">{fieldErrors.email}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                className="mb-2"
                control={<Checkbox color="success" />}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    credit_pull_allow: e.target.checked,
                  });
                  // if (!showReward) {
                  //   setShowReward(true);
                  //   setShowRewardInfo(true);
                  // }
                }}
                checked={journeyData?.credit_pull_allow}
                label={
                  <span className="checkbox-txt" style={{ fontSize: 12 }}>
                    I hereby authorize <b style={{ color: "black" }}>Embifi</b>{" "}
                    to pull my Credit Report.
                  </span>
                }
              />
            </Grid>
          </Grid>
        </div>

        {/* {!journeyData?.is_psychometric_done && showReward && (
          <div style={{ position: 'absolute', background: 'rgba(0,0,0,0.5)',width: "100%", height: '100%' }}>
            <RewardFloating
              isOpen={journeyData?.credit_pull_allow}
              // action={handlePsycho}
              action={() => setShowReward(false)}
              // action={() => alert('false')}
              onClick={() => {
                setPsycho(true);
                setShowReward(false);
              }}
            />
          </div>
        )} */}

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Check Eligibility"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  ) : (
    <PsychometricTest
      onClick={() => {
        setShowReward(true);
        setPsycho(false);
      }}
    />
  );
});

export default PanDetails;
