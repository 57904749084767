export const FILE_URL = "https://api.eriksha.embifi.in/v1/common/view?";
export const BanksList = [];

export const IncredoBankList = [
  {
    code: "ABBL",
    name: "AB Bank Ltd.",
    ifsc: "ABBL",
  },
  {
    code: "ABHY",
    name: "Abhyudaya Co-operative Bank Ltd",
    ifsc: "ABHY",
  },
  {
    code: "ADCB",
    name: "Abu Dhabi Commercial Bank Ltd.",
    ifsc: "ADCB",
  },
  {
    code: "ACC",
    name: "Access Bank",
    ifsc: "",
  },
  {
    code: "ACBL",
    name: "Adarsh Co-operative Bank Ltd.",
    ifsc: "ACBX",
  },
  {
    code: "APB",
    name: "Airtel Payments Bank",
    ifsc: "AIRP",
  },
  {
    code: "ALLA",
    name: "Allahabad Bank",
    ifsc: "ALLA",
  },
  {
    code: "AUGB",
    name: "Allahabad UP Gramin Bank",
    ifsc: "AUGX",
  },
  {
    code: "AJHC",
    name: "Ambarnath Jai Hind Co Op Bank Ltd.",
    ifsc: "AJHC",
  },
  {
    code: "AEBG",
    name: "American Express Banking Corp.",
    ifsc: "",
  },
  {
    code: "ANDH",
    name: "Andhra Bank",
    ifsc: "ABEX",
  },
  {
    code: "APGVB",
    name: "Andhra Pradesh Grameena Vikas Bank",
    ifsc: "APGV",
  },
  {
    code: "APGB",
    name: "Andhra Pragathi Grameena Bank",
    ifsc: "APGB",
  },
  {
    code: "ASBL",
    name: "Apna Sahakari Bank Ltd.",
    ifsc: "ASBL",
  },
  {
    code: "APRB",
    name: "Arunachal Pradesh Rural Bank",
    ifsc: "APRX",
  },
  {
    code: "ASGVB",
    name: "Assam Gramin Vikash Bank",
    ifsc: "AGVX",
  },
  {
    code: "ASFB",
    name: "Au Small Finance Bank Ltd.",
    ifsc: "AUBL",
  },
  {
    code: "ANBG",
    name: "Australia and New Zealand Banking Group Ltd.",
    ifsc: "ANZB",
  },
  {
    code: "AXIS",
    name: "Axis Bank Ltd.",
    ifsc: "UTIB",
  },
  {
    code: "BAAC",
    name: "Bank for Agriculture and Agricultural Cooperatives",
    ifsc: "",
  },
  {
    code: "BCP",
    name: "Banco Comercial Português",
    ifsc: "",
  },
  {
    code: "BBL",
    name: "Bandhan Bank Ltd.",
    ifsc: "BDBL",
  },
  {
    code: "BGVB",
    name: "Bangiya Gramin Vikas Bank",
    ifsc: "",
  },
  {
    code: "BANB",
    name: "Bangkok Bank",
    ifsc: "",
  },
  {
    code: "BOA",
    name: "Bank of America",
    ifsc: "BOFA",
  },
  {
    code: "BAY",
    name: "Bank of Ayudhya",
    ifsc: "",
  },
  {
    code: "BBK",
    name: "Bank of Bahrain & Kuwait BSC",
    ifsc: "",
  },
  {
    code: "BOB",
    name: "Bank of Baroda",
    ifsc: "BARB",
  },
  {
    code: "BOC",
    name: "Bank of Ceylon",
    ifsc: "BCEY",
  },
  {
    code: "BOI",
    name: "Bank of India",
    ifsc: "BKID",
  },
  {
    code: "BOM",
    name: "Bank of Maharashtra",
    ifsc: "MAHB",
  },
  {
    code: "BONS",
    name: "Bank of Nova Scotia",
    ifsc: "NOSC",
  },
  {
    code: "BARA",
    name: "The Baramati Sahakari Bank Ltd.",
    ifsc: "BARA",
  },
  {
    code: "BBP",
    name: "Barclays Bank Plc.",
    ifsc: "BARC",
  },
  {
    code: "BGGB",
    name: "Baroda Gujarat Gramin Bank",
    ifsc: "BGGX",
  },
  {
    code: "BRKGB",
    name: "Baroda Rajasthan Kshetriya Gramin Bank",
    ifsc: "BRGX",
  },
  {
    code: "BUGB",
    name: "Baroda UP Gramin Bank",
    ifsc: "",
  },
  {
    code: "BCCB",
    name: "Bassein Catholic Co-operative Bank Ltd.",
    ifsc: "BACB",
  },
  {
    code: "BNSB",
    name: "Bhagini Nivedita Sahakari Bank Ltd.",
    ifsc: "BNSX",
  },
  {
    code: "BLMS",
    name: "The Bhagyalakshmi Mahila Sahakari Bank Ltd.",
    ifsc: "BMSX",
  },
  {
    code: "BCBM",
    name: "Bharat Co-operative Bank (Mumbai) Ltd.",
    ifsc: "BCBM",
  },
  {
    code: "BSBL",
    name: "Bhausaheb Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "BDCB",
    name: "The Bhavnagar District Co-operative Bank Ltd",
    ifsc: "BVNX",
  },
  {
    code: "BGB",
    name: "Bihar Gramin Bank",
    ifsc: "BGBX",
  },
  {
    code: "BNP",
    name: "BNP Paribas",
    ifsc: "BNPA",
  },
  {
    code: "CAN",
    name: "Canara Bank",
    ifsc: "CNRB",
  },
  {
    code: "CSFB",
    name: "Capital Small Finance Bank Ltd",
    ifsc: "CLBL",
  },
  {
    code: "CSB",
    name: "Catholic Syrian Bank Ltd.",
    ifsc: "CSBK",
  },
  {
    code: "CBI",
    name: "Central Bank of India",
    ifsc: "CBIN",
  },
  {
    code: "CMPGB",
    name: "Central Madhya Pradesh Gramin Bank",
    ifsc: "CMPX",
  },
  {
    code: "CGGB",
    name: "Chaitanya Godavari Grameena Bank",
    ifsc: "CGGX",
  },
  {
    code: "CRGB",
    name: "Chattisgarh Rajya Gramin Bank",
    ifsc: "",
  },
  {
    code: "CITI",
    name: "Citibank N.A.",
    ifsc: "",
  },
  {
    code: "CUBL",
    name: "City Union Bank Ltd.",
    ifsc: "CIUB",
  },
  {
    code: "CLAB",
    name: "Coastal Local Area Bank Ltd",
    ifsc: "COAS",
  },
  {
    code: "CRUA",
    name: "Cooperatieve Rabobank U.A.",
    ifsc: "",
  },
  {
    code: "CORP",
    name: "Corporation Bank",
    ifsc: "CORP",
  },
  {
    code: "COSB",
    name: "Cosmos Co-operative Bank Ltd",
    ifsc: "COSB",
  },
  {
    code: "CACIB",
    name: "Credit Agricole Corporate & Investment Bank",
    ifsc: "",
  },
  {
    code: "CSAG",
    name: "Credit Suisse A.G",
    ifsc: "",
  },
  {
    code: "CTBC",
    name: "CTBC Bank Co.",
    ifsc: "CTCB",
  },
  {
    code: "DBS",
    name: "DBS Bank Ltd.",
    ifsc: "",
  },
  {
    code: "DCB",
    name: "DCB Bank Ltd.",
    ifsc: "DCBL",
  },
  {
    code: "DNSB",
    name: "Deendayal Nagari Sahakari Bank Ltd.",
    ifsc: "DNSX",
  },
  {
    code: "DENA",
    name: "Dena Bank",
    ifsc: "BKDN",
  },
  {
    code: "DGGB",
    name: "Dena Gujarat Gramin Bank",
    ifsc: "DEGX",
  },
  {
    code: "DEOB",
    name: "Deogiri Nagari Sahakari Bank",
    ifsc: "DEOB",
  },
  {
    code: "DEUT",
    name: "Deutsche Bank",
    ifsc: "DEUT",
  },
  {
    code: "DHAN",
    name: "Dhanlaxmi Bank Ltd.",
    ifsc: "DLXB",
  },
  {
    code: "DMKJ",
    name: "DMK Jaoli Bank Ltd.",
    ifsc: "DMKJ",
  },
  {
    code: "DOHA",
    name: "Doha Bank",
    ifsc: "DOHB",
  },
  {
    code: "DNS",
    name: "Dombivli Nagari Sahakari Bank Ltd.",
    ifsc: "DNSB",
  },
  {
    code: "EDB",
    name: "Ellaquai Dehati Bank",
    ifsc: "EDBX",
  },
  {
    code: "ENBD",
    name: "Emirates NBD Bank PJSC",
    ifsc: "EBIL",
  },
  {
    code: "ESFB",
    name: "Equitas Small Finance Bank Ltd",
    ifsc: "ESFB",
  },
  {
    code: "ESAF",
    name: "ESAF Small Finance Bank Ltd.",
    ifsc: "ESAF",
  },
  {
    code: "FBL",
    name: "Federal Bank Ltd.",
    ifsc: "FDRL",
  },
  {
    code: "FSFB",
    name: "Fincare Small Finance Bank Ltd.",
    ifsc: "FINF",
  },
  {
    code: "FPB",
    name: "Fino Payments Bank",
    ifsc: "FINO",
  },
  {
    code: "FADB",
    name: "First Abu Dhabi Bank PJSC",
    ifsc: "",
  },
  {
    code: "FRBL",
    name: "FirstRand Bank Ltd.",
    ifsc: "FIRN",
  },
  {
    code: "GCUB",
    name: "Gayatri Co-operative Urban Bank Ltd.",
    ifsc: "GCUX",
  },
  {
    code: "GSB",
    name: "Government Savings Bank",
    ifsc: "",
  },
  {
    code: "GPPB",
    name: "GP Parsik Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "GBA",
    name: "Gramin Bank of Aryavart",
    ifsc: "ARYX",
  },
  {
    code: "GBCB",
    name: "Greater Bombay Co-operative Bank Ltd",
    ifsc: "GBCB",
  },
  {
    code: "GMUC",
    name: "Gulshan Mercantile Urban Coop. Bank Ltd",
    ifsc: "",
  },
  {
    code: "HCBL",
    name: "The Hasti Co - Op Bank Ltd.",
    ifsc: "",
  },
  {
    code: "HDFC",
    name: "HDFC Bank Ltd",
    ifsc: "HDFC",
  },
  {
    code: "HPGB",
    name: "Himachal Pradesh Gramin Bank",
    ifsc: "HMBX",
  },
  {
    code: "HSBC",
    name: "HSBC Ltd",
    ifsc: "",
  },
  {
    code: "ICICI",
    name: "ICICI Bank Ltd.",
    ifsc: "ICIC",
  },
  {
    code: "IDFC",
    name: "IDFC Bank Ltd.",
    ifsc: "",
  },
  {
    code: "INKR",
    name: "Inkredo",
    ifsc: "",
  },
  {
    code: "IPPB",
    name: "India Post Payments Bank",
    ifsc: "IPOS",
  },
  {
    code: "INDB",
    name: "Indian Bank",
    ifsc: "IDIB",
  },
  {
    code: "IOB",
    name: "Indian Overseas Bank",
    ifsc: "IOBA",
  },
  {
    code: "IIBL",
    name: "IndusInd Bank Ltd",
    ifsc: "INDB",
  },
  {
    code: "ICBC",
    name: "Industrial & Commercial Bank of China Ltd.",
    ifsc: "",
  },
  {
    code: "IBK",
    name: "Industrial Bank of Korea",
    ifsc: "IBKO",
  },
  {
    code: "JPMC",
    name: "J.P. Morgan Chase Bank N.A.",
    ifsc: "",
  },
  {
    code: "JKBL",
    name: "Jammu & Kashmir Bank Ltd.",
    ifsc: "JAKA",
  },
  {
    code: "JKGB",
    name: "Jammu & Kashmir Grameen Bank",
    ifsc: "",
  },
  {
    code: "JSFB",
    name: "Jana Small Finance Bank Ltd",
    ifsc: "JSFB",
  },
  {
    code: "JSBL",
    name: "Janaseva Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "JSBP",
    name: "Janata Sahakari Bank Ltd.",
    ifsc: "",
  },
  {
    code: "JGB",
    name: "Jharkhand Gramin Bank",
    ifsc: "",
  },
  {
    code: "JRGB",
    name: "Jharkhand Rajya Gramin Bank",
    ifsc: "VGBX",
  },
  {
    code: "JSC",
    name: "JSC VTB Bank",
    ifsc: "",
  },
  {
    code: "KAIJ",
    name: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank",
    ifsc: "KAIJ",
  },
  {
    code: "KJSB",
    name: "Kalyan Janata Sahakari Bank",
    ifsc: "KJSB",
  },
  {
    code: "KBL",
    name: "Karnataka Bank Ltd.",
    ifsc: "KARB",
  },
  {
    code: "KSCB",
    name: "The Karnataka State Co-Operative Apex Bank Ltd.",
    ifsc: "KSCB",
  },
  {
    code: "KVGB",
    name: "Karnataka Vikas Grameena Bank",
    ifsc: "KVGB",
  },
  {
    code: "KVBL",
    name: "Karur Vysya Bank Ltd.",
    ifsc: "KVBL",
  },
  {
    code: "KGSG",
    name: "Kashi Gomti Samyut Gramin Bank",
    ifsc: "KGSX",
  },
  {
    code: "KKB",
    name: "Kasikornbank",
    ifsc: "",
  },
  {
    code: "KAGB",
    name: "Kaveri Grameena Bank",
    ifsc: "KGBX",
  },
  {
    code: "KEBH",
    name: "KEB Hana Bank",
    ifsc: "KBHB",
  },
  {
    code: "KEGB",
    name: "Kerala Gramin Bank",
    ifsc: "KLGB",
  },
  {
    code: "KBSL",
    name: "Krishna Bhima Samruddhi LAB Ltd",
    ifsc: "KBSX",
  },
  {
    code: "KTBP",
    name: "Krung Thai Bank Public Co. Ltd.",
    ifsc: "",
  },
  {
    code: "LVBL",
    name: "Lakshmi Vilas Bank Ltd.",
    ifsc: "LAVB",
  },
  {
    code: "LDRB",
    name: "Langpi Dehangi Rural Bank",
    ifsc: "LDRX",
  },
  {
    code: "MTB",
    name: "M&T Bank",
    ifsc: "",
  },
  {
    code: "MBGB",
    name: "Madhya Bihar Gramin Bank",
    ifsc: "",
  },
  {
    code: "MGB",
    name: "Madhyanchal Gramin Bank",
    ifsc: "MADX",
  },
  {
    code: "MAGB",
    name: "Maharashtra Gramin Bank",
    ifsc: "MAHG",
  },
  {
    code: "MIEB",
    name: "Makarpura Ind Estate Co Op Bank Ltd",
    ifsc: "",
  },
  {
    code: "MLGB",
    name: "Malwa Gramin Bank",
    ifsc: "MGRB",
  },
  {
    code: "MARB",
    name: "Manipur Rural Bank",
    ifsc: "MRBX",
  },
  {
    code: "MBPSC",
    name: "Mashreq Bank PSC",
    ifsc: "MSHQ",
  },
  {
    code: "MERB",
    name: "Meghalaya Rural Bank",
    ifsc: "MERX",
  },
  {
    code: "MIRB",
    name: "Mizoram Rural Bank",
    ifsc: "MZRX",
  },
  {
    code: "MBL",
    name: "Mizuho Bank Ltd.",
    ifsc: "MHCB",
  },
  {
    code: "NRB",
    name: "Nagaland Rural Bank",
    ifsc: "NAGX",
  },
  {
    code: "NBL",
    name: "Nainital Bank Ltd.",
    ifsc: "NTBL",
  },
  {
    code: "NUCB",
    name: "The Nandura Urban Co-operative Bank Ltd.",
    ifsc: "TNUX",
  },
  {
    code: "NJGB",
    name: "Narmada Jhabua Gramin Bank",
    ifsc: "NJGX",
  },
  {
    code: "NAB",
    name: "National Australia Bank",
    ifsc: "NATA",
  },
  {
    code: "NICB",
    name: "New India Co-Operative Bank Ltd",
    ifsc: "NICB",
  },
  {
    code: "NIYO",
    name: "Niyo Solutions Inc.",
    ifsc: "",
  },
  {
    code: "NKGS",
    name: "NKGSB Co-operative Bank Ltd.",
    ifsc: "NKGS",
  },
  {
    code: "NESF",
    name: "North East Small Finance Bank Ltd",
    ifsc: "NESF",
  },
  {
    code: "NSDL",
    name: "NSDL Payments Bank Limited",
    ifsc: "NSPB",
  },
  {
    code: "OGB",
    name: "Odisha Gramya Bank",
    ifsc: "ODGB",
  },
  {
    code: "OBC",
    name: "Oriental Bank of Commerce",
    ifsc: "ORBC",
  },
  {
    code: "OCBC",
    name: "Oversea-Chinese Banking Corporation",
    ifsc: "Limited",
  },
  {
    code: "PUCB",
    name: "The Pandharpur Urban Co-operative Bank Ltd",
    ifsc: "PDUX",
  },
  {
    code: "PAYTM",
    name: "PayTM Payments Bank",
    ifsc: "PYTM",
  },
  {
    code: "PLGB",
    name: "Pallavan Grama Bank",
    ifsc: "PABX",
  },
  {
    code: "PNGB",
    name: "Pandyan Grama Bank",
    ifsc: "PDNX",
  },
  {
    code: "PBGB",
    name: "Paschim Banga Gramin Bank",
    ifsc: "PASX",
  },
  {
    code: "PKGB",
    name: "Pragathi Krishna Gramin Bank",
    ifsc: "PGBX",
  },
  {
    code: "PRAB",
    name: "Prathama Bank",
    ifsc: "PRTH",
  },
  {
    code: "PNSB",
    name: "Priyadarshani Nagari Sahakari Bank Ltd",
    ifsc: "PDSX",
  },
  {
    code: "PTBMI",
    name: "PT Bank Maybank Indonesia TBK",
    ifsc: "IBBK",
  },
  {
    code: "PUBG",
    name: "Puduvai Bharathiar Grama Bank",
    ifsc: "PBGX",
  },
  {
    code: "PCSB",
    name: "Pune Cantonment Sahakari Bank Ltd",
    ifsc: "PCTX",
  },
  {
    code: "PPCB",
    name: "Pune People's Co-operative Bank",
    ifsc: "PPBX",
  },
  {
    code: "PSB",
    name: "Punjab & Sind Bank",
    ifsc: "PSIB",
  },
  {
    code: "PGB",
    name: "Punjab Gramin Bank",
    ifsc: "PUGX",
  },
  {
    code: "PNB",
    name: "Punjab National Bank",
    ifsc: "PUNB",
  },
  {
    code: "PURB",
    name: "Purvanchal Bank",
    ifsc: "",
  },
  {
    code: "QNB",
    name: "Qatar National Bank SAQ",
    ifsc: "QNBA",
  },
  {
    code: "RMGB",
    name: "Rajasthan Marudhara Gramin Bank",
    ifsc: "MDGX",
  },
  {
    code: "RBL",
    name: "RBL Bank Ltd.",
    ifsc: "RATN",
  },
  {
    code: "SGB",
    name: "Saptagiri Grameena Bank",
    ifsc: "SPBX",
  },
  {
    code: "SCOB",
    name: "Saraswat Co-operative Bank",
    ifsc: "SRCB",
  },
  {
    code: "SHGB",
    name: "Sarva Haryana Gramin Bank",
    ifsc: "HGBX",
  },
  {
    code: "SUGB",
    name: "Sarva UP Gramin Bank",
    ifsc: "",
  },
  {
    code: "SAGB",
    name: "Saurashtra Gramin Bank",
    ifsc: "SAGX",
  },
  {
    code: "SBER",
    name: "Sberbank",
    ifsc: "",
  },
  {
    code: "SBM",
    name: "SBM Bank (Mauritius) Ltd",
    ifsc: "STCB",
  },
  {
    code: "SCAN",
    name: "Scanned Statement",
    ifsc: "",
  },
  {
    code: "SVBL",
    name: "The Seva Vikas Co-operative Bank Limited",
    ifsc: "SVBL",
  },
  {
    code: "SVCB",
    name: "Shamrao Vithal Co-operative Bank",
    ifsc: "",
  },
  {
    code: "SHIN",
    name: "Shinhan Bank",
    ifsc: "SHBK",
  },
  {
    code: "KNSB",
    name: "Shree Kadi Nagarik Sahakari Bank Ltd",
    ifsc: "KDIX",
  },
  {
    code: "SMCB",
    name: "Shree Mahesh Co- Op Bank Limited",
    ifsc: "SHRX",
  },
  {
    code: "CRUB",
    name: "Shri Chhatrapati Rajarshi Shahu Urban Co-Operative Bank",
    ifsc: "",
  },
  {
    code: "SIAM",
    name: "Siam Commercial Bank",
    ifsc: "",
  },
  {
    code: "SOGE",
    name: "Societe Generale",
    ifsc: "SOGE",
  },
  {
    code: "SONA",
    name: "Sonali Bank Ltd.",
    ifsc: "SBLD",
  },
  {
    code: "SIB",
    name: "South Indian Bank Ltd.",
    ifsc: "SIBL",
  },
  {
    code: "SCB",
    name: "Standard Chartered Bank",
    ifsc: "SCBL",
  },
  {
    code: "SBI",
    name: "State Bank of India",
    ifsc: "SBIN",
  },
  {
    code: "SLBL",
    name: "Subhadra Local Bank Ltd",
    ifsc: "",
  },
  {
    code: "SMBC",
    name: "Sumitomo Mitsui Banking Corporation",
    ifsc: "SMBC",
  },
  {
    code: "SPCB",
    name: "The Surat People's Co-Op. Bank Ltd.",
    ifsc: "",
  },
  {
    code: "SSFB",
    name: "Suryoday Small Finance Bank Ltd.",
    ifsc: "SURY",
  },
  {
    code: "SUTL",
    name: "Sutlej Gramin Bank",
    ifsc: "SJGX",
  },
  {
    code: "SYNB",
    name: "Syndicate Bank",
    ifsc: "SYNB",
  },
  {
    code: "TMBL",
    name: "Tamilnad Mercantile Bank Ltd.",
    ifsc: "TMBL",
  },
  {
    code: "TGCB",
    name: "Tamluk Ghatal Central Co-operative Bank Ltd.",
    ifsc: "",
  },
  {
    code: "TEGB",
    name: "Telengana Grameena Bank",
    ifsc: "",
  },
  {
    code: "ACCUK",
    name: "The Access Bank UK",
    ifsc: "",
  },
  {
    code: "BTM",
    name: "The Bank of Tokyo- Mitsubishi UFJ",
    ifsc: "BOTM",
  },
  {
    code: "RBS",
    name: "The Royal Bank of Scotland plc",
    ifsc: "",
  },
  {
    code: "MCBL",
    name: "The Municipal Co-Op. Bank Ltd.",
    ifsc: "",
  },
  {
    code: "TJSB",
    name: "TJSB Sahakari Bank Ltd",
    ifsc: "TJSB",
  },
  {
    code: "TTB",
    name: "TMBThanachart Bank Public Company Limited",
    ifsc: "",
  },
  {
    code: "TRGB",
    name: "Tripura Gramin Bank",
    ifsc: "TGBX",
  },
  {
    code: "UCOB",
    name: "UCO Bank",
    ifsc: "UCBA",
  },
  {
    code: "USFB",
    name: "Ujjivan Small Finance Bank Ltd.",
    ifsc: "UJVN",
  },
  {
    code: "UBI",
    name: "Union Bank of India",
    ifsc: "UBIN",
  },
  {
    code: "UNBI",
    name: "United Bank of India",
    ifsc: "UTBI",
  },
  {
    code: "UOBL",
    name: "United Overseas Bank Ltd.",
    ifsc: "UOVB",
  },
  {
    code: "UGB",
    name: "Utkal Grameen bank",
    ifsc: "UGBX",
  },
  {
    code: "UTSF",
    name: "Utkarsh Small Finance Bank Ltd.",
    ifsc: "UTKS",
  },
  {
    code: "UBGB",
    name: "Uttar Bihar Gramin Bank",
    ifsc: "UBGX",
  },
  {
    code: "UTGB",
    name: "Uttarakhand Gramin Bank",
    ifsc: "UTGX",
  },
  {
    code: "UKGB",
    name: "Uttarbanga Kshetriya Gramin Bank",
    ifsc: "UKGX",
  },
  {
    code: "VGB",
    name: "Vananchal Gramin Bank",
    ifsc: "",
  },
  {
    code: "VVSB",
    name: "Vasai Vikas Sahakari Bank Ltd.",
    ifsc: "VVSB",
  },
  {
    code: "VKGB",
    name: "Vidharbha Konkan Gramin Bank",
    ifsc: "",
  },
  {
    code: "VIJ",
    name: "Vijaya Bank",
    ifsc: "VIJB",
  },
  {
    code: "VSBL",
    name: "The Vishweshwar Sahakari Bank Ltd.",
    ifsc: "VSBL",
  },
  {
    code: "WBC",
    name: "Westpac Banking Corporation",
    ifsc: "WPAC",
  },
  {
    code: "WOOB",
    name: "Woori Bank",
    ifsc: "HVBK",
  },
  {
    code: "YESB",
    name: "YES Bank Ltd.",
    ifsc: "YESB",
  },
  {
    code: "IDBI",
    name: "IDBI Bank Limited",
    ifsc: "IBKL",
  },
  {
    code: "KOTAK",
    name: "Kotak Mahindra Bank Ltd",
    ifsc: "KKBK",
  },
];
