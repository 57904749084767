import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../../components/Appbar";
import waitIcon from "../../../../assets/wait-glass.svg";

import { GlobalContext } from "../../../../Contexts/GlobalContext";
// import TimeInput from "../../Components/DateInput";

const Failed = forwardRef((props, ref) => {
  const { application_id } = useParams();
  const { bottomNav } = useContext(GlobalContext);

  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleBack = () => {
    navigate("/home");
  };

  const style = {
    reviewTxt: {
      fontSize: "30px",
      fontWeight: "900",
      color: "#151940",
    },
    tagTxt: {
      padding: "0 30px",
      color: "#151940",
      fontWeight: 500,
      marginTop: "40px",
    },
  };

  return (
    <>
      <Box className="" ref={ref}>
        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div
          className="main-wrapper"
          style={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "90vh",
          }}
        >
          <div>
            <p className="text-center">
              <img src={waitIcon} alt="wait" />
            </p>
            <p className="text-center" style={style?.tagTxt}>
              Your application is under review.<br/> Our team will verify you bank
              details and get back to you soon.
            </p>
          </div>
        </div>

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={() => window.location.reload()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Refresh"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default Failed;
