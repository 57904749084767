import {
  Avatar,
  BottomNavigation,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import MultiUpload from "../../../../components/MultipleUpload";
import CaptureUpload from "../../../../components/CaptureV2";
import MenuAppBar from "../../../../components/Appbar";
import CustomInputAutocomplete from "../../../../components/Autocomplete/AutoCompleteBank";

import { FiFileText } from "react-icons/fi";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CustomerContext } from "../../../../Contexts/CustomerContext";
import { useNavigate, useParams } from "react-router-dom";
import { IncredoBankList } from "../../../../util/constants";
import { updateBankDetails } from "../../../../api/agent";
import useCustomer from "../../../../hooks/useCustomer";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { isBlank } from "../../../../validations/fieldValidations";

const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const BankDetailsStatement = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const { application_id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [pUploadError, setPError] = useState(false);
  const [pErrorTxt, setPErrorTxt] = useState("");
  const [banks, setBanks] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({});
  const [bankStatement, setBankStatement] = useState("");

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const { bottomNav } = useContext(GlobalContext);
  const [bankDetails, setBankDetails] = useState({ cheques: [] });

  const navigate = useNavigate();

  const isValid = async () => {
    let valid = true;
    let errors = {
      bank_name: isBlank(journeyData?.bank_name || ""),
      bank_code: isBlank(journeyData?.bank_code || ""),
      account_type: isBlank(journeyData?.account_type || ""),
      cash_credit:
        journeyData?.account_type === "Cash_Credit_Account_Overdraft_Account"
          ? isBlank(journeyData?.credit_over_limit || "")
          : "",

      bank_statement: bankDetails?.cheques?.length === 0 && "Upload Statement",
      statement_password: journeyData?.is_password
        ? isBlank(journeyData?.pdf_password || "")
        : "",
    };

    console.log(errors);
    setFieldErrors(errors);

    for (let i in errors) {
      if (errors[i].length > 0) {
        valid = false;
      }
    }

    return valid;
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (await isValid()) {
      let payload = {
        bank_name: journeyData?.bank_name,
        // bank_beneficiary_name: "",
        bank_code: journeyData?.bank_code,
        bank_account_type: journeyData?.account_type,
        bank_statement: bankDetails?.cheques,
        cash_credit: journeyData?.credit_over_limit || "",
        is_file_protected: journeyData?.is_password,
        statement_password: journeyData?.pdf_password || "",
      };

      console.log(payload);

      // setLoading(false);
      // return;

      try {
        let { data } = await updateBankDetails(payload, application_id, true);
        if (data?.status) {
          navigate("/onboard/bank-accounts/" + application_id);
        }
      } catch (error) {
        enqueueSnackbar(error?.response?.data?.message, {
          variant: "error",
        });
      }
    }

    setLoading(false);
  };

  const removeItem = (index) => {
    setBankDetails((st) => ({
      ...st,
      cheques: bankDetails.cheques.filter((val, i) => {
        if (i !== index) return val;
      }),
    }));
  };

  return (
    <>
      <Box className="kyc-wrapper w-100" sx={{ pb: 7, pt: 5 }} ref={ref}>
        <MenuAppBar home={false} draft={true} action={()=>{
          navigate(-1)
        }}/>
        <CssBaseline />
        <div className="main-wrapper">
          <Grid item xs={12} sm={12}>
            <label className="form-label">
              Bank Name <span className="mand-star">*</span>
            </label>

            <CustomInputAutocomplete
              options={IncredoBankList}
              onChange={(val) => {
                // setFieldErrors((prev) => ({ ...prev, bank: "" }));
                setJourneyData((prev) => ({
                  ...prev,
                  bank_name: val?.name,
                  bank_code: val?.code,
                }));
                // let bank = BanksList.find((el) => el.bankname === val);
                // setIfsc(bank?.ifsc);
              }}
            />
            <span className="error">{fieldErrors.bank_name}</span>
          </Grid>

          <Grid item xs={12} sm={12} className="pt-0 mt-4">
            <label className="form-label">
              Bank Account Type
              <span className="mand-star">*</span>
            </label>
            <RadioGroup
              // ref={refs.is_aadhaar_property_owned}
              className="mb-1 ps-3"
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={journeyData.account_type}
              onChange={(e) => {
                setJourneyData({
                  ...journeyData,
                  account_type: e.target.value,
                });
              }}
            >
              <FormControlLabel
                value={"savings"}
                control={<Radio sx={radioStyle} />}
                label={
                  <span style={{ fontSize: "10px" }}>Savings Account</span>
                }
                sx={labelStyle}
              />

              <FormControlLabel
                value={"current"}
                control={<Radio sx={radioStyle} />}
                label={
                  <span style={{ fontSize: "10px" }}>Current Account</span>
                }
                sx={labelStyle}
              />

              <FormControlLabel
                value={"Cash_Credit_Account_Overdraft_Account"}
                control={<Radio sx={radioStyle} />}
                label={
                  <span style={{ fontSize: "10px" }}>
                    Cash Credit Account/Overdraft Account
                  </span>
                }
                sx={labelStyle}
              />
            </RadioGroup>

            <span className="error">
              {fieldErrors.account_type}
            </span>
          </Grid>

          {journeyData?.account_type ===
            "Cash_Credit_Account_Overdraft_Account" && (
            <Grid item xs={12} sm={12}>
              <label className="form-label mt-4">
                Cash Credit / Overfdraft Limit{" "}
                <span className="mand-star">*</span>
              </label>
              <input
                type="text"
                className="form-input w-100 px-3"
                required
                placeholder="Cash Credit / Overfdraft Limit"
                name="credit-limit"
                autoComplete="family-name"
                value={journeyData.credit_over_limit}
                onChange={(e) => {
                  setJourneyData((st) => ({
                    ...st,
                    credit_over_limit: e.target.value,
                  }));
                }}
                onKeyUp={
                  (e) => {}
                  // setFieldErrors((st) => ({
                  //   ...st,
                  //   email: isEmailValid(e.target.value),
                  // }))
                }
              />
              <span className="error">{fieldErrors.cash_credit}</span>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <div className="capture-only mt-5">
              <div className="capture-content-v2" style={{ border: "0" }}>
                <FiFileText
                  className="me-2"
                  style={{ color: "rgba(108, 99, 255, 1)" }}
                />
                <span className="input-placeholder-capture">
                  Bank Statement (pdf only) <span className="mand-star">*</span>
                </span>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Grid item xs={6} sm={6}>
              {bankDetails.cheques.map((val, i) => {
                let type = val
                  .match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0]
                  .split("/")[1];
                return (
                  <>
                    <CardHeader
                      avatar={
                        type !== "pdf" ? (
                          <img src={val} width="50px" height="50px" />
                        ) : (
                          <InsertDriveFileIcon
                            sx={{
                              color: "rgba(108, 99, 255, 1)",
                              fontSize: "40px",
                            }}
                          />
                        )
                      }
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={() => removeItem(i)}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      }
                      title={`Statement - ${i + 1}`}
                      subheader={`file type: ${type}`}
                    />
                  </>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            {bankDetails.cheques.length < 6 && (
              <MultiUpload
                text={
                  bankDetails.cheques.length === 0
                    ? `Upload File`
                    : `${
                        bankDetails.cheques.length < 3
                          ? "Add more (" + bankDetails.cheques.length + "/3)"
                          : "Add more"
                      }`
                }
                setFile={(file) => {
                  setBankDetails((st) => ({
                    ...st,
                    cheques: [...bankDetails.cheques, file],
                  }));
                }}
                // onSelect={() => {
                //   setFieldErrors((p) => ({ ...p, selfie: "" }));
                // }}
              />
            )}
            <span className="error">{fieldErrors.bank_statement}</span>
          </Grid>

          <Grid item xs={12} sm={12} className="mt-4">
            <FormControlLabel
              className="mb-2"
              control={
                <Checkbox color="success" checked={journeyData?.is_password} />
              }
              onChange={(e) => {
                setJourneyData({
                  ...journeyData,
                  is_password: e.target.checked,
                });
                // if (!showReward) {
                //   setShowReward(true);
                //   setShowRewardInfo(true);
                // }
              }}
              label={
                <span className="checkbox-txt" style={{ fontSize: 12 }}>
                  Is this file password protected
                </span>
              }
            />
          </Grid>

          {journeyData?.is_password && (
            <Grid item xs={12} sm={12}>
              <label className="form-label">Password</label>
              <input
                type="text"
                className="form-input w-100 px-3"
                required
                placeholder="Your Pdf Password"
                name="pdf-password"
                autoComplete="family-name"
                value={journeyData.pdf_password}
                onChange={(e) => {
                  setJourneyData((st) => ({
                    ...st,
                    pdf_password: e.target.value,
                  }));
                }}
                onKeyUp={
                  (e) => {}
                  // setFieldErrors((st) => ({
                  //   ...st,
                  //   email: isEmailValid(e.target.value),
                  // }))
                }
              />
              <span className="error">{fieldErrors.statement_password}</span>
            </Grid>
          )}
        </div>

        <Paper className="paper-bottom" elevation={3} ref={bottomNav}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
});

export default BankDetailsStatement;
