import {
  BottomNavigation,
  Button,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import MenuAppBar from "../../../../components/Appbar";
import successIco from "../../../../assets/success-badge.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import { coCustomerAdd } from "../../../../api/agent";
// import TimeInput from "../../Components/DateInput";

const bodyStyle = `
body {
    background: rgb(30,22,171);
    background: linear-gradient(333deg, rgba(30,22,171,1) 0%, rgba(23,23,189,1) 35%, rgba(0,212,255,1) 100%) fixed;
}
`;

export const CongratsCoRequired = forwardRef(
  ({ origin, action, coNeeded, application_id }, ref) => {
    const { bottomNav } = useContext(GlobalContext);

    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    // const handleCoApplicant = async () => {
    //   try {
    //     setLoading(true);
    //     let { data } = await coCustomerAdd(application_id);
    //     navigate("/create-loan/co-customer/" + application_id);
    //   } catch (error) {
    //     enqueueSnackbar("Server error", { variant: "error" });
    //   }
    //   setLoading(false);
    // };

    const style = {
      reviewTxt: {
        fontSize: "30px",
        fontWeight: "500",
        color: "white",
      },
      tagTxt: {
        padding: "0 20px",
        color: "white",
      },
      coNote: {
        backgroundColor: "#ffffff3d",
        padding: "20px",
        borderRadius: "10px",
        color: "white",
        fontWeight: "300",
      },
    };


    return (
      <>
        <style>{bodyStyle}</style>
        <Box ref={ref}>
          <div className={`appbar-wrapper`}>
            <div className="appbar w-100">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArrowBackIcon
                    sx={{ color: "white", cursor: "pointer" }}
                    className="ms-3"
                    onClick={() => {
                      navigate("/home");
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <CssBaseline />
          <div
            className="main-wrapper"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              height: "90vh",
            }}
          >
            <div>
              <p className="text-center">
                <img src={successIco} alt="wait" />
              </p>
              <p className="text-center mb-0" style={style?.reviewTxt}>
                Congratulations
              </p>
              <p className="text-center" style={style?.tagTxt}>
                We can proceed with the Application
              </p>

              <>
                {" "}
                <p className="text-center mt-5" style={style?.tagTxt}>
                  Co Applicant or Guarantor Required
                </p>
                <p className="text-center" style={style?.coNote}>
                  Note: Co Applicant or Guarantor must have own residence in
                  current residential region of Applicant.
                </p>
              </>
            </div>

            <div
              className="button-wrapper"
              style={{
                position: "fixed",
                bottom: "5px",
                width: "100%",
                padding: "0 20px",
              }}
            >
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mb: 2,
                  bgcolor: "white !important",
                  color: "rgb(30,22,171) !important",
                }}
                onClick={() => action()}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </>
    );
  }
);

export default CongratsCoRequired;
