import React, { useContext, useEffect } from "react";
import { Route, Navigate, Outlet, useLocation } from "react-router-dom";
import { UserContext } from "./../Contexts/UserContext";
import Loading from "./../components/Loading";
import RegisterName from "./RegisterName";
import useFindUser from "../hooks/AuthScreens/Customer/useFindUser";
import { verifyAuth } from "../api";
import { GlobalContext } from "../Contexts/GlobalContext";
import PermissionModal from "../components/PermissionModal";
import usePermission from "../hooks/AuthScreens/Customer/usePermission";

export default function PrivateRoute() {
  const { user, isLoading } = useContext(UserContext);
  const { isDenied } = useContext(GlobalContext);
  const { getUserLocation } = usePermission();
  const { pathname } = useLocation();

  if (isLoading) {
    return <Loading />;
  }

  if (user) {
    if (isDenied) {
      return <PermissionModal />;
    }

    return <Outlet />;
  } else {
    return <Navigate to="/" state={{ from: pathname }} replace />;
  }

  // return <Outlet/>
}
