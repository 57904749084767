import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
// import Avatar from "@mui/material/Avatar";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { GiReceiveMoney } from "react-icons/gi";
import { AiOutlineHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import MenuAppBar from "../../../components/Appbar";
import {
  Chip,
  CircularProgress,
  Grid,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, FormControl, Row } from "react-bootstrap";
import moment from "moment";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import SearchIcon from "@mui/icons-material/Search";
import emptyIco from "../../../assets/empty.png";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getDraftApplication } from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";

export default function DraftApplication() {
  const ref = useRef(null);
  const selectRef = useRef(null);

  const [applications, setApplications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [time, setTime] = useState("Any time");
  const [searchTxt, setSearchTxt] = useState("");
  const [letSearch, setLetSearch] = useState("");
  const [isSearching, setSearching] = useState(false);

  const navigate = useNavigate();

  const { showPermissionState, applicationState } = useContext(GlobalContext);

  const fetchDraftApplications = async (type, time) => {
    setLoading(true);
    try {
      let { data } = await getDraftApplication();
      setApplications(data?.loans);
    } catch (error) {}
    setLoading(false);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home/loan");
    };
  });

  const mapStage = (stage) => {
    switch (stage) {
      case "CUSTOMER_BASIC_DETAILS":
        return "Customer Details";
        break;
      case "CUSTOMER_BUSINESS_DETAILS":
        return "Business Details";
        break;
      case "CUSTOMER_EMBIFI_REJECTED":
        return "Rejected";
        break;
      case "CUSTOMER_CREDIT_ENGINE_FAILED":
        return "Under Review";
        break;
      case "CUSTOMER_UNDER_REVIEW":
        return "Under Review";
        break;
      case "CO_CUSTOMER_CREATION":
        return "Co-Applicant Onboarding";
        break;
      case "CO_CUSTOMER_RECREATION":
        return "Co-Applicant Re-creation";
        break;
      case "CO_CUSTOMER_BASIC_DETAILS":
        return "Co-Applicant Basic Details";
        break;
      case "CO_CUSTOMER_EMBIFI_REJECTED":
        return "Co-Applicant Rejected";
        break;
      case "CO_CUSTOMER_CREDIT_ENGINE_FAILED":
        return "Co-Applicant Under Review";
        break;
      case "CO_CUSTOMER_UNDER_REVIEW":
        return "Co-Applicant Under Review";
        break;
      case "APPLICATION_UNDER_REVIEW":
        return "Under Review";
        break;
      case "CREATE_BANK":
        return "Add bank";
        break;
      case "LOAN_OFFER":
        return "Loan Summary";
        break;
      case "BANK_ACCOUNTS":
        return "Bank Accounts";
        break;
      case "PAYMENT_METHOD":
        return "Payment Selection";
        break;
      case "AADHAAR_E_SIGN":
        return "MITC";
        break;
      case "AADHAAR_E_SIGN_STATUS":
        return "Aadhaar e-sign Status";
        break;
      case "PENNY_DROP_FAILED":
        return "Bank Details Under Review";
        break;
      case "WAITING_FOR_DISBURSAL":
        return "Waiting Disbursal";
        break;
      case "DISBURSED":
        return "Disbursed";
        break;
      case "COMPLETED":
        return "Completed";
        break;
    }
  };

  const { getStage } = useApplication();

  useEffect(() => {
    navigate("/home")
    // fetchDraftApplications();
  }, []);

  return (
    <Box sx={{ pb: 7 }} ref={ref} className="content-center">
      <Grid container spacing={2} className="p-3 response-wrapper">
        <Grid item xs={12} sm={12}>
          <div className="page-head">
            <ArrowBackIcon
              sx={{ color: "#274384" }}
              className="pointer"
              onClick={() => {
                navigate("/home/loan");
              }}
            />{" "}
            Draft Applications
          </div>
        </Grid>

        {/* <Grid item xs={8} sm={8}>
          <div style={{ position: "relative" }}>
            <input
              type={"text"}
              placeholder="Search Name/PAN/Number"
              class="form-input input-blue input-blue-search w-100"
              value={searchTxt}
              onChange={(e) => {
                setSearchTxt(e.target.value);
              }}
            />

            <SearchIcon
              className="search-ico "
              style={{ color: "gray" }}
              onClick={() => {}}
            />
          </div>
        </Grid>

        <Grid item xs={4} sm={4} className="ps-2">
          <Select
            reference={selectRef}
            search={true}
            state={time}
            default={true}
            options={[
              "Any time",
              "1 week",
              "1 month",
              "3 month",
              "6 month",
              "1 year",
            ]}
            isBlue={true}
            styleText={true}
            getData={(val) => {
              setTime(val);
            }}
          />
        </Grid> */}

        <Grid item xs={12} sm={12}>
          <Container
            className="load-collapse-row mb-0"
            style={{
              boxShadow: "none",
              border: "none",
              position: "sticky",
              top: 0,
            }}
          >
            <Row
              className="mt-2"
              style={{
                color: "#274384",
                backgroundColor: "white",
                paddingTop: 10,
                paddingBottom: 1,
              }}
            >
              <Col className="font-blue d-flex justify-content-start">Date</Col>
              <Col className="font-blue d-flex justify-content-start">Name</Col>
              <Col className="font-blue d-flex justify-content-end">Stage</Col>
            </Row>
          </Container>

          {isSearching && (
            <p className="w-100 text-center mt-2 mb-2">
              <CircularProgress size={25} />
            </p>
          )}

          {!isLoading ? (
            <>
              {applications?.length !== 0 &&
                applications?.map((val) => {
                  return (
                    <>
                      <Container
                        className="load-collapse-row collapse-blue pointer"
                        onClick={() => {
                          // setSelectedApplication(val?.id);
                          // fetchStage(
                          //   val?.id,
                          //   val?.customerId,
                          //   val?.current_stage
                          // );
                          getStage(val?.stage, val?.application_id, val?.coCustomerData ? true : false);
                        }}
                      >
                        <Row className="mt-2">
                          <Col className="font-blue">
                            <p className="app-p">
                              {" "}
                              {val?.createdAt
                                ? moment(val?.createdAt).format("DD/MM/YYYY")
                                : "---"}
                            </p>
                          </Col>
                          <Col className="font-blue">
                            <p className="app-p text-left">
                              {val?.customerData?.pan_details?.name}
                            </p>
                          </Col>
                          <Col className="font-blue">
                            <p className="app-p text-end">{mapStage(val?.stage)}</p>
                          </Col>
                        </Row>
                      </Container>
                    </>
                  );
                })}
              {applications.length === 0 && (
                <p className="text-center mt-5">
                  <img src={emptyIco} />
                  <p style={{ color: "gray" }}>No Applications here</p>
                </p>
              )}{" "}
            </>
          ) : (
            <>
              {["", "", ""].map((val) => {
                return (
                  <>
                    <Container className="load-collapse-row collapse-blue pointer">
                      <Row className="mt-2">
                        <Col className="font-blue">
                          <Skeleton width={"100%"} />
                        </Col>
                        <Col className="font-blue">
                          <Skeleton width={"100%"} />
                        </Col>
                        <Col className="font-blue">
                          <Skeleton width={"100%"} />
                        </Col>
                      </Row>
                    </Container>
                  </>
                );
              })}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
