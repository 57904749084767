import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import Congrats from "./Congrats";
import Failed from "./Failed";
import Success from "./Success";
import UnderReview from "./UnderReview";
import Waiting from "./Waiting";

const LoanStatus = () => {
  const navigate = useNavigate();
  const { application_id } = useParams();

  const [status, setStatus] = useState("loading");

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  useEffect(() => {
    switch (journeyData?.stage) {
      case "APPLICATION_UNDER_REVIEW":
        setStatus("review");
        break;
      case "BANK_ACCOUNTS":
        if(journeyData?.temp_status){
          setStatus("review");
          break;
        }
      // case "WAITING_FOR_DISBURSAL":
      //   setStatus("waiting");
      //   break;
      // case "DISBURSED":
      //   setStatus("disbursed");
      //   break;
    }
  }, [journeyData?.stage, navigate]);

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  return (
    <>
      <div className="response-wrapper w-100">
        {status === "penny" && <Failed />}
        {status === "review" && <UnderReview />}
        {/* {status === "waiting" && <Waiting />}
        {status === "disbursed" && (
          <Success
            text={"Disbursement Completed"}
            action={() => {
              navigate("/create-loan/progress/" + application_id);
            }}
          />
        )} */}
      </div>
      {status === "loading" && <LoadingOver />}
    </>
  );
};

export default LoanStatus;
