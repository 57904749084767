import React from "react";
import HandymanIcon from "@mui/icons-material/Handyman";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const PageUnder = () => {
    const navigate  =useNavigate()
  return (
    <div className="center">
      <p className="text-center">
        <HandymanIcon style={{ fontSize: "100px" }} />
      </p>
      <p>Page Under Construction</p>
      <p className="text-center"><Button
        variant="outlined"
        onClick={() => {
          navigate(-1);
        }}
      >
        Go back
      </Button></p>
    </div>
  );
};

export default PageUnder;
