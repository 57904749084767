import {
  BottomNavigation,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ageValidate, validateDob } from "../../../validations/dateValidations";
import { createCoCustomer, createCustomer } from "../../../api";
import MenuAppBar from "../../../components/Appbar";
import TimeInput from "../../../components/DateInput";
import { GlobalContext } from "../../../Contexts/GlobalContext";
import { LoanContext } from "../../../Contexts/LoanJourneyContext";
import {
  updateAddress,
  updateBusiness,
  verifyPincode,
} from "../../../api/agent";
import useApplication from "../../../hooks/useApplication";
import {
  isBlank,
  isGSTValid,
  isMobValid,
  isStartSpace,
} from "../../../validations/fieldValidations";
import PsychometricTest from "./PsychometricTest";
import RewardFloating from "../../../components/RewardFloating";
import RewardWallet from "../../../components/V2/Reward/RewardWallet";
// import TimeInput from "../../Components/DateInput";

const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const BusinessDetails = forwardRef((props, ref) => {
  const { bottomNav, otpState } = useContext(GlobalContext);
  const [otpData, setOtpData] = otpState;

  const refs = {
    business_start_date: useRef(null),
    registered_address: useRef(null),
    registered_pincode: useRef(null),
    is_registered_owned: useRef(null),
    business_address: useRef([]),

    aadhaar_address: useRef(null),
    aadhaar_pincode: useRef(null),
    current_address: useRef(null),
    current_pincode: useRef(null),
    is_property_owned: useRef(null),
    is_aadhaar_property_owned: useRef(null),
    mobile_number: useRef(null),
    gender: useRef(null),
  };

  const [isPsycho, setPsycho] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fieldErrors, setErrors] = useState({ business_address: [] });

  const { application_id } = useParams();
  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;
  const [showReward, setShowReward] = useState(false);
  const [showRewardInfo, setShowRewardInfo] = useState(false);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleInput = (e, setState) => {
    let result = validateDob(e.target.value);
    setState((st) => ({ ...st, business_start_date: result }));
  };

  const validDate = (date, key) => {
    if (date.length === 0 || date?.includes("_")) {
      return "Provide a valid date";
    } else {
      // if (key === "dob") {
      //   if (date.length > 0 && !date?.includes("_")) {
      //     return ageValidate(journeyData.date_of_birth);
      //   }
      // }

      return "";
    }
  };

  const isValid = async () => {
    try {
      console.log(journeyData.registered_address);
      let valid = true;
      let errors = {
        business_name: isBlank(journeyData.business_name),
        gst_number: isGSTValid(journeyData.gst_number),
        business_start_date: validDate(journeyData.business_start_date),
        registered_address: isBlank(journeyData.registered_address),
        registered_pincode: await isBlank(journeyData.registered_pincode),
        is_registered_owned: [null, undefined, ""].includes(
          journeyData?.is_registered_owned
        )
          ? "Select any option"
          : "",
        business_address: [],
      };

      let i = 0;
      for await (const address of journeyData?.business_address) {
        let address_errors = {
          operational_address:
            ![null, undefined, ""].includes(
              journeyData?.is_operational_address_same
            ) &&
            journeyData?.is_operational_address_same &&
            i === 0
              ? ""
              : isBlank(address?.operational_address || ""),
          operational_pincode:
            ![null, undefined, ""].includes(
              journeyData?.is_operational_address_same
            ) &&
            journeyData?.is_operational_address_same &&
            i === 0
              ? ""
              : await isPinValid(address?.operational_pincode),
          is_operational_property_owned:
            ![null, undefined, ""].includes(
              journeyData?.is_operational_address_same
            ) &&
            journeyData?.is_operational_address_same &&
            i === 0
              ? ""
              : [null, undefined, ""].includes(
                  address?.is_operational_property_owned
                )
              ? "Select any option"
              : "",
        };

        errors.business_address[i] = address_errors;
        i++;
      }

      console.log(errors);

      setErrors((prev) => ({ ...prev, ...errors }));

      // if (state.full_name === "" || state.pan_number === "") valid = false;
      let first = false;
      for (let i in errors) {
        if (i === "business_address") {
          for (let key in errors[i]) {
            if (errors[i][key].length > 0) {
              if (!first) {
                first = true;
                // refs[i]?.current?.focus();
                // window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
              }
              valid = false;
            }
          }
        } else {
          if (errors[i].length > 0) {
            if (!first) {
              first = true;
              refs[i]?.current?.focus();
              window.scrollTo(0, refs[i]?.current?.offsetTop - 100);
            }
            valid = false;
          }
        }
      }

      // return true;
      return valid;
    } catch (err) {
      console.log(err);
    }
  };

  const isPinValid = async (pincode, key) => {
    try {
      let { data } = await verifyPincode(pincode);
      if (Object.keys(data).length !== 0) {
        return "";
      } else {
        return "Invalid pincode";
      }
    } catch (error) {
      return "Pincode validation failed";
    }
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (await isValid()) {
      let payload = {
        business_name: journeyData?.business_name,
        gst_number: journeyData?.gst_number,
        business_start_date: journeyData?.business_start_date,

        registered_address: journeyData?.registered_address,
        registered_pincode: journeyData?.registered_pincode,
        is_registered_owned: journeyData?.is_registered_owned,
        is_address_same: journeyData?.is_operational_address_same,
      };

      if (journeyData?.is_operational_address_same) {
        let business_address = journeyData?.business_address;
        business_address[0] = {
          operational_address: journeyData?.registered_address,
          operational_pincode: journeyData?.registered_pincode,
          is_operational_property_owned: journeyData?.is_registered_owned,
        };

        payload = {
          ...payload,
          operational_address: business_address,
        };
      } else {
        payload = {
          ...payload,
          operational_address: journeyData?.business_address,
        };
      }

      try {
        let { data } = await updateBusiness(payload, application_id);
        if (data?.status) {
          navigate(`/onboard/pan-details/${application_id}`);
        }
      } catch (err) {
        let error = "";
        if (err?.response?.data?.message) {
          if (err?.response?.data?.message === "Bad request parameters") {
            error = "Please re-check all the inputs";
          } else {
            error = err?.response?.data?.message;
          }
        } else {
          error = "Something went wrong";
        }
        enqueueSnackbar(error, { variant: "error" });
      }
    }
    setLoading(false);
  };

  const handleBack = async () => {
    navigate("/home");
  };

  const handleAddmore = () => {
    setJourneyData({
      ...journeyData,
      business_address: [
        ...journeyData?.business_address,
        {
          operational_address: "",
          operational_pincode: "",
          is_operational_property_owned: null,
          // is_address_same: null,
        },
      ],
    });
  };

  const { getApplicationDetails } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  const clearError = (i, key) => {
    if (fieldErrors.business_address[i]) {
      fieldErrors.business_address[i][key] = "";
      setErrors({
        ...fieldErrors,
      });
    }
  };

  const handleDeleteAddress = (i) => {
    let currentArray = journeyData?.business_address || [];
    currentArray.splice(i, 1);
    setJourneyData({
      ...journeyData,
      business_address: currentArray,
    });
  };

  return !isPsycho ? (
    // return true ? (
    <>
      <Box
        className="kyc-wrapper"
        sx={{ pb: 7, pt: 7, position: "relative" }}
        ref={ref}
      >
        <RewardFloating
          isOpen={showReward}
          showInfo={showRewardInfo}
          action={() => setShowRewardInfo(false)}
          onClick={() => {
            setPsycho(true);
            setShowReward(false);
          }}
        />

        <MenuAppBar home={false} action={handleBack} />
        <CssBaseline />
        <div className="main-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <label className="form-label">Name of Firm</label>
              <input
                ref={refs?.business_name}
                className="form-input w-100"
                autoComplete="given-name"
                name="firmName"
                fullWidth
                // disabled
                placeholder="Enter firm name"
                autoFocus
                value={journeyData.business_name}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    business_name: e.target.value,
                  });

                  setErrors((prev) => ({
                    ...prev,
                    business_name: "",
                  }));
                }}
              />
              <span className="error">{fieldErrors?.business_name}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">GST Number</label>
              <input
                ref={refs?.gst_number}
                className="form-input w-100"
                required
                fullWidth
                // disabled
                placeholder="Enter your GST number"
                autoComplete="family-name"
                value={journeyData.gst_number}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    gst_number: e.target.value,
                  });

                  setErrors((prev) => ({
                    ...prev,
                    gst_number: "",
                  }));
                }}
              />
              <span className="error">{fieldErrors?.gst_number}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">Business Start Date</label>

              <TimeInput
                ref={refs.business_start_date}
                value={journeyData.business_start_date}
                onChange={(e) => {
                  setErrors({ ...fieldErrors, business_start_date: "" });
                  handleInput(e, setJourneyData);
                }}
                onBlur={() => {
                  if (journeyData.business_start_date === "__/__/____")
                    setJourneyData((st) => ({
                      ...st,
                      business_start_date: "",
                    }));
                  setErrors((prev) => ({
                    ...prev,
                    business_start_date: validDate(
                      journeyData.business_start_date
                    ),
                  }));
                }}
              ></TimeInput>

              <span className="error">{fieldErrors?.business_start_date}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                Business Registered Address <span className="mand-star">*</span>
              </label>
              <textarea
                ref={refs.registered_address}
                className="form-input-area w-100"
                required
                fullWidth
                placeholder="Aadhaar Address"
                autoComplete="family-name"
                value={journeyData.registered_address}
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    registered_address: e.target.value,
                  });

                  setErrors((prev) => ({
                    ...prev,
                    registered_address: "",
                  }));
                }}
              />
              <span className="error">{fieldErrors?.registered_address}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="form-label">
                Business Registered Pincode <span className="mand-star">*</span>
              </label>
              <input
                ref={refs.registered_pincode}
                className="form-input w-100"
                required
                fullWidth
                placeholder="Aadhaar Pincode"
                autoComplete="family-name"
                value={journeyData.registered_pincode}
                onChange={(e) => {
                  // if (
                  //   e.target.value !== "" &&
                  //   !/^\d+$/.test(e.target.value)
                  // ) {
                  //   return false;
                  // }
                  // if (e.target.value.length > 6) return false;

                  setJourneyData({
                    ...journeyData,
                    registered_pincode: e.target.value,
                  });

                  setErrors((prev) => ({
                    ...prev,
                    registered_pincode: "",
                  }));
                }}
              />
              <span className="error">{fieldErrors.registered_pincode}</span>
            </Grid>

            <Grid item xs={12} sm={12} className="pt-0 mt-4">
              <label className="form-label">
                Is the Registered address owned by customer{" "}
                <span className="mand-star">*</span>
              </label>
              <RadioGroup
                ref={refs.is_registered_owned}
                className="mb-1"
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={
                  journeyData.is_registered_owned !== null
                    ? journeyData.is_registered_owned
                      ? "yes"
                      : "no"
                    : null
                }
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_registered_owned:
                      e.target?.value === "yes" ? true : false,
                  });
                  setErrors((prev) => ({
                    ...prev,
                    is_registered_owned: "",
                  }));
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <FormControlLabel
                      value={"yes"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>Yes</span>}
                      sx={labelStyle}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      value={"no"}
                      control={<Radio sx={radioStyle} />}
                      label={<span style={{ fontSize: "10px" }}>No</span>}
                      sx={labelStyle}
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
              <span className="error">{fieldErrors.is_registered_owned}</span>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={journeyData?.is_operational_address_same}
                  />
                }
                label="Is Operational Address same as Registered Address"
                onChange={(e) => {
                  setJourneyData({
                    ...journeyData,
                    is_operational_address_same: e.target.checked,
                  });
                }}
              />
            </Grid>

            {journeyData?.business_address?.map((val, i) => {
              return (
                <>
                  {i !== 0 && (
                    <Grid item xs={12} sm={12}>
                      <div
                        className="my-2"
                        style={{ borderTop: "2px solid #bbbdbb" }}
                      ></div>
                      <div className="mt-3">
                        <Chip
                          label={`Address ${i + 1}`}
                          variant="outlined"
                          onDelete={(e) => handleDeleteAddress(i)}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      Operational Business Address{" "}
                      <span className="mand-star">*</span>
                    </label>
                    <textarea
                      ref={refs.current_address}
                      className="form-input-area w-100"
                      required
                      fullWidth
                      placeholder="Current Residential Address"
                      autoComplete="family-name"
                      disabled={
                        journeyData?.is_operational_address_same && i === 0
                      }
                      value={
                        journeyData?.is_operational_address_same && i === 0
                          ? journeyData?.registered_address
                          : journeyData?.business_address[i]
                              ?.operational_address
                      }
                      onChange={(e) => {
                        let data = journeyData?.business_address;
                        data[i].operational_address = e.target.value;
                        setJourneyData({
                          ...journeyData,
                          business_address: data,
                        });

                        clearError(i, "operational_address");
                      }}
                    />
                    <span className="error">
                      {fieldErrors?.business_address?.[i]?.operational_address}
                    </span>
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <label className="form-label">
                      Operational Business Pincode{" "}
                      <span className="mand-star">*</span>
                    </label>
                    <input
                      ref={refs.current_pincode}
                      className="form-input w-100"
                      required
                      fullWidth
                      disabled={
                        journeyData?.is_operational_address_same && i === 0
                      }
                      placeholder="Current Residential Pincode"
                      autoComplete="family-name"
                      value={
                        journeyData?.is_operational_address_same && i === 0
                          ? journeyData?.registered_pincode
                          : journeyData?.business_address[i]
                              ?.operational_pincode
                      }
                      onChange={(e) => {
                        if (
                          e.target.value !== "" &&
                          !/^\d+$/.test(e.target.value)
                        ) {
                          return false;
                        }
                        if (e.target.value.length > 6) return false;

                        let data = journeyData?.business_address;
                        data[i].operational_pincode = e.target.value;
                        setJourneyData({
                          ...journeyData,
                          business_address: data,
                        });
                        clearError(i, "operational_pincode");
                      }}
                    />
                    <span className="error">
                      {fieldErrors?.business_address?.[i]?.operational_pincode}
                    </span>
                  </Grid>

                  <Grid item xs={12} sm={12} className="pt-0 mt-4">
                    <label className="form-label">
                      Is the Operational Business Address owned by customer{" "}
                      <span className="mand-star">*</span>
                    </label>
                    <RadioGroup
                      ref={refs.is_aadhaar_property_owned}
                      className="mb-1"
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      disabled={
                        journeyData?.is_operational_address_same && i === 0
                      }
                      value={
                        journeyData?.is_operational_address_same && i === 0
                          ? journeyData?.is_registered_owned !== null
                            ? journeyData?.is_registered_owned
                              ? "yes"
                              : "no"
                            : null
                          : journeyData.business_address[i]
                              ?.is_operational_property_owned !== null
                          ? journeyData.business_address[i]
                              ?.is_operational_property_owned
                            ? "yes"
                            : "no"
                          : null

                        // (() => {
                        //   if (
                        //     journeyData?.is_operational_address_same &&
                        //     i === 0
                        //   ) {
                        //     if (journeyData?.is_registered_owned !== null) {
                        //       if (journeyData?.is_registered_owned) {
                        //         return "yes";
                        //       }
                        //       return "no";
                        //     } else {
                        //       return null;
                        //     }
                        //   } else {
                        //     if (
                        //       journeyData.business_address[i]
                        //         ?.is_operational_propery_owned !== null
                        //     ) {
                        //       if (
                        //         journeyData.business_address[i]
                        //           ?.is_operational_propery_owned
                        //       ) {
                        //         return "yes";
                        //       }
                        //       console.log(
                        //         journeyData.business_address[i]
                        //           ?.is_operational_propery_owned
                        //       );
                        //       return "no";
                        //     } else {
                        //       return null;
                        //     }
                        //   }
                        // })()
                      }
                      onChange={(e) => {
                        let data = journeyData?.business_address;
                        data[i].is_operational_property_owned =
                          e.target?.value === "yes" ? true : false;
                        setJourneyData({
                          ...journeyData,
                          business_address: data,
                        });
                        clearError(i, "is_operational_property_owned");
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item>
                          <FormControlLabel
                            value={"yes"}
                            control={<Radio sx={radioStyle} />}
                            label={
                              <span style={{ fontSize: "10px" }}>Yes</span>
                            }
                            sx={labelStyle}
                          />
                        </Grid>

                        <Grid item>
                          <FormControlLabel
                            value={"no"}
                            control={<Radio sx={radioStyle} />}
                            label={<span style={{ fontSize: "10px" }}>No</span>}
                            sx={labelStyle}
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                    <span className="error">
                      {
                        fieldErrors?.business_address?.[i]
                          ?.is_operational_property_owned
                      }
                    </span>
                  </Grid>
                </>
              );
            })}

            <Grid item xs={12} sm={12}>
              <Button onClick={handleAddmore} style={{ float: "right" }}>
                + Add additional Operational Address
              </Button>
            </Grid>
          </Grid>
        </div>

        <Paper className={`paper-bottom`} ref={bottomNav} elevation={3}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  ) : (
    <PsychometricTest
      onClick={() => {
        setShowReward(true);
        setPsycho(false);
      }}
    />
  );
});

export default BusinessDetails;
