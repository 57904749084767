import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import Congrats from "./Congrats";
import CongratsCoRequired from "./CongratsCoRequired";
import Rejected from "./Rejected";
import UnderReview from "./UnderReview";

const CustomerStatus = () => {
  const navigate = useNavigate();
  const { application_id } = useParams();

  const [status, setStatus] = useState("loading");

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  useEffect(() => {

    switch(journeyData?.stage){
      case 'CUSTOMER_CREDIT_ENGINE_FAILED':
        setStatus("review")
        break;
      case 'CUSTOMER_UNDER_REVIEW':
        setStatus("review")
        break;
      case 'CUSTOMER_EMBIFI_REJECTED':
        setStatus("rejected")
        break;
      case 'CO_CUSTOMER_CREATION':
        setStatus("success-co")
        break;
      case 'CREATE_BANK':
        setStatus("success")
        break;
    }
  }, [journeyData?.stage]);

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id);
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      navigate("/home");
    };
  });

  return (
    <>
      <div className="response-wrapper w-100">
        {status === "review" && <UnderReview />}
        {status === "success" && (
          <Congrats
            application_id={application_id}
            action={() => {
              navigate("/onboard/loan-creation/" + application_id);
            }}
          />
        )}
        {status === "success-co" && (
          <CongratsCoRequired
            application_id={application_id}
            action={() => {
              navigate("/onboard/co-customer/" + application_id);
            }}
          />
        )}
        {status === "rejected" && <Rejected origin={"customer"} />}
      </div>
      {status === "loading" && <LoadingOver />}
    </>
  );
};

export default CustomerStatus;
