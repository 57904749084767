import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Buffer } from "buffer";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CollectionsIcon from "@mui/icons-material/Collections";
import FlipCameraIosIcon from "@mui/icons-material/FlipCameraIos";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CameraIcon from "@mui/icons-material/Camera";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { FiCamera, FiImage, FiUpload, FiX } from "react-icons/fi";
import { GrGallery } from "react-icons/gr";

import "react-image-crop/dist/ReactCrop.css";
import ReactCrop from "react-image-crop";
import Webcam from "react-webcam";
import { getBase64 } from "../util/convertFileBase";
import { CircularProgress, Grid } from "@mui/material";
import ImageCropper from "./Crop";
import PermissionModal from "./PermissionModal";
import usePermission from "../hooks/usePermission";
import { useContext } from "react";
import { GlobalContext } from "../Contexts/GlobalContext";
import AddIcon from "@mui/icons-material/Add";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function MultipleUpload({
  setFile,
  error,
  errorTxt,
  onSelect,
  text,
  nostyle,
  isLocation,
}) {
  const [open, setOpen] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [isSelfie, setSelfie] = useState(false);
  const [captureImage, setImage] = useState("");
  const [confirmFilename, setConfirmFilename] = useState("");
  const [isInput, setInput] = useState(false);
  const [pdfFile, setPdf] = useState("");
  const [loading, setLoading] = React.useState(true);
  const { getLocationV2 } = usePermission();

  const fileInput = useRef();
  const sliderBottom = useRef();
  const backdrop = useRef();

  const [cropImgTemp, setTempCrop] = useState("");
  const [croppedImg, setCropImg] = useState("");
  const [geoLocation, setLocation] = useState({});

  const handleClickOpen = () => {
    // setOpen(true);
    fileInput.current.value = "";
    setConfirmFilename("");
    setInput(false);
    setPdf("");
    setImage("");
    setCropImg("");

    sliderBottom.current.classList.remove("close");
    backdrop.current.classList.remove("hide");
  };
  const handleClose = () => {
    // setOpen(false);
    setTimeout(() => {
      sliderBottom.current.classList.add("close");
      backdrop.current.classList.add("hide");
    }, 100);
  };

  useEffect(() => {
    if (!open) {
      setIsCameraOpen(false);
      setImage("");
    }
  }, [open]);

  useEffect(() => {
    if (croppedImg !== "") {
      setFile && setFile(croppedImg, geoLocation);
      fileInput.current.value = "";
      setLocation({})
      // console.log(fileInput.current.value);
    }
  }, [croppedImg]);

  useEffect(() => {
    if (pdfFile !== "") {
      setFile && setFile(pdfFile);
    }
  }, [pdfFile]);

  const handleUserMedia = () => setTimeout(() => setLoading(false), 1_000);

  return (
    <>
      <div
        className="capture-only pointer"
        onClick={() => {
          handleClickOpen();
        }}
      >
        {!nostyle && (
          <div className="multi-upload-btn mb-2">
            <CloudUploadIcon
              className="me-2"
              sx={{ color: "rgba(108, 99, 255, 1)" }}
            />
            <span className={!nostyle && "input-placeholder-capture"}>
              {text}
            </span>

            {error && (
              <>
                <br></br>
                <span className="error ms-4 mt-2">
                  <ErrorOutlineIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Upload required file
                </span>
              </>
            )}
          </div>
        )}

        {nostyle && (
          <div className="mb-2 d-flex justify-content-end">
            <AddIcon className="me-2" sx={{ color: "rgba(108, 99, 255, 1)" }} />
            <span style={{ fontSize: "14px", color: "rgba(108, 99, 255, 1)" }}>
              {text}
            </span>

            {error && (
              <>
                <br></br>
                <span className="error ms-4 mt-2">
                  <ErrorOutlineIcon sx={{ fontSize: "15px", mr: 1 }} />
                  Upload required file
                </span>
              </>
            )}
          </div>
        )}
      </div>

      <div
        className="slider-backdrop hide"
        ref={backdrop}
        onClick={() => {
          handleClose();
        }}
      ></div>
      <div ref={sliderBottom} class="slider close">
        <Grid container spacing={2}>
          <Grid className="content-center" item xs={6} sm={6}>
            <Button
              className="bottom-menu-button"
              onClick={() => {
                handleClose();
                setIsCameraOpen(true);
              }}
            >
              <div>
                <FiCamera className="bottom-menu-ico" />
                <p className="w-100">Camera</p>
              </div>
            </Button>
          </Grid>
          <Grid className="content-center" item xs={6} sm={6}>
            <Button
              className="bottom-menu-button"
              onClick={() => {
                fileInput.current.click();
                setInput(true);
              }}
            >
              <div>
                <FiImage className="bottom-menu-ico" />
                <p className="w-100">Gallery</p>
              </div>
            </Button>
            <input
              ref={fileInput}
              type="file"
              class="custom-file-input"
              accept="application/pdf , image/png, image/jpg, image/jpeg"
              onChange={async (e) => {
                onSelect && onSelect();
                // if (!e.target.files[0].type.match("application/pdf")) {
                //   setError && setError("Please upload pdf file only");
                //   return false;
                // } else if (e.target.files[0].size >= 5 * 1024 * 1024) {
                //   setError && setError("Select files below 5mb");
                //   return;
                // }

                if (e.target.files[0].size >= 5 * 1024 * 1024) {
                  alert("Maximum 5mb");
                  return false;
                }
                if (e.target.files[0].type.match("application/pdf")) {
                  setInput(true);
                  setPdf(await getBase64(e.target.files[0]));
                  setConfirmFilename(e.target.files[0].name);
                } else if (e.target.files[0]["type"].split("/")[0] == "image") {
                  if (e.target.files[0].type.match("image/svg")) {
                    alert("Not supported file");
                    return false;
                  }
                  setInput(false);
                  setImage(await getBase64(e.target.files[0]));
                } else {
                  alert("Pdf/Image only");
                  fileInput.current.value = "";
                  setConfirmFilename("");
                  setInput(false);
                  setPdf("");
                  setImage("");
                }

                handleClose();
              }}
              hidden
            />
          </Grid>

          {/* <Grid className="content-center" item xs={4} sm={4}>
            <Button
              className="bottom-menu-button"
              onClick={() => handleClose()}
            >
              <div>
                <FiX className="bottom-menu-ico" />
                <p className="w-100">Close</p>
              </div>
            </Button>
          </Grid> */}
        </Grid>
      </div>

      {/* /////// CAMERA ///// */}

      {isCameraOpen && (
        <div className="camera-screen">
          <CloseIcon
            className="camera-close pointer"
            onClick={() => {
              setIsCameraOpen(false);
            }}
          />
          <div className="camera-loading">
            <div className="content-center">
              <CircularProgress />
            </div>
            Camera Loading
          </div>
          <Webcam
            audio={false}
            className={"webcamera"}
            screenshotQuality={1}
            screenshotFormat="image/jpeg"
            width={"100%"}
            height={"100%"}
            // onUserMedia={handleUserMedia}
            videoConstraints={{
              width: "1000",
              height: "1000",
              facingMode: `${isSelfie ? "user" : "environment"}`,
            }}
          >
            {({ getScreenshot }) => (
              <>
                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}

                <div className={`action-buttons-holder`}>
                  <div className="responsive-div">
                    <div className="action-buttons w-100">
                      <span
                        className="cam-flip-btn-v2"
                        onClick={() => {
                          setSelfie(!isSelfie);
                        }}
                      >
                        <FlipCameraIosIcon />
                      </span>

                      <button
                        className="capture-btn"
                        onClick={async () => {
                          if (isLocation) {
                            let data = await getLocationV2();
                            setLocation({
                              lat: data?.coords?.latitude,
                              long: data?.coords?.longitude,
                            });
                          }
                          const imageSrc = getScreenshot();
                          setImage(imageSrc);
                          setIsCameraOpen(false);
                          onSelect && onSelect();
                        }}
                      >
                        <CameraIcon className="me-2" />
                        Capture photo
                      </button>
                    </div>
                  </div>
                </div>

                {/* //////////////////////////////////////////////////////////////////////////////////////////// */}
              </>
            )}
          </Webcam>
          {/* 
          {permissions.camera === "PROMPT" && (
            <PermissionModal initial={true} />
          )} */}
        </div>
      )}

      {captureImage !== "" && !isInput && (
        <div className="crop-screen">
          <CloseIcon
            className="crop-close"
            onClick={() => {
              setImage("");
            }}
          />
          <div className="content-center">
            <ImageCropper
              onImageCropped={(result) => {
                setTempCrop(result);
              }}
              imageToCrop={captureImage}
            />
          </div>
          <div className={`action-buttons-holder`}>
            <div className="responsive-div">
              <div className="action-buttons w-100">
                <button
                  className="capture-btn"
                  onClick={() => {
                    setCropImg(cropImgTemp);
                    setConfirmFilename("capture.jpg");
                    setImage("");
                  }}
                >
                  <CameraIcon className="me-2" />
                  Crop and Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
