import { useState, useEffect } from "react";
import axios from "axios";
import { verifyAuth } from "../../../api/agent";
import { useLocation } from "react-router-dom";

export default function useFindUser() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    async function findUser() {
      setLoading(true);
      try {
        let { data } = await verifyAuth();
        setLoading(false);
        setUser({
          ...data?.data,
          name: data?.data?.pan_details?.name || "User",
          type: "AGENT",
        });
      } catch (err) {
        setLoading(false);
        setUser(null);
      }
    }

    findUser();
  }, []);

  //   useEffect(()=>{
  //     if(user){
  //         localStorage.setItem("userData", JSON.stringify(user));
  //     }else{
  //         localStorage.removeItem("userData");
  //     }
  //   },[user])

  return {
    user,
    setUser,
    isLoading,
  };
}
