import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { aadhaarEsign, viewFile } from "../../../../api/agent";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import useApplication from "../../../../hooks/useApplication";
import Failed from "./EnachStatus/Failed";
import Success from "./EnachStatus/Success";
import Waiting from "./Waiting";
import moment from "moment";
import { PDFDocument } from "pdf-lib";
import { environment } from "../../../../config";

const EnachStatus = () => {
  const navigate = useNavigate();
  const { application_id } = useParams();

  const [status, setStatus] = useState("loading");
  const [isLoading, setLoading] = useState(false);

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  useEffect(() => {
    if (journeyData?.aadhaar_esign_status === null) {
      setStatus("pending");
    } else if (journeyData?.aadhaar_esign_status) {
      setStatus("success");
    } else {
      setStatus("failed");
    }
  }, [journeyData]);

  const { getApplicationDetails, getStage } = useApplication();
  useEffect(() => {
    getApplicationDetails(application_id, true);
  }, []);

  const createPdf = async () => {
    // const existingPdfBytes = await fetch(mitcPdf).then((res) =>
    //   res.arrayBuffer()
    // );
    setLoading(true);

    try {
      const { data } = await viewFile(`assets/MITC.pdf`);
      const existingPdfBytes = await data?.arrayBuffer();

      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      const lenderName = form.getTextField("lenderName");
      const lenderAddress = form.getTextField("lenderAddress");
      const lenderWebsite = form.getTextField("lenderWebsite");
      const bankName = form.getTextField("bankName");
      const accountNumber = form.getTextField("accountNumber");
      const bankIfsc = form.getTextField("bankIfsc");
      const applicationNo = form.getTextField("applicationNo");
      const approvalDate = form.getTextField("approvalDate");
      const borrowerName = form.getTextField("borrowerName");
      const borrowerAddress = form.getTextField("borrowerAddress");
      const borrowerBankName = form.getTextField("borrowerBankName");
      const borrowerAccountNumber = form.getTextField("borrowerAccountNumber");
      const borrowerIfsc = form.getTextField("borrowerIfsc");
      const loanAmount = form.getTextField("loanAmount");
      const interestRate = form.getTextField("interestRate");
      const tranchTenure = form.getTextField("tranchTenure");
      const penalInterest = form.getTextField("penalInterest");
      const proccesingFee = form.getTextField("proccesingFee");
      const documentationFee = form.getTextField("documentationFee");
      const preInfo = form.getTextField("preInfo");
      const receivedAccount = form.getTextField("receivedAccount");

      lenderName.setText(journeyData?.nbfc_data?.name || "N/A");
      lenderAddress.setText(journeyData?.nbfc_data?.address || "N/A");
      lenderWebsite.setText(journeyData?.nbfc_data?.website || "N/A");
      bankName.setText(journeyData?.bank_name || "N/A");
      accountNumber.setText(journeyData?.accountNumber || "N/A");
      bankIfsc.setText(journeyData?.bank_name || "N/A");
      applicationNo.setText(journeyData?.application_id || "N/A");
      approvalDate.setText(
        moment(journeyData?.start_date).format("DD/MM/YYYY") || "N/A"
      );
      borrowerName.setText(journeyData?.full_name || "N/A");
      borrowerAddress.setText(journeyData?.current_address || "N/A");
      borrowerBankName.setText(journeyData?.bank_details?.bank_name || "N/A");
      borrowerAccountNumber.setText(
        journeyData?.bank_details?.account_number || "N/A"
      );
      borrowerIfsc.setText(journeyData?.bank_details?.ifsc_code || "N/A");
      loanAmount.setText(journeyData?.loanAmount || "N/A");
      interestRate.setText(journeyData?.interestRate || "N/A");
      tranchTenure.setText(journeyData?.tranchTenure || "N/A");
      penalInterest.setText(journeyData?.penalInterest || "N/A");
      proccesingFee.setText(journeyData?.proccesingFee || "N/A");
      documentationFee.setText(journeyData?.documentationFee || "N/A");
      preInfo.setText(journeyData?.preInfo || "N/A");
      receivedAccount.setText(journeyData?.bank_account || "N/A");

      const pdfBytes = await pdfDoc.save();

      var blob = new Blob([pdfBytes], { type: "application/pdf" });
      var url = URL.createObjectURL(blob);
      // window.open(url);

      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = async function () {
        var base64data = reader.result;
        // console.log(base64data);

        try {
          let payload = {
            redirect_url:
              environment === "development"
                ? `https://demo.cf.embifi.in/onboard/esign-status/${application_id}`
                : `https://cf.embifi.in/onboard/esign-status/${application_id}`,
            file: base64data,
          };
          let { data } = await aadhaarEsign(payload, application_id);
          window.open(data?.data?.url, "_self");
        } catch (error) {}

        setLoading(false);
      };
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="response-wrapper w-100">
        {status === "success" && <Success amount={journeyData?.credit_limit}/>}
        {status === "failed" && (
          <Failed
            count={journeyData?.esign_count || 0}
            action={() => {
              createPdf();
            }}
            actionPhysical={() => {
              navigate(`/onboard/physical-agreement/${application_id}`);
            }}
          />
        )}
        {status === "pending" && <Waiting />}
      </div>
      {status === "loading" || (isLoading && <LoadingOver />)}
    </>
  );
};

export default EnachStatus;
