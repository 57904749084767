import {
  Avatar,
  BottomNavigation,
  Button,
  CardHeader,
  Checkbox,
  CircularProgress,
  CssBaseline,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSnackbar } from "notistack";
import MultiUpload from "../../../../components/MultipleUpload";
import CaptureUpload from "../../../../components/CaptureV2";
import MenuAppBar from "../../../../components/Appbar";
import CustomInputAutocomplete from "../../../../components/Autocomplete/AutoComplete";

import { FiFileText } from "react-icons/fi";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CustomerContext } from "../../../../Contexts/CustomerContext";
import { useNavigate, useParams } from "react-router-dom";
import { BanksList } from "../../../../util/constants";
import { getChequeData, updateCustomerBankDetails } from "../../../../api";
import useCustomer from "../../../../hooks/useCustomer";
import { GlobalContext } from "../../../../Contexts/GlobalContext";
import LoadingOver from "../../../../components/LoadingOver";
import { LoanContext } from "../../../../Contexts/LoanJourneyContext";
import { confirmBank } from "../../../../api/agent";
import PennyDrop from "../../../../components/PennyDrop";
import { isBlank, isIFSCValid } from "../../../../validations/fieldValidations";

const radioStyle = {
  color: "#274384",
  "&.Mui-checked": {
    color: "#274384",
  },
};

const labelStyle = {
  color: "gray",
};

const ConfirmData = forwardRef((props, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const { application_id } = useParams();

  const [isLoading, setLoading] = useState(false);
  const [pUploadError, setPError] = useState(false);
  const [pErrorTxt, setPErrorTxt] = useState("");
  const [banks, setBanks] = useState([]);
  const [bankStatement, setBankStatement] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [pennyState, setPennyState] = useState({
    show: false,
    status: "pending",
  });

  const { journeyState } = useContext(LoanContext);
  const [journeyData, setJourneyData] = journeyState;

  const { bottomNav } = useContext(GlobalContext);
  const [bankDetails, setBankDetails] = useState({ cheques: [] });

  const navigate = useNavigate();

  const isValid = async () => {
    let valid = true;
    let errors = {
      beneficiary_name: isBlank(journeyData?.beneficiary_name || ""),
      // bank_name: isBlank(journeyData?.bank_name || ""),
      // bank_code: isBlank(journeyData?.bank_code || ""),
      // account_type: isBlank(journeyData?.account_type || ""),
      account_number: isBlank(journeyData?.account_number || ""),
      ifsc_code: await isIFSCValid(journeyData?.ifsc_code || ""),
    };

    console.log(errors);
    setFieldErrors(errors);

    for (let i in errors) {
      if (errors[i].length > 0) {
        valid = false;
      }
    }

    return valid;
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (await isValid()) {
      let payload = {
        application_id,
        bank_name: journeyData?.bank_name,
        bank_beneficiary_name: journeyData?.beneficiary_name,
        bank_account_type: journeyData?.account_type,
        bank_account_number: journeyData?.account_number,
        bank_ifsc_code: journeyData?.ifsc_code,
      };

      console.log(payload);
      // setLoading(false);
      // return;

      setPennyState({
        ...pennyState,
        show: true,
      });

      try {
        let { data } = await confirmBank(journeyData?.bank_id, payload);
        if (data?.status) {
          if (!data?.pennyDropFailed) {
            setPennyState({
              ...pennyState,
              show: true,
              status: "success",
            });
          } else {
            enqueueSnackbar("Penny Drop failed!", {
              variant: "error",
            });
            setPennyState({
              ...pennyState,
              show: false,
            });
          }
        }
        navigate("/onboard/bank-accounts/" + application_id);
      } catch (error) {
        enqueueSnackbar(error.response.data.errors[0].message, {
          variant: "error",
        });
      }
    }

    setLoading(false);
  };

  const removeItem = (index) => {
    setBankDetails((st) => ({
      ...st,
      cheques: bankDetails.cheques.filter((val, i) => {
        if (i !== index) return val;
      }),
    }));
  };

  useEffect(() => {
    BanksList.forEach((val) => {
      banks.push(val.bank);
    });
    setBanks(banks);
  }, []);

  return (
    <>
      <Box className="kyc-wrapper" sx={{ pb: 7, pt: 5 }} ref={ref}>
        <MenuAppBar
          home={false}
          draft={true}
          action={() => {
            navigate(`/onboard/bank-accounts/${application_id}`);
          }}
        />
        <CssBaseline />
        <div className="main-wrapper">
          <Grid item xs={12} sm={12}>
            <label className="form-label mt-3">
              Beneficiary Name <span className="mand-star">*</span>
            </label>
            <input
              type="text"
              className="form-input w-100 px-3"
              required
              placeholder="Beneficiary Name"
              name="beneficiary-name"
              autoComplete="family-name"
              value={journeyData.beneficiary_name}
              // disabled
              onChange={(e) => {
                setJourneyData((st) => ({
                  ...st,
                  beneficiary_name: e.target.value,
                }));
              }}
            />
            <span className="error">{fieldErrors.beneficiary_name}</span>
          </Grid>

          <Grid item xs={12} sm={12}>
            <label className="form-label mt-3">
              Bank Name <span className="mand-star">*</span>
            </label>

            <input
              type="text"
              className="form-input w-100 px-3"
              required
              placeholder="Bank Name"
              name="bank-name"
              autoComplete="family-name"
              value={journeyData.bank_name}
              disabled
              onChange={(e) => {
                setJourneyData((st) => ({
                  ...st,
                  bank_name: e.target.value,
                }));
              }}
            />

            {/* <span className="error">{fieldErrors.bank}</span> */}
          </Grid>

          <Grid item xs={12} sm={12} className="pt-0 mt-4">
            <label className="form-label">
              Bank Account Type
              <span className="mand-star">*</span>
            </label>

            <input
              type="text"
              className="form-input w-100 px-3"
              required
              placeholder="Bank Account Type"
              name="bank-type"
              autoComplete="family-name"
              value={journeyData.account_type}
              disabled={true}
              onChange={(e) => {
                setJourneyData((st) => ({
                  ...st,
                  account_type: e.target.value,
                }));
              }}
            />
          </Grid>

          {journeyData?.account_type ===
            "Cash_Credit_Account_Overdraft_Account" && (
            <Grid item xs={12} sm={12}>
              <label className="form-label mt-4">
                Cash Credit / Overfdraft Limit{" "}
                <span className="mand-star">*</span>
              </label>
              <input
                type="text"
                className="form-input w-100 px-3"
                required
                placeholder="Cash Credit / Overfdraft Limit"
                name="credit-limit"
                autoComplete="family-name"
                value={journeyData.credit_over_limit}
                disabled
                onChange={(e) => {
                  setJourneyData((st) => ({
                    ...st,
                    credit_over_limit: e.target.value,
                  }));
                }}
              />
              {/* <span className="error">{fieldErrors.current_address}</span> */}
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <label className="form-label mt-3">
              Account Number <span className="mand-star">*</span>
            </label>
            <input
              type="text"
              className="form-input w-100 px-3"
              required
              placeholder="Your Account number"
              name="account-number"
              autoComplete="family-name"
              value={journeyData.account_number}
              onChange={(e) => {
                if (e.target.value !== "" && !/^\d+$/.test(e.target.value)) {
                  return false;
                }
                setJourneyData((st) => ({
                  ...st,
                  account_number: e.target.value,
                }));
              }}
            />
            <span className="error">{fieldErrors.account_number}</span>
          </Grid>
          <Grid item xs={12} sm={12}>
            <label className="form-label mt-3">
              IFSC Code <span className="mand-star">*</span>
            </label>
            <input
              type="text"
              className="form-input w-100 px-3"
              required
              placeholder="Your IFSC code"
              name="pdf-password"
              autoComplete="family-name"
              // disabled
              value={journeyData.ifsc_code}
              maxLength={11}
              onChange={(e) => {
                setJourneyData((st) => ({
                  ...st,
                  ifsc_code: e.target.value?.toUpperCase(),
                }));
              }}
            />
            <span className="error">{fieldErrors.ifsc_code}</span>
          </Grid>
        </div>

        <Paper className="paper-bottom" elevation={3} ref={bottomNav}>
          <BottomNavigation style={{ height: "auto" }}>
            <div className="button-wrapper w-100">
              <Button
                className="btn-submit"
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mb: 2 }}
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <CircularProgress
                      className="me-2 mb-1"
                      size={20}
                      style={{ color: "white" }}
                    />
                    Loading
                  </>
                ) : (
                  "Confirm and Proceed"
                )}
              </Button>
            </div>
          </BottomNavigation>
        </Paper>
      </Box>
      {pennyState?.show && (
        <PennyDrop
          status={pennyState?.status}
          onClick={() => {
            alert("Done");
          }}
        />
      )}
    </>
  );
});

export default ConfirmData;
